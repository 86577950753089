import { makeStyles } from '@material-ui/core/styles';

const useStyles = (props) => makeStyles(theme => ({
	input: {
		display: 'none'
	},
	uploadButton: {
		border: '1px solid #808080',
		textTransform: 'none',
	},
	container: {
		height: props.height + 50 || 280,
	},
	childrenContainer: {
		margin: theme.spacing(2)
	},
	inputContainer: {
		overflow: 'auto',
		minHeight: '100%'
	}
}));

export default useStyles;

	// btn: {
	// 	border: `2px dashed ${color}`,
	//   color: color,
	//   backgroundColor: 'white',
	//   height: props.height + 10 || 255,
	//   borderRadius: 10,
	//   fontSize: 20,
	//   width: '100%',
	// },
	// inputFile: {
	// 	fontSize: props.height + 10 || 255,
	//   position: 'absolute',
	//   left: 0,
	//   top: 0,
	//   opacity: 0
	// },