import React from 'react';

import { Link } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const LinkButton = props => 
  <ListItem style={{ background: '#00bfff', color: '#fff' }} button component={Link} to={props.url}>
    { props.icon && <ListItemIcon style={{ color: '#fff' }}>{props.icon}</ListItemIcon> }
    <ListItemText primary={props.label} />
  </ListItem>

export { LinkButton };