import { gql } from '@apollo/client';

const GET = gql`
  query Costs($start: Date, $end: Date){
    costs(start: start, end: $end) {
      id
      description
      cost
      crmId
      percent
    }
  }
`; 

const GET_BY_ID = gql`
  query Cost($crmId: ID!){
    cost(crmId: $crmId) {
      id
      description
      cost
      percent
    }
  }
`;

const CREATE = gql`
  mutation CreateCost(
    $crmId: ID!
    $description: String!
    $cost: Float!
  ) {
    createCost(input: {
      crmId: $crmId
      description: $description
      cost: $cost
    }) {
      id
      description
      cost
      crmId
      percent
    }
  }
`

const DELETE = gql`
  mutation DeleteCost($id: ID!) {
    deleteCost(id: $id) {
      id
    }
  }
`;

const UPDATE_EXPENSE = gql`
  mutation UpdateExpense($cost1: Float! $cost2: Float!
      $cost3: Float!
      $cost4: Float!
      $cost5: Float!
      $cost6: Float!
      $cost7: Float!
      $cost8: Float!
      $cost9: Float!
      $cost10: Float!
      $cost11: Float!
      $cost12: Float!
      $cost13: Float!
      $cost14: Float!
      $cost15: Float!
    ) {
    updateExpense(
      input: {
        cost1: $cost1
        cost2: $cost2
        cost3: $cost3
        cost4: $cost4
        cost5: $cost5
        cost6: $cost6
        cost7: $cost7
        cost8: $cost8
        cost9: $cost9
        cost10: $cost10
        cost11: $cost11
        cost12: $cost12
        cost13: $cost13
        cost14: $cost14
        cost15: $cost15
      }
    ){
      cost1
      cost2
      cost3
      cost4
      cost5
      cost6
      cost7
      cost8
      cost9
      cost10
      cost11
      cost12
      cost13
      cost14
      cost15
    }
  }
`;

const GET_CRM = gql`
  query BalanceCrms($userId: ID $status: [Int] $subStatus: Int $start: Date $end: Date $showBySurgeryDate: Boolean $generalStatus: Int $limit: Int $offset: Int $customer: String $orderBy: Int){
    crms(userId: $userId status: $status subStatus: $subStatus start: $start end: $end showBySurgeryDate: $showBySurgeryDate generalStatus: $generalStatus limit: $limit offset: $offset customer: $customer orderBy: $orderBy) {
      count
      rows {
        id
        surgeryDate
        Costs {
          cost
        }
        medicalConsultation
        OneProcedure {
          id
          prefix
        }
        Customer {
          name
        }
        total
      }
    }
  }
`;

const GET_SUMMARY = gql`
  query SummaryBalanceCrms($userId: ID $status: [Int] $subStatus: Int $start: Date $end: Date $showBySurgeryDate: Boolean $generalStatus: Int $limit: Int $offset: Int $customer: String $orderBy: Int){
    crms(userId: $userId status: $status subStatus: $subStatus start: $start end: $end showBySurgeryDate: $showBySurgeryDate generalStatus: $generalStatus limit: $limit offset: $offset customer: $customer orderBy: $orderBy) {
      count
      rows {
        Costs {
          cost
        }
        total
      }
      expense {
        cost1
        cost2
        cost3
        cost4
        cost5
        cost6
        cost7
        cost8
        cost9
        cost10
        cost11
        cost12
        cost13
        cost14
        cost15
      }
    }
  }
`;

const object = { 
  GET_SUMMARY,
  GET, 
  GET_BY_ID, 
  DELETE, 
  CREATE,
  GET_CRM,
  UPDATE_EXPENSE
};
export default object