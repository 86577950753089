import React from 'react';

import ButtonMUI from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: '#282828'
  },
}));

const Button = ({ isLoading, ...props }) => {

	const classes = useStyles();
	
	const handleClick = e => {
		e.preventDefault();
		props.onClick && props.onClick();
	}

	return (
		<ButtonMUI
      variant={props.variant || "contained" }
      color={props.color || "primary"}
      onClick={handleClick}
      disabled={isLoading}
      {...props}
    >
      {
      	isLoading 
	      	? <CircularProgress size={24} className={classes.buttonProgress} />
	      	: props.label
      }
    </ButtonMUI>
	);

};

export default Button;