import React, { useState, useCallback, useRef, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import useStyles from './style';

const ImageField = ({resetImage, ...props}) => {

	const classes = useStyles({ height: props.height })();

	const [fileData, setFileData] = useState(['file', 'jpeg']);
	const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ 
    unit: '%', 
    width: props.width || 30, 
    aspect: props.aspect || 1 
  });
  // const [previewUrl, setPreviewUrl] = useState();

  useEffect(() => {
    if(props.isUpload){
      setUpImg(null);
      resetImage();
    }
  }, [props.isUpload, resetImage]);

  const onSelectFile = e => {
    if(props.disabled){return;}
    if (e.target.files && e.target.files.length > 0) {
    	const tmpFileData = getFileNameWithExt(e);
    	setFileData(tmpFileData);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const makeClientCrop = async crop => {
    if (imgRef.current && crop.width && crop.height) {
      await createCropPreview(imgRef.current, crop, fileData.join('.'));
    }
  };

  const createCropPreview = async (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas esta vacío.'));
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(previewUrl);
        // setPreviewUrl(window.URL.createObjectURL(blob));
        props.croppedImage(window.URL.createObjectURL(blob),blob);
      }, `image/${fileData[1]}`, 1);
    });
  };

  const onChange = c => setCrop(c);

  const onReturn = e => {
  	e.preventDefault();
  	setUpImg(null);
  } 

	return (
		<Grid container spacing={0} alignContent='center' className={!props.noHeight ? classes.container : ''}>
			<Grid item xs={12} align='center'>
		    { !upImg 
		    	?
		    		<Grid container className={classes.inputContainer} alignItems='center'>
              <Grid item xs={12}>
  			    		<input
  				        accept='image/*'
  				        className={classes.input}
  				        id='contained-button-file'
  				        onChange={onSelectFile}
  				        type='file'
                  disabled={props.disabled || false}
  				      />
  				      <label htmlFor='contained-button-file'>
  				        <Button 
  				        	variant='outlined'
  				        	color='default' 
  				        	component='span'
  				        	disableElevation
                    disabled={props.disabled || false}
  				        	className={classes.uploadButton}
  				        >
  				          Selecciona una imagen de tu ordenador
  				        </Button>
  				      </label>
              </Grid>
				      {	props.children &&
				      	<Grid item xs={12} className={classes.childrenContainer}>
					      	{props.children}
					      </Grid>
				    	}
				    </Grid>
					:
						<div>
							<p>Para recortar la imagen, arrastra el área seleccionada y haz clic en "{props.buttonLabel || 'Guardar'}"</p>
              { props.noHeight 
                ? <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={onChange}
                    onComplete={makeClientCrop}
                    keepSelection={props.keepSelection || true}
                  />
                : <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={onChange}
                    onComplete={makeClientCrop}
                    keepSelection={props.keepSelection || true}
                    minHeight={ props.minHeight || 100 }
                    imageStyle={{ height: props.height || 250 }}
                  />
              }
							
              <br/>
					    <Link href='#' onClick={onReturn} color='primary'>
				        Quiero seleccionar otra imagen.
				      </Link>
				    </div>
				}	
	  	</Grid>
	  </Grid>
  
	)
}

function getFileNameWithExt(event) {

  if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
    return;
  }

  const name = event.target.files[0].name;
  const lastDot = name.lastIndexOf('.');

  const fileName = name.substring(0, lastDot);
  const ext = name.substring(lastDot + 1);

  return [fileName, ext];
  
}

export default ImageField;