import React, { useState, useEffect, Fragment } from 'react';

import ListAltIcon from '@material-ui/icons/ListAltOutlined';

import { useQuery } from '@apollo/client';

import Module from './../../presentationals/Module';

import GQL from './_gql';
import FilterDialog from './filter.dialog';
import FormExpense from './form.dialog';
import ConceptDialog from './concept.dialog';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', format: 'date' },
	{ label: 'Folio', key: 'folio', sort: 'text' },
	{ label: 'Tipo', key: 'type', sort: 'text', format: 'typeSale' },
	{ label: 'Cliente', key: 'Customer name', sort: 'text' },
	{ label: 'Responsable', key: 'User name', sort: 'text' },
	{ label: 'Importe', key: 'total', format: 'currency', align: 'right' },
];

const Component = props => {
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isOpenConcepts, setIsOpenConcepts ] = useState([0,false]);
	const [ formIsOpen, setFormIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});
	const [ sales, setSales ] = useState({ count: 0, rows: [] });

	const { data, loading, refetch } = useQuery(GQL.GET);

	useEffect(() => {
		if(data && data.sales) {
			const tmp = data.sales.rows.map(el => ({
				...el,
				folio: `${el.serial}-${el.folio}`,
				folioLabel: `${el.serial}-${el.folio}`,
			}));
			setSales({ count: data.sales.count, rows: tmp });
		}
	},[data]);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

	const handleCreate = () => setFormIsOpen(true);
	const handleCloseCreate = () => setFormIsOpen(false);
	
	const handleCloseConcepts = () => setIsOpenConcepts([0,false]);
	const handleConcepts = (e,{ id }) => setIsOpenConcepts([id,true]);
	
	return (
		<Fragment>
			<Module
				isLoading={loading}
				name='Movimientos de Productos'
				singular='Movimientos de Producto'
				operation={['sale', 'sales']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				noEdit
				noDelete
				records={sales}
				filter={handleFilter}
				handleSearch={handleSearch}
				handleCustomCreate={handleCreate}
				actions={[
					{ icon: <ListAltIcon/>, onClick: handleConcepts, label: 'Conceptos'  },
				]}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
			<FormExpense isOpen={formIsOpen} handleClose={handleCloseCreate} />
			<ConceptDialog isOpen={isOpenConcepts[1]} handleClose={handleCloseConcepts} id={isOpenConcepts[0]} />
		</Fragment>
	);
}

export default Component;