import { gql } from '@apollo/client';

const GET = gql`
	query CrmLogs($start: Date $end: Date $status: Int $userId: ID $catchment: [Int] $subStatus: Int $surgeryDoctor: Int){
		crmLogs(start: $start, end: $end, status: $status userId: $userId catchment: $catchment subStatus: $subStatus surgeryDoctor: $surgeryDoctor){
			count
			rows{
				status
				subStatus
				surgeryDate
				createdAt
				xopDate
				diffDays
				catchment
				surgeryDoctor
				Customer{
					name
					place
					age
					phone
				}
				OneProcedure {
					id
					prefix
				}
				date
			}
		}
	}
`;

const object = { GET }

export default object;