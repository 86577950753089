import React, { useState, Fragment } from 'react';

import { useMutation } from '@apollo/client';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import logo from './../../files/logo.png';

import TextField from './../../presentationals/TextField';
import Button from './../../presentationals/Button';
import Snackbar from './../../presentationals/Snackbar';

import { HANDLE_SNACKBAR } from './../../apollo/typeDefs';
import { parseError } from './../../helpers';

import { LOGIN } from './_gql';
import { Copyright } from './helpers';
import useStyles from './style';

const defaultValues = {
  username: '',
  password: ''
}

const LoginComponent = props => {

  const classes = useStyles();

  const [ login, { loading } ] = useMutation(LOGIN);
  const [ handleSnackbar ] =  useMutation(HANDLE_SNACKBAR);
  
  const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState(defaultValues);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleClick = async (e, reason) => {
    try{
      const { 
        data: { 
          login: { 
            accessToken, 
            refreshToken, 
            session
          } 
        } 
      } = await login({ variables: values });

      const { id, username, name, privileges } = session;

      localStorage.setItem('token', accessToken);
      localStorage.setItem('refresh', refreshToken);
      localStorage.setItem('name', name);
      localStorage.setItem('id', id);
      localStorage.setItem('username', username);
      localStorage.setItem('privileges', privileges);
      
      window.location.reload()
      return false;

    } catch(e) {
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if(el.name === 'BAD_USER_INPUT') {
          setErrors(el.message);
        } else {
          handleSnackbar({
            variables: { 
              isOpen: true, 
              time: 3000,
              label: el.message, 
              severity: 'error'
            }
          });
        }
      });
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleClick();
    }
  }

  return (
    <Fragment>
      <Grid container component="main" justify="center" className={classes.root}>
        <Grid 
          item 
          lg={4}
          md={6}
          sm={8}
          xs={12} 
          component={Paper} 
          className={classes.box} 
          elevation={6} 
          square
        >
          <div className={classes.paper}>
            <img src={logo} alt="El Cuerpo de tus Sueños" className={classes.logo} />
            
            <div className={classes.form}>
              <Grid container spacing={1}>
                <Grid item xs={12} align="center">
                  <div className={classes.title}>
                    Una herramienta inteligente, veloz y fácil de usar.
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    label="Usuario"
                    name="username"
                    value={values.username}
                    error={errors.username}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    size="medium"
                    autoFocus
                    noFormat
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    label="Contraseña"
                    name="password"
                    type="password"
                    value={values.password}
                    error={errors.password}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    size="medium"
                    noFormat
                  />
                </Grid>
              </Grid>
              <Button
                isLoading={loading}
                fullWidth
                onClick={handleClick}
                className={classes.submit}
                label="Iniciar Sesión"
              />
              
              <Box mt={5}>
                <Copyright />
              </Box>
            </div>
          </div>
        </Grid>
      </Grid>
      <Snackbar horizontal='center' />
    </Fragment>
  );
}

export default LoginComponent;