import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import FormDialog from './../../presentationals/FormDialog';
import TextField from './../../presentationals/TextField';

import GQL from './_gql';

const CreateDialog = props => {
	const [ values, setValues ] = useState('');

	const [ updatePostSurgery, { loading } ] = useMutation(GQL.UPDATE, {
		update(cache, { data: response }) {
			const tmp = response.updatePostSurgery.commentary;
			const id = props.id;
			const oldQuery = cache.readQuery({ query: GQL.GET_HISTORY, variables: { crmId: props.crmId } });
			const newQuery = oldQuery.postSurgeriesHistory.map(el => {
				if(parseInt(el.id) === parseInt(id)){ return { ...el, commentary: tmp }; }
				return el;
			});
			cache.writeQuery({
        query: GQL.GET_HISTORY,
        variables: { crmId: props.crmId },
        data: { postSurgeriesHistory: newQuery }
      });
		}
	});

	const handleCloseForm = e => {
		props.handleClose();
	}

	const handleAction = async e => {
		try{
			const { id } = props;
			await updatePostSurgery({ variables: { id, commentary: values } });
			setValues('');
			props.handleAction();
			handleCloseForm();
		}catch(e){
			console.log(e.message)
		}
	}

	const handleChange = e => {
    setValues(e.target.value);
  }

	return (
		<FormDialog
			isOpen={props.isOpen}
			isLoading={loading}
			title='Actualizar comentario'
      handleClose={handleCloseForm}
      handleAction={handleAction}
      actionLabel='Actualizar'
		>
			<TextField
				autoFocus
				name='commentary'
				label='Comentario'
				onChange={handleChange}
				value={values}
			/>
		</FormDialog>
	);
};

export default CreateDialog;