import React from 'react';

import { useQuery } from '@apollo/client';

import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroidOutlined';

import Module from './../../presentationals/Module';

import GQL from './_gql';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text', filter: true },
	{ label: 'Edad', key: 'age', sort: 'text', filter: true },
	{ label: 'Teléfono', key: 'phone', sort: 'text', filter: true },
	{ label: 'Ubicación', key: 'place', sort: 'text' },
	{ label: 'Habla Español', key: 'spanish', sort: 'text', format: 'yesNoType' }
];

const nationalityOptions = [
	{ value: 1, label: 'Mexicana' },
	{ value: 2, label: 'Extranjera' }
];

const spanishOptions = [
	{ value: true, label: 'Sí' },
	{ value: false, label: 'No' }
];

const Component = props => {

	const { data, loading } = useQuery(GQL.GET);
	
	return (
		<Module
			isLoading={loading}
			name='Clientes'
			singular='Cliente'
			operation={['customer', 'customers']}
			Rows={Rows}
			get={GQL.GET}
			getById={GQL.GET_BY_ID}
			create={GQL.CREATE}
			update={GQL.UPDATE}
			delete={GQL.DELETE}
			records={data && data.customers}
			fields={[
				{ 
					icon: <AccountCircleIcon />,
					name: 'name', 
					label: 'Nombre',
					autoFocus: true
				},
				{ 
					name: 'age', 
					label: 'Edad'
				},
				{ 
					icon: <PhoneAndroidIcon />,
					name: 'phone', 
					label: 'Teléfono',
					type: 'phone'
				},
				{ 
					name: 'place', 
					label: 'Ubicación'
				},
				{ 
					name: 'nationality', 
					label: 'Nacionalidad',
					field: 'select',
					options: nationalityOptions
				},
				{
					name: 'spanish',
					label: '¿Habla Español?',
					field: 'select',
					options: spanishOptions
				}
			]}
		/>
	);
}

export default Component;