import React, { useState, Fragment } from 'react';

import { useQuery } from '@apollo/client';

import AirlineSeatFlatAngledIcon from '@material-ui/icons/AirlineSeatFlatAngledOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import Module from './../../presentationals/Module';

import GQL from './_gql';
import CostDialog from './cost.dialog';

const Rows = [
	{ label: 'Procedimiento', key: 'name', sort: 'text', filter: true },
	{ label: 'Prefijo', key: 'prefix', sort: 'text', filter: true }
];

const Component = props => {

	const [ isOpen, setIsOpen ] = useState([false, null]);

	const { data, loading } = useQuery(GQL.GET);

	const handleCost = (e, data) => {
		setIsOpen([true, data.id]);
	}

	const handleClose = () => {
		setIsOpen([false, null]);
	}
	
	return (
		<Fragment>
			<Module
				isLoading={loading}
				name='Procedimientos'
				singular='Procedimiento'
				operation={['procedure', 'procedures']}
				Rows={Rows}
				get={GQL.GET}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				update={GQL.UPDATE}
				delete={GQL.DELETE}
				records={data && data.procedures}
				actions={[
					{ 
						icon: <AccountBalanceIcon/>, 
						label: 'Gastos', 
						onClick: handleCost
					}
				]}
				fields={[
					{ 
						icon: <AirlineSeatFlatAngledIcon />,
						name: 'name', 
						label: 'Procedimiento',
						autoFocus: true
					},
					{ 
						name: 'prefix', 
						label: 'Prefijo'
					}
				]}
			/>
			<CostDialog isOpen={isOpen[0]} procedureId={isOpen[1]} handleClose={handleClose} />
		</Fragment>
	);
}

export default Component;