import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import FormDialog from './../../presentationals/FormDialog';
import SelectField from './../../presentationals/SelectField';

import GQL from './_gql';

const commissionOptions = [
	{ value: 1, label: 'Pendiente' },
	{ value: 2, label: 'Pagada' },
];

const CommissionDialog = props => {
	const [ values, setValues ] = useState(1);

	const [ updatePostSurgery, { loading } ] = useMutation(GQL.UPDATE_COMMISSION, {
		update(cache, { data }) {
			if(data.updateCrm.postSurgeryCommission === 1){ return; }
    	const id = `Crm:${data.updateCrm.id}`;
      cache.evict({ id });
      cache.gc();
      
    }
	});

	useEffect(() => {
		if(props.params.commission){
			setValues(parseInt(props.params.commission));
		}else {
			setValues(1);
		}
	}, [props.params.commission]);

	const handleCloseForm = e => {
		props.handleClose();
	}

	const handleAction = async e => {
		try{
			const { crmId: id } = props.params;
			await updatePostSurgery({ variables: { id, commission: parseInt(values) } });
			setValues(1);
			handleCloseForm();
		}catch(e){
			console.log(e.message)
		}
	}

	const handleChange = e => {
    setValues(e.target.value);
  }

	return (
		<FormDialog
			isOpen={props.isOpen}
			isLoading={loading}
			title='Comisión'
      handleClose={handleCloseForm}
      handleAction={handleAction}
      actionLabel='Actualizar'
		>
			<SelectField
				name='commission'
				label='Estatus de Comisión'
				onChange={handleChange}
				value={values}
				options={commissionOptions}
			/>
		</FormDialog>
	);
};

export default CommissionDialog;