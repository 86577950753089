import jsPDF from 'jspdf';
import { format } from 'date-fns';
import rowFormat from './../Table/helpers/Format';

const currentDate = new Date();

const object = (title, keys, data) => {
	const doc = new jsPDF('landscape');	
	let breakLine = header(doc, title, keys);

	const width = 275 / parseInt(keys.length);
	// let breakLine = 33;
	let heightBreakLine = 4;
	let rowsLine = 1;

	data.forEach( row => {
		breakLine = breakLine + heightBreakLine;
		let x = 10;
		
		keys.forEach(({ key, align = 'left', ...el }) => {
			const value = key.includes(' ') 
				? key.split(' ').reduce((string, elCh) => {
					const tmpData = string[elCh] || el.ifNull || '-';
					return tmpData;
				}, row)
				: row[key];

			const label = doc.splitTextToSize(rowFormat(value,el.format),(width - 2));
			rowsLine = label.length > rowsLine ? label.length : rowsLine;

			const tmpX = alignText(x,width,align);
			doc.text(tmpX, breakLine, label, align);
			
			x = x + width;
		});

		breakLine = breakLine + (3 * rowsLine);
		doc.line(10, breakLine, 285, breakLine);

		rowsLine = 1;

		if(breakLine >= 200){
			doc.addPage('L');
			breakLine = header(doc, title, keys);
		}
	});


	doc.save(`${title}_${format(currentDate, "dd-MMM-yyyy_HH-mm")}.pdf`);
}

const header = (doc, title, keys) => {
	let rowsLine = 1;

	doc.setFontSize(14);
	doc.text(title.toUpperCase(), 10, 20);
	doc.text(`${format(currentDate, "dd-MMM-yyyy HH:mm")}`, 285, 20, 'right');

	const width = 275 / keys.length;
	let x = 10;

	doc.setFontSize(10);
	keys.forEach(({ label, align, format }) => {
		const tmpX = alignText(x,width,align);

		const labelFix = doc.splitTextToSize(label,(width - 2));
		rowsLine = labelFix.length > rowsLine ? labelFix.length : rowsLine;
		
		doc.text(tmpX,30,labelFix,align);
		x = x + width;
	});
	const breakLine = 31 + (2 * rowsLine);
	doc.line(10, breakLine, 285, breakLine);
	return breakLine
}

const alignText = (x,width,align) => {
	let tmpX = x;
	switch(align) {
		case 'right':
			tmpX = x + width - 2;
			break;
		case 'center':
			tmpX = width / 2 + x;
			break;
		default:
			tmpX = x;
			break;
	}

	return tmpX;
}

export default object