import React from 'react';

import { useQuery, gql  } from '@apollo/client';

import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


import { snackbar } from './../../apollo/cache';

const GET_LOCAL = gql`
  query Snackbar {
    snackbar @client
  }
`;

const Alert = props => 
	<MuiAlert elevation={6} variant="filled" {...props} />

const Snackbar = props => {
  
  const { data } = useQuery(GET_LOCAL);
  
	const handleClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    snackbar({ isOpen: false, time: 3000, label: '', severity: 'success' });
  };

	return (
		<MuiSnackbar 
      open={ data.snackbar.isOpen } 
      autoHideDuration={ data.snackbar.time || null } 
      anchorOrigin={{ vertical: props.vertical || 'bottom', horizontal: props.horizontal || 'right' }} 
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={data.snackbar.severity || 'success'}>
        { data.snackbar.label }
      </Alert>
    </MuiSnackbar>
	);
}

export default Snackbar;