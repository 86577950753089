import React from "react";
import { Switch } from "react-router-dom";

import { Authenticated, Unauthenticated } from "./helpers";

import Login from "./../../containers/login";
// import Main from "./../../containers/main";
import Customer from "./../../containers/customer";
import Employee from "./../../containers/employee";
import Procedure from "./../../containers/procedure";
import PostSurgery from "./../../containers/postSurgery";
import RecoveryHouse from "./../../containers/recoveryHouse";
import Crm from "./../../containers/crm";
import Pdf from "./../../containers/pdf";
import Indicator from "./../../containers/indicator";
import CrmLog from "./../../containers/crmLog";
import Product from "./../../containers/product";
import Sale from "./../../containers/sale";
import Balance from "./../../containers/balance";

const _privileges = localStorage.getItem('privileges');

const PlatformRoutes = () =>
  <Switch>
    <Unauthenticated path="/public/login" component={Login} />
    <Authenticated exact path="/" component={Crm} />
    { parseInt(_privileges) === 1 && <Authenticated exact path="/customer" component={Customer} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/employee" component={Employee} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/procedure" component={Procedure} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/product" component={Product} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/sale" component={Sale} /> }
    <Authenticated exact path="/postSurgery" component={PostSurgery} />
    <Authenticated exact path="/recoveryHouse" component={RecoveryHouse} />
    <Authenticated exact path="/crm" component={Crm} />
    <Authenticated exact path="/review/pdf" component={Pdf} />
    { (parseInt(_privileges) === 1 || parseInt(_privileges) === 5) && <Authenticated exact path="/indicator" component={Indicator} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/crmLog" component={CrmLog} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/balance" component={Balance} /> }
  </Switch>

export default PlatformRoutes;