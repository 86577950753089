import React, { Fragment, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';

import LinearForm from './../../../../presentationals/LinearForm';
import Autocomplete from './../../../../presentationals/Autocomplete';
import TextField from './../../../../presentationals/TextField';

import GQL_PRODUCT from './../../../product/_gql';

const defaultValues = {
	cost: 0,
	discount: 0,
	quantity: 0,
	productId: null,
}

const Form = props => {
	const [ values, setValues ] = useState(defaultValues);
	const [ products, setProducts ] = useState([]);
	const [ errors, setErrors ] = useState({});

	const { data: productsData } = useQuery(GQL_PRODUCT.GET);

	useEffect(() => {
		if(productsData && productsData.products){
			const tmpProducts = productsData.products.rows.map(el => ({ value: el.id, label: el.name, price: el.price, cost: el.cost }));
			setProducts(tmpProducts);
		}
	}, [productsData]);

	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

	const handleAutocompleteChange = (e, name, value) => {
		setValues(values => ({ ...values, cost: value.cost }));
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAddProduct = e => {
  	try{

  		if(!values.productId){ 
  			const e = new Error('Selecciona un producto.')
  			e.name = 'productId';
  			throw e; 
  		}

  		if(parseFloat(values.quantity) <= 0 || isNaN(parseFloat(values.quantity))){ 
  			const e = new Error('Proporciona la cantidad de producto.')
  			e.name = 'quantity';
  			throw e; 
  		}
  		
  		props.handleAdd({
  			product: values.productId,
  			quantity: parseFloat(values.quantity),
  			discount: parseFloat(values.discount),
  			cost: parseFloat(values.cost),
  		});

  		setValues(defaultValues);
  		setErrors({});
  	}catch(e) {
  		setErrors({ [e.name]: e.message });
  	}
  	
  }

	return (
		<Fragment>
			<div>
				<LinearForm handleAdd={handleAddProduct} align='center'>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<Autocomplete 
				        name='productId'
				        label='Producto' 
				        variant='outlined'
				        onChange={handleAutocompleteChange}
				        options={products}
				        value={values.productId}
				        error={errors.productId}
				        noFormat
				      />
						</Grid>
						<Grid item xs={12} md={3}>
							<TextField 
				        name='quantity'
				        label='Cant.'
				        type='number' 
				        variant='outlined'
				        onChange={handleChange}
				        value={values.quantity}
				        error={errors.quantity}
				        noFormat
				      />
						</Grid>
						{
							props.type === 1 ?
							<Grid item xs={12} md={3}>
								<TextField 
					        name='discount'
					        label='Desc.'
					        type='number' 
					        variant='outlined'
					        onChange={handleChange}
					        value={values.discount}
					        error={errors.discount}
					        noFormat
					      />
							</Grid>	:
							<Grid item xs={12} md={3}>
								<TextField 
					        name='cost'
					        label='Costo.'
					        type='number' 
					        variant='outlined'
					        onChange={handleChange}
					        value={values.cost}
					        error={errors.cost}
					        noFormat
					      />
							</Grid>
						}
					</Grid>
				</LinearForm>
			</div>
		</Fragment>
	);
}

export default Form;