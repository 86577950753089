import React, { useRef, useEffect, useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		flex: 1
	},
  table: {
  	height: props => props.height,
  	maxHeight: props => props.height
  }
}));

const Component = props => {

	const [ height, setHeight ] = useState(0);

	const targetRef = useRef();
	const classes = useStyles({ height });

	useEffect(() => {
		setHeight(targetRef.current.offsetHeight)
	}, []);

	return (
		<div ref={targetRef} className={classes.container}>
			<TableContainer className={classes.table}>
				<Table stickyHeader size="small">
					{ props.children }
				</Table>
			</TableContainer>
		</div>
	)
}

export default Component;