import React, { useState, useEffect, Fragment } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';

import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroidOutlined';
import RoomIcon from '@material-ui/icons/RoomOutlined';

import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Header from './../../presentationals/Table/header';
import Table from './../../presentationals/Table/helpers/Table';
import BodyCell from './../../presentationals/Table/helpers/BodyCell';
import BodyRow from './../../presentationals/Table/helpers/BodyRow';
import format from './../../presentationals/Table/helpers/Format';
import TableHead from './../../presentationals/Table/tableHead';
import ActionForm from './../../presentationals/ActionForm';
import Snackbar from './../../presentationals/Snackbar';
import FilterDrawer from './../../presentationals/FilterDrawer';
import SelectField from './../../presentationals/SelectField';
import TablePagination from './../../presentationals/Table/tablePagination';

import { searchField } from './../../apollo/cache';

import { statusToLabel, subStatusToLabel, Link, statusOptions, subStatusOptions } from './helpers';
import useStyles from './style';
import RowOptions from './RowOptions';
import PersonalDataDialog from './personalData.dialog';

import GQL from './_gql';
import GQL_PROCEDURE from './../procedure/_gql';
import GQL_EMPLOYEE from './../employee/_gql';

const _privileges = parseInt(localStorage.getItem('privileges'));
const _userId = ![1, 4].includes(parseInt(_privileges)) ? localStorage.getItem('id') : null;

export const catchmentOptions = [
	{ value: 1, label: 'WhatsApp' },
	{ value: 2, label: 'Facebook' },
	{ value: 3, label: 'Instagram' },
	{ value: 4, label: 'Recomendación' },
	{ value: 5, label: 'Contacto del Doctor' },
	{ value: 6, label: 'Cliente' },
	{ value: 7, label: 'No Identificado' },
	{ value: 8, label: 'Dr. Omar' },
	{ value: 9, label: 'WhatsApp/Facebook' },
  { value: 10, label: 'WhatsApp/Instagram' },
  { value: 11, label: 'Google' },
  { value: 12, label: 'TikTok' },
];

const StatusOptions = [
	{ value: -1, label: 'TODOS' },
	...statusOptions
];

const SubstatusOptions = [
	{ value: -1, label: 'TODOS' },
	...subStatusOptions
];

const statusDateOptions = [
	{ value: true, label: 'TODOS' },
	{ value: false, label: 'PENDIENTES' }
];

const generalStatusOptions = [
	{ value: 1, label: 'TODOS' },
	// { value: 2, label: 'COMPLETOS' },
	{ value: 3, label: 'INCOMPLETOS' }
];

const nationalityOptions = [
	{ value: 1, label: 'Mexicana' },
	{ value: 2, label: 'Extranjera' }
];

const orderByOptions = [
	{ value: 1, label: 'Fecha de Registro' },
	{ value: 2, label: 'Nombre' },
	{ value: 3, label: 'Fecha de Cirugía | ASC' },
	{ value: 4, label: 'Fecha de Cirugía | DESC' },
];

export const surgeryDoctorOptionsSelect = [
	{ value: 1, label: 'Doctor Omar' },
	{ value: 2, label: 'Doctor Alonso' },
	{ value: 3, label: 'Bariatria' },
	{ value: 4, label: 'Doctora Prado' },
	{ value: 5, label: 'Doctor Rene' },
	{ value: 6, label: 'Turismo médico' },
	{ value: 7, label: 'Bariatra Juarez' },
];


export const surgeryDoctorOptions = [
	{ value: -1, label: 'Todos' },
	{ value: 0, label: 'No asignado' },
	...surgeryDoctorOptionsSelect
];

const tmpRows = [
	{ key: 'createdAt', label: 'Registro', background: '#00bfff', color: '#fff' },
	{ key: 'surgeryDoctor', label: 'Doctor', background: '#00bfff', color: '#fff' },
	{ key: 'status', label: 'Estatus', align: 'center', background: '#00bfff', color: '#fff' },
	{ key: 'subStatus', label: 'Subtipo', align: 'center', background: '#00bfff', color: '#fff' },
	{ key: 'catchment', label: 'Captación', background: '#00bfff', color: '#fff' },
	{ key: 'statusDate', label: 'Seguimiento', background: '#00bfff', color: '#fff' },
	{ key: 'name', label: 'Paciente', background: '#00bfff', color: '#fff' },
	{ key: 'oneProcedureId', label: 'Proc1', background: '#00bfff', color: '#fff' },
	{ key: 'twoProcedureId', label: 'Proc2', background: '#00bfff', color: '#fff' },
	{ key: 'threeProcedureId', label: 'Proc3', background: '#00bfff', color: '#fff' },
	{ key: 'name1', label: 'Paciente', background: '#00bfff', color: '#fff' },
	{ key: 'preSurgeryDate', label: 'PreCirugía', background: '#00bfff', color: '#fff' },
	{ key: 'surgeryDate', label: 'Cirugía', background: '#00bfff', color: '#fff' },
	{ key: 'feedbackDate', label: 'Confirmación', background: '#00bfff', color: '#fff' },
	{ key: 'xopDate', label: 'XOP', background: '#00bfff', color: '#fff' },
	{ key: 'cancelDate', label: 'Cancelación', background: '#00bfff', color: '#fff' },
	{ key: 'pictures', label: 'Fotos', background: '#00bfff', color: '#fff' },
	{ key: 'total', label: 'Presupuesto', background: '#00bfff', color: '#fff', align: 'right' },
	{ key: 'warningMessage', label: 'LlenadoDeDatos', background: '#00bfff', color: '#fff' },
	{ key: 'effectivity', label: 'Efectividad', background: '#00bfff', color: '#fff' },
	{ key: 'User', label: 'Vendedor', background: '#00bfff', color: '#fff' },
	{ key: 'tcp', label: 'TCP', background: '#00bfff', color: '#fff', align: 'center' },
	{ key: 'tcx', label: 'TCX', background: '#00bfff', color: '#fff', align: 'center' },
	{ key: 'ttx', label: 'TTX', background: '#00bfff', color: '#fff', align: 'center' },
];

const defaultFilter = {
	userId: _userId,
	status: -1,
	subStatus: -1,
	statusDate: true,
	generalStatus: 1,
	orderBy: 1,
	surgeryDoctor: -1
};

const Component = props => {

	const classes = useStyles();

	const searchFieldVar = useReactiveVar(searchField);

	const params = new URLSearchParams(props.location.search);
	const isAdmin = params.get('admin');
	const isDoctor = params.get('doctor');
	const externalCrmId = params.get('crmId');

	const [ openFromExternal, setOpenFromExternal ] = useState({});
	const [ procedures, setProcedures ] = useState([]);
	const [ showOptions, isShowOptions ] = useState([false, null, null]);
	const [ isPersonalDataOpen, setIsOpenPersonalDataOpen ] = useState([false, null]);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState(defaultFilter);
	const [ userOptions, setUserOptions ] = useState([]);
	const [ Rows, setRows ] = useState(tmpRows);
	const [ offset, setOffset ] = useState(0);
	const [ limit, setLimit ] = useState(50);
	
	const { data } = useQuery(GQL.GET,{ 
		variables: {
			...filter, offset, limit, 
			customer: searchFieldVar,
			userId: parseInt(filter.userId) === -1 ? null : filter.userId,
			status: isDoctor ? [4] : parseInt(filter.status) === -1 ? null : [filter.status],
			subStatus: parseInt(filter.subStatus) === -1 ? null : filter.subStatus,
			showByStatusDate: filter.statusDate,
			generalStatus: filter.generalStatus,
			surgeryDoctor: filter.surgeryDoctor >= 0 ? filter.surgeryDoctor : null
		},
		fetchPolicy: "cache-and-network"
	});
	const { data: dataProcedures } = useQuery(GQL_PROCEDURE.GET);
	const { data: dataEmployees } = useQuery(GQL_EMPLOYEE.GET);

	useEffect(() => {
		if(!isNaN(parseFloat(externalCrmId))){
			setIsOpenPersonalDataOpen([true, externalCrmId]);
		}
	}, [externalCrmId]);

	useEffect(() => {
		if(isDoctor){
			const notAllowedKeys = ['createdAt', 'status', 'subStatus', 'statusCommentary', 'statusDate', 'commision', 'paid', 'balance', 'payment', 'total'];
			const tmp = tmpRows.filter(el => !notAllowedKeys.includes(el.key) && el);
			setRows(tmp);
		} else {
			const allowedKeys = [];
			const tmpAllowed = tmpRows.filter(el => !allowedKeys.includes(el.key) && el);
			setRows(tmpAllowed);
		}
	}, [isDoctor]);
	
	useEffect(() => {
		if(dataEmployees){
			const tmp = dataEmployees && dataEmployees.users.rows.map(el => ({
				value: el.id, label: el.name
			}));
			setUserOptions([ { value: -1, label: 'TODOS' }, ...tmp ]);
		}
	}, [dataEmployees]);
  
	useEffect(() => {
		if(dataProcedures){
			const tmp = dataProcedures && dataProcedures.procedures.rows.map(el => ({
				value: el.id, label: el.prefix
			}));
			setProcedures(tmp);
		}
	}, [dataProcedures]);

	const handleClick = (e, el) => {
		const tmp = [
			{ label: format(el.createdAt, 'date'), tag: 'Registro', noEdit: true},
			{ row: 'status', value: el.status, label: statusToLabel(el.status), tag: 'Estatus' },
			{ row: 'subStatus', value: el.subStatus, label: subStatusToLabel(el.subStatus), tag: 'SubEstatus'},
			{ row: 'statusCommentary', value: el.statusCommentary, label: format(el.statusCommentary), tag: 'Comentario'},
			{ row: 'statusDate', value: el.statusDate, label: format(el.statusDate, 'date'), tag: 'Seguimiento'},
		];
		isShowOptions([true, tmp, el.id]);
	}

	const handleCloseOptions = () => {
		isShowOptions([false, null]);
	}

	const handleOptionClick = (e, params) => {
		setOpenFromExternal({ ...params, open: true });
	}

	const handleOptionClose = () => {
		setOpenFromExternal({});
	}

	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setFilter(values => ({ ...values, [name]: value }));
  }

  const handleOpenPersonalData = (e, params) => {
  	setIsOpenPersonalDataOpen([true, params]);
  }

  const handlePersonalDataClose = () => {
  	setIsOpenPersonalDataOpen([false, null]);
  }

  const handleRows = (page, rowsPerPage) => {
  	setOffset(page);
  	setLimit(rowsPerPage);
	};
	return (
		(((isAdmin || isDoctor) && [1, 4].includes(_privileges)) || (!isDoctor && !isAdmin && [1, 2, 4].includes(_privileges)))
			? <div className={classes.root}>
					{	!showOptions[0] 
						? <Header
								title='Seguimiento de Clientes' 
								qty={data ? data.crms.count : 0}
								filter={handleOpen}
								create={isAdmin ? false : true}
								noMenu
							/>
						: <RowOptions 
								openFromExternal={openFromExternal} 
								closeOpenFromExternal={handleOptionClose} 
								handleClose={handleCloseOptions} 
								values={showOptions[1]} 
								_id={showOptions[2]} 
								procedures={procedures} 
							/>
					}
					<Table>
						<TableHead Rows={Rows} direction='asc' noActionButtons />
						<TableBody>
						{
							data && data.crms.rows.map(el => 
								<BodyRow 
									key={el.id} 
									className={el.background ? classes[el.background] : classes.regular} 
									params={el}
									handleClick={handleClick}
								>
									{ !isDoctor && <BodyCell noWrap>{format(el.createdAt, 'date')}</BodyCell> }
									<BodyCell noWrap>
										<Link handleClick={handleOptionClick} params={{ id: el.id, row: 'surgeryDoctor', value: el.surgeryDoctor }}>
											{format(el.surgeryDoctor, 'surgeryDoctor')}
										</Link>
									</BodyCell>
									{ !isDoctor && 
										<BodyCell align='center' noWrap>
											<Link handleClick={handleOptionClick} params={{ id: el.id, row: 'status', value: el.status }}>
												{statusToLabel(el.status)}
											</Link>
										</BodyCell>
									}
									{ !isDoctor && 
										<BodyCell align='center' noWrap>
											<Link handleClick={handleOptionClick} params={{ id: el.id, row: 'subStatus', value: el.subStatus }}>
												{subStatusToLabel(el.subStatus)}
											</Link>
										</BodyCell>
									}
									{ !isDoctor && 
										<BodyCell noWrap>
											{format(el.catchment, 'catchment')}
										</BodyCell>
									}
									{ !isDoctor && 
										<BodyCell background={el.statusDateBackground} noWrap>
											<Link handleClick={handleOptionClick} params={{ id: el.id, row: 'statusDate', value: el.statusDate }}>
												{format(el.statusDate, 'date')}
											</Link>
										</BodyCell>
									}
									<BodyCell noWrap>
										{
											<Link handleClick={handleOpenPersonalData} params={el.id}>
												{format(el.Customer.name)}
											</Link>
										}
									</BodyCell>
									<BodyCell noWrap>{el.OneProcedure ? el.OneProcedure.prefix : '-'}</BodyCell>
									<BodyCell noWrap>{el.TwoProcedure ? el.TwoProcedure.prefix : '-'}</BodyCell>
									<BodyCell noWrap>{el.ThreeProcedure ? el.ThreeProcedure.prefix : '-'}</BodyCell>
									
									<BodyCell noWrap>
										{
											<Link handleClick={handleOpenPersonalData} params={el.id}>
												{format(el.Customer.name)}
											</Link>
										}
									</BodyCell>
									<BodyCell noWrap>
										<Link handleClick={handleOptionClick} params={{ id: el.id, row: 'preSurgeryDate', value: el.preSurgeryDate }}>
											{format(el.preSurgeryDate, 'dateTime')}
										</Link>
									</BodyCell>
									<BodyCell background={el.surgeryDateBackground} noWrap>
										<Link handleClick={handleOptionClick} params={{ id: el.id, row: 'surgeryDate', value: el.surgeryDate }}>
											{format(el.surgeryDate, 'dateSurgery')}
										</Link>
									</BodyCell>
									<BodyCell background={el.feedbackDateBackground} noWrap>
										<Link handleClick={handleOptionClick} params={{ id: el.id, row: 'feedbackDate', value: el.feedbackDate }}>
											{format(el.feedbackDate, 'dateTime')}
										</Link>
									</BodyCell>
									<BodyCell noWrap>
										<Link handleClick={handleOptionClick} params={{ id: el.id, row: 'xopDate', value: el.xopDate }}>
											{format(el.xopDate, 'dateTime')}
										</Link>
									</BodyCell>
									<BodyCell noWrap>
										<Link handleClick={handleOptionClick} params={{ id: el.id, row: 'cancelDate', value: el.cancelDate }}>
											{format(el.cancelDate, 'dateTime')}
										</Link>
									</BodyCell>
									<BodyCell background={el.picturesBackground} noWrap>
										{format(el.pictures)}
									</BodyCell>
									<BodyCell background={el.totalBackground} noWrap align='right'>
										<Link handleClick={handleOptionClick} params={{ id: el.id, row: 'total', value: el.total }}>
											{format(el.total, 'currency')}
										</Link>
									</BodyCell>
									<BodyCell background={el.warningMessage === 'Incompleto' ? 'danger' : 'regular'} noWrap>
										{format(el.warningMessage)}
									</BodyCell>
									<BodyCell background={el.effectivity === 'Pendiente' ? 'danger' : 'regular'} noWrap>
										{format(el.effectivity)}
									</BodyCell>
									<BodyCell noWrap>
										{format(el.User ? el.User.name : '-')}
									</BodyCell>
									<BodyCell noWrap>
										{format(el.tpc ? el.tpc : '-')}
									</BodyCell>
									<BodyCell noWrap>
										{format(el.tcx ? el.tcx : '-')}
									</BodyCell>
									<BodyCell noWrap>
										{format(el.ttx ? el.ttx : '-')}
									</BodyCell>
								</BodyRow>
							)
						}
						</TableBody>
					</Table>
					<TablePagination rowsPerPage={50} count={(data && data.crms.count) || 0} handleRows={handleRows} />
					<ActionForm
		      	operation='crms'
		      	singular='Cliente'
		      	cacheVariables={{
		      		...filter, offset, limit, 
		      		customer: searchFieldVar,
							userId: parseInt(filter.userId) === -1 ? null : filter.userId,
							status: isDoctor ? [4] : parseInt(filter.status) === -1 ? null : [filter.status],
							subStatus: parseInt(filter.subStatus) === -1 ? null : filter.subStatus,
							showByStatusDate: filter.statusDate,
							generalStatus: filter.generalStatus,
							surgeryDoctor: filter.surgeryDoctor >= 0 ? filter.surgeryDoctor : null
		      	}}
		      	fields={[
							{ 
								icon: <AccountCircleIcon />,
								name: 'name', 
								label: 'Nombre',
								autoFocus: true
							},
							{ 
								name: 'age', 
								label: 'Edad'
							},
							{ 
								icon: <PhoneAndroidIcon />,
								name: 'phone', 
								label: 'Teléfono',
								type: 'phone'
							},
							{ 
								icon: <RoomIcon />,
								name: 'place', 
								label: 'Ubicación'
							},
							{ 
								name: 'nationality', 
								label: 'Nacionalidad',
								field: 'select',
								defaultValue: 1,
								options: nationalityOptions
							},
							{
								name: 'surgeryDoctor',
								label: 'Doctor',
								field: 'select',
								defaultValue: 1,
								options: surgeryDoctorOptionsSelect
							},
							{ 
								name: 'oneProcedureId', 
								label: 'Procedimiento 1',
								field: 'autocomplete',
								options: procedures
							},
							// { 
							// 	name: 'twoProcedureId', 
							// 	label: 'Procedimiento 2',
							// 	field: 'autocomplete',
							// 	options: procedures
							// },
							// { 
							// 	name: 'threeProcedureId', 
							// 	label: 'Procedimiento 3',
							// 	field: 'autocomplete',
							// 	options: procedures
							// },
							// { 
							// 	name: 'fourProcedureId', 
							// 	label: 'Procedimiento 4',
							// 	field: 'autocomplete',
							// 	options: procedures
							// },
							// { 
							// 	name: 'fiveProcedureId', 
							// 	label: 'Procedimiento 5',
							// 	field: 'autocomplete',
							// 	options: procedures
							// },
							{ 
								name: 'catchment', 
								label: 'Método de Captación',
								field: 'select',
								defaultValue: 7,
								options: catchmentOptions
							},
						]}
		      	create={GQL.CREATE}
		      	get={GQL.GET}
		      /> 
		      <FilterDrawer 
			      isOpen={isOpen} 
			      handleClose={handleClose}
			    >
			    	{
			    		isAdmin &&
			    			<Fragment>
			    				<div className={classes.filterContent}>
					          <Typography variant="subtitle1">
					            Empleados:
					          </Typography>
					          <SelectField
					          	name='userId'
					          	value={filter.userId || -1}
					          	onChange={handleChange}
					          	options={userOptions}
					          	variant='outlined'
					          	noFormat
					          />
					        </div>
					        <Divider/>
			    			</Fragment>
			    	}
			    	
		        <div className={classes.filterContent}>
		        	{ !isDoctor &&
		        			<Fragment>
					          <Typography variant="subtitle1">
					            Estatus:
					          </Typography>
					          <SelectField
					          	name='status'
					          	value={filter.status || -1}
					          	onChange={handleChange}
					          	options={StatusOptions}
					          	variant='outlined'
					          	noFormat
					          />
					          <br/>
				          </Fragment>
			        }
		          <Typography variant="subtitle1">
		            Subtipo:
		          </Typography>
		          <SelectField
		          	name='subStatus'
		          	value={filter.subStatus || -1}
		          	onChange={handleChange}
		          	options={SubstatusOptions}
		          	variant='outlined'
		          	noFormat
		          />
		        </div>        
		        <Divider/>
		        <div className={classes.filterContent}>
		        	<Typography variant="subtitle1">
		            Fecha de Seguimiento:
		          </Typography>
		          <SelectField
		          	name='statusDate'
		          	value={filter.statusDate}
		          	onChange={handleChange}
		          	options={statusDateOptions}
		          	variant='outlined'
		          	noFormat
		          />
		        	<Typography variant="subtitle1">
		            Doctor Asignado:
		          </Typography>
		          <SelectField
		          	name='surgeryDoctor'
		          	value={filter.surgeryDoctor}
		          	onChange={handleChange}
		          	options={surgeryDoctorOptions}
		          	variant='outlined'
		          	noFormat
		          />
		        </div>
						<Divider/>
						<div className={classes.filterContent}>
		        	<Typography variant="subtitle1">
		            Llenado de Datos:
		          </Typography>
		          <SelectField
		          	name='generalStatus'
		          	value={filter.generalStatus}
		          	onChange={handleChange}
		          	options={generalStatusOptions}
		          	variant='outlined'
		          	noFormat
		          />
		        </div>
						<Divider/>
						<div className={classes.filterContent}>
		        	<Typography variant="subtitle1">
		            Ordenar por:
		          </Typography>
		          <SelectField
		          	name='orderBy'
		          	value={filter.orderBy}
		          	onChange={handleChange}
		          	options={orderByOptions}
		          	variant='outlined'
		          	noFormat
		          />
		        </div>
						<Divider/>
			    </FilterDrawer>
		      <PersonalDataDialog 
		      	isOpen={isPersonalDataOpen[0]} 
		      	crmId={isPersonalDataOpen[1]} 
		      	handleClose={handlePersonalDataClose} 
		      	isAdmin={isAdmin}
		      />
		      <Snackbar horizontal='center' />
				</div>
		: <div className={classes.noPrivileges}>No tienes privilegios para consultar este módulo.</div>
	);
}

export default Component;