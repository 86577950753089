import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import PrintIcon from '@material-ui/icons/PrintOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';

import TablePDF from '../../PDF/table.js';
import TableXLS from '../../XLS/table.js';

const HeaderMenu = props => {
  
  const downloadPDF = () => TablePDF(props.title,props.Rows,props.records);
  const downloadXLS = () => TableXLS(props.title,props.Rows,props.records);

  return (
    <Menu
      id="header-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
    >
      <MenuItem onClick={downloadPDF}>
        <ListItemIcon>
          <PrintIcon />
        </ListItemIcon>
        <ListItemText primary="Descargar | PDF" />
      </MenuItem>
      <MenuItem onClick={downloadXLS}>
        <ListItemIcon>
          <CloudDownloadIcon />
        </ListItemIcon>
        <ListItemText primary="Descargar | XLS" />
      </MenuItem>
      { props.return && <Divider /> }
      {
        props.return && 
          <MenuItem onClick={props.return}>
            <ListItemIcon>
              <KeyboardReturnIcon />
            </ListItemIcon>
            <ListItemText primary="Regresar" />
          </MenuItem>
      }
    </Menu>
  )
}

export default HeaderMenu;