import React from "react";

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { withStyles, makeStyles } from '@material-ui/core/styles';

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',

  },
  typography: { margin: theme.spacing(2) },
  progress: { position: 'absolute', width: '100%' },
  rightContent: { paddingRight: theme.spacing(2) }
}))(props => {
  const { children, classes } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Grid container alignItems='center'>
        <Grid item xs={12} md={props.rightContent ? 6 : 12}>
          <Typography className={ classes.typography } variant="h6">{children}</Typography>
        </Grid>
          { props.rightContent &&
              <Grid item xs={12} md={6} className={classes.rightContent}>
                {props.rightContent}
              </Grid>
          }
      </Grid>
    </MuiDialogTitle>
  );
});

const useDialogContentStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: props => props.noPadding ? 0 : theme.spacing(3),
    paddingTop: props => props => props.noPadding ? 0 : theme.spacing(1),
    backgroundColor: props => props.contentBackground ? props.contentBackground : '#fff'
  },
}));

const DialogContent = ({ noPadding, contentBackground, ...props }) => {
  const classes = useDialogContentStyles({ noPadding, contentBackground });
  return (
    <MuiDialogContent className={classes.root}>
      { props.children }
    </MuiDialogContent>
  ) 
};

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export { 
  DialogTitle,
  DialogContent,
  DialogActions
};