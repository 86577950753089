import { ApolloClient, ApolloLink } from '@apollo/client';

import errorLink from './errorLink';
import authLink from './authLink';
import httpLink from './httpLink';

// import typeDefs from './typeDefs';
// import resolvers from './resolvers';

import { cache } from './cache';

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    httpLink
  ]),
  cache
});

export default client;