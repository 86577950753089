import dateFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO'

import { currencyFormat } from './../../../helpers';

const format = (value, type) => {
	switch(type){
		case 'date':
			return value && value !== '-' 
				? new Date('2040/01/01 00:00:00') < new Date(value)
					? 'No Aplica' 
					: value 
						? dateFormat(parseISO(value), 'dd/MM/yyyy') 
						: '-'
				: '-';
		case 'dateSurgery':
			return value && value !== '-' 
				? new Date('2040/01/01 00:00:00') < new Date(value)
					? 'Es Espera' 
					: value 
						? dateFormat(parseISO(value), 'dd/MM/yyyy HH:mm:ss') 
						: '-'
				: '-';
		case 'dateTime':
			return value && value !== '-' ? dateFormat(parseISO(value), 'dd/MM/yyyy HH:mm:ss') : '-';
		case 'time':
			return value && value !== '-' ? dateFormat(parseISO(value), 'HH:mm:ss') : '-';
		case 'currency':
			return currencyFormat(value);
		case 'percent':
			return !isNaN(parseInt(value)) ? `${currencyFormat(value, false)}%` : '-';
		case 'balance':
			return (parseFloat(value) === 0) ? '-' : currencyFormat(value);
		case 'int':
			return !value ? '-' : parseInt(value);
		case 'productType': 
			return productType[value];
		case 'yesNoType': 
			return yesNoType(value);
		case 'printer':
			return printer[value];
		case 'action':
			return action[value];
		case 'orderStatus':
			return orderStatus[value];
		case 'typeSale':
			return typeSale[value];
		case 'privileges':
			return privileges[value];
		case 'catchment':
			return catchment[value];
		case 'postSurgeryStatus':
			return postSurgeryStatus[value] || '-';
		case 'postSurgeryCommission':
			return postSurgeryCommission[value] || 'Pendiente';
		case 'status':
			return status[value] || '-';
		case 'nationality':
			return nationality[value] || '-';
		case 'subStatus':
			return subStatus[value] || '-';
		case 'surgeryDoctor':
			return surgeryDoctor[value] || '-';
		default: return (typeof value === 'undefined' || value === '') ? '-' : value;
	}
}

export const nationality = ['-', 'Mexicana', 'Extranjera'];
export const productType = ['-', 'Unidad', 'Compuesto'];
export const yesNoType = value => value ? 'SI' : 'NO'; 
export const printer = ['No Imprimir', 'Impresora 1', 'Impresora 2'];
export const action = ['-','Retiro','Depósito','Caja Fuerte'];
export const orderStatus = ['Cancelada','Abierta','En Proceso','Cerrada'];
export const typeSale = ['-','Venta','Abastecimiento'];
export const privileges = ['-','Administrador','Ventas','Masajes','Observador','Indicadores'];
export const postSurgeryStatus = ['-','Pendiente','Asisitió', 'No asisitió'];
export const postSurgeryCommission = ['Pendiente','Pendiente','Pagada'];
export const status = ['-', 'PROS', '1RA', 'COT', 'XOP', 'OP', 'OPSF', 'CANCEL', 'OTRO'];
export const catchment = ['-', 'WhatsApp', 'Facebook', 'Instagram', 'Recomendación', 'Contacto del Doctor', 'Cliente',  'No Identificado', 'Dr. Omar', 'WhatsApp/Facebook', 'WhatsApp/Instagram', 'Google', 'TikTok'];
export const subStatus = ['-', 'EN PROC', 'NO CON', 'PRECIO', 'UBICAC', 'SIN FE', 'NO APL', 'DESPUES', 'NO INTE', 'OTRA FE', 'NO SATI', 'NO QUIE', 'CALCULAN', 'FOTOS'];
export const surgeryDoctor = ['-', 'D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8'];

export default format;