import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from './../../presentationals/FilterDrawer';
import SelectField from './../../presentationals/SelectField';

const typeOptions = [
	{ value: -1, label: 'Todos' },
	{ value: 1, label: 'Venta' },
	{ value: 2, label: 'Abastecimiento' },
];

const defaultValues = {
	type: -1
}

const FilterDialog = props => {
	const [ values, setValues ] = useState(defaultValues);

	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		const { startDate: start, endDate: end } = data;
		const type = parseInt(values.type) !== -1 ? [values.type] : undefined 
		props.handleAction(null, { start, end, type });
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FilterDrawer 
			isOpen={props.isOpen} 
			handleClose={handleClose} 
			handleAction={handleAction}
			showDates
		>
			<Typography variant="subtitle1">
        Tipo:
      </Typography>
      <SelectField
        name='type'
        variant='outlined'
        value={values.type}
        onChange={handleChange}
        options={typeOptions}
        noFormat
      />
		</FilterDrawer>
	);
}

export default FilterDialog;