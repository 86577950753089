import { gql } from '@apollo/client';
import client from './../index';

const REFRESH_TOKEN = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(input: { token: $token }) { accessToken }
  }
`;

const getNewToken = async () => {
  try {
    const token = localStorage.getItem('refresh') || '';
    const { data } = await client.mutate({
      mutation: REFRESH_TOKEN,
      variables: { token }
    });
    const newToken = data.refreshToken.accessToken;
    localStorage.setItem('token', newToken);
    return `Bearer ${newToken}`;
  }catch (e) {
    localStorage.clear();
    window.location.reload();
  }
}

export default getNewToken;