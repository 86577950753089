import React, { useState, useEffect } from 'react';

import { useMutation, useLazyQuery } from '@apollo/client';

import TrashIcon from '@material-ui/icons/DeleteOutline';
import SearchIcon from '@material-ui/icons/SearchOutlined';

import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import makeStyles from '@material-ui/core/styles/makeStyles';

import FormDialog from './../../presentationals/FormDialog';
import ImageField from './../../presentationals/ImageField';
import IconButton from './../../presentationals/IconButton';

import config from './../../config/config.json';

import GQL from './../crm/_gql';
import GQL_PICTURES from './_gql';

const useStyles = makeStyles((theme) => ({
  image: {
    height: 200,
    borderRadius: 5,
    margin: theme.spacing(1)
  },
  badgeButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0, 0, 5), 
    color: '#5c5c5c',
    backgroundColor: 'rgba(255,255,255,0.7)',
    top: 0, 
    '&:hover': {
      color: '#000',
      backgroundColor: '#fff',
    }
  },
  lastBadgeButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 5, 0, 0), 
    color: '#5c5c5c',
    backgroundColor: 'rgba(255,255,255,0.7)',
    top: 0, 
    '&:hover': {
      color: '#000',
      backgroundColor: '#fff',
    }
  },
}));

const PictureDialog = props => {

	const classes = useStyles();

	const [ previewImage, setPreviewImage ] = useState([null,null]);

	const [ createImage, { loading } ] = useMutation(GQL.UPLOAD_FILE,{
		update(cache, { data: response }) {
      try{
        const dataResponse = parseInt(response.updateFiles.id);
        const oldQuery = cache.readQuery({ query: GQL_PICTURES.GET_AFTER, variables: { id: props.crmId } });
        const newQuery = [ ...oldQuery.crm.After, { id: dataResponse, __typename: 'File' } ];
        cache.writeQuery({
          query: GQL.GET_BY_ID,
          variables: { id: props.crmId },
          data: { crm: { ...oldQuery.crm, After: newQuery } }
        });
      }catch(e){
        console.log(e.message);
      }
    },
	});

	const [ getImage, { data } ] = useLazyQuery(GQL_PICTURES.GET_AFTER);

	useEffect(() => {
		if(props.crmId){
			getImage({ variables: { id: props.crmId } })
		}
	}, [props.crmId, getImage]);
	
	const handleCloseForm = e => {
		props.handleClose();
	}

	const handleImage = (uri,file) => {
		window.URL.revokeObjectURL(previewImage[0]);
		setPreviewImage([uri,file]);
	}

	const handleAction = async () => {
		try{
			await createImage({ variables: { id: props.crmId, type: 'After', file: previewImage[1] } });
			handleCloseForm();
		}catch(e){
			console.log(e.message);
		}
	}

	const handleDeleteFile = (e, params) => {
  	console.log(params)
  }

  const handleShowFile = (e, params) => {
  	window.open(`${config["api-file-service"].url}/files/${params}`, '_href');
  }
  // console.log(props.images)
	return (
		<FormDialog
			isOpen={props.isOpen}
			isLoading={loading}
			title='Fotografía'
      handleClose={handleCloseForm}
      handleAction={handleAction}
      fullScreen
		>
			<ImageField
				croppedImage={handleImage}
				aspect={9/14}
				width={100}
				noHeight
			>
				<Grid container spacing={1} align='center' alignItems='center' justify='center' alignContent='center'>
				{ data && data.crm.After.map(el => 
					<Grid key={el.id} item md={3} xs={12}>
						<Badge
              overlap="circle"
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              badgeContent={
              	<IconButton  
                  params={el.id} 
                  action={handleShowFile} 
                  className={classes.badgeButton}
                  icon={<SearchIcon />}
                /> 
	            }
	          >
							<Badge
	              overlap="circle"
	              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
	              badgeContent={
                  <IconButton  
	                  params={el.id} 
	                  action={handleDeleteFile} 
	                  className={classes.lastBadgeButton}
	                  icon={<TrashIcon />}
	                />      
		            }
		          >
	              <img
	                src={`${config["api-file-service"].url}/files/${el.id}`} alt={`IMG-${el.id}`}
	                className={classes.image}
	              />
		          </Badge>
	          </Badge>
          </Grid>
				)}
				</Grid>
		</ImageField>	
		</FormDialog>
	);
};

export default PictureDialog;