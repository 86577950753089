import React, { Fragment, useState } from 'react';

import Form from './form';
import Table from './table';

const Cart = props => {

	const [ cartItems, setCartItems ] = useState([]);

	const addItemToCart = data => {
		const discount = parseInt(props.type) === 1
			? ((parseFloat(data.quantity) * parseFloat(data.product.price)) * parseFloat(data.discount)) / 100
			: 0;

		const subtotal = parseInt(props.type) === 1
			? parseFloat(data.product.price)
			: parseFloat(data.cost);

		const total = parseInt(props.type) === 1
			? (parseFloat(data.quantity) * parseFloat(data.product.price)) - discount
			: (parseFloat(data.quantity) * parseFloat(subtotal)) - discount;

		setCartItems(cartItems => ([ ...cartItems, { ...data, discount, subtotal, total } ]));
		props.getCart([ ...cartItems, { ...data, total } ]);
	};

	const removeItemFromCart = position => {
		const tmpCartItems = cartItems
			.map((el, i) => (parseInt(i) !== parseInt(position)) && el)
			.filter(el => el);
		setCartItems(tmpCartItems);
		props.getCart(tmpCartItems);
	}

	return (
		<Fragment>
			<Form handleAdd={addItemToCart} codeToClear={props.codeToClear} type={props.type} />
			<Table
				cartItems={cartItems}
				removeItem={removeItemFromCart}
				codeToClear={props.codeToClear}
				type={props.type}
			/>
		</Fragment>
	);
}

export default Cart