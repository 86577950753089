import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton'; 

const useStyles = makeStyles((theme: Theme) => ({
	tooltip: { marginTop: theme.spacing(0.5) }
}));

const Component = ({params, action, ...props}) => {
	
	const classes = useStyles();

	const handleClick = e => { action(e, params); }

	return (
		props.label 
			? <Tooltip title={props.label} classes={{ tooltip: classes.tooltip }}>
					<span>
			    	<IconButton onClick={ handleClick } aria-label={props.label} { ...props }>
			        { props.icon }
			      </IconButton>
		      </span>
		    </Tooltip>
		  : <IconButton onClick={ handleClick } aria-label={props.label} { ...props }>
	        { props.icon }
	      </IconButton>
	);

}

export default Component;