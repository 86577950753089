import React, { useState, useEffect, Fragment } from 'react';

import { format, differenceInDays } from "date-fns";

import { useQuery } from '@apollo/client';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import { currencyFormat } from './../../../helpers';

import FormDialog from './../../../presentationals/FormDialog';
import DateField from './../../../presentationals/DateField';
import BodyCell from './../../../presentationals/Table/helpers/BodyCell';
import BodyRow from './../../../presentationals/Table/helpers/BodyRow';

import GQL from './../_gql';
import ExpenseDialog from './../ExpenseDialog';

const currentDate = format(new Date(), 'yyyy/MM/dd');

const defaultTotal = {
	sale: 0,
	cost: 0,
	costPercent: 0,
	utility1: 0,
	utility1Percent: 0,
	expense: 0,
	expensePercent: 0,
	utility2: 0,
	utility2Percent: 0,
}

const defaultValues = {
	start: currentDate,
	end: currentDate,
	days: 1
}

const SummaryDialog = props => {

	const [ values, setValues ] = useState(defaultValues);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ total, setTotal ] = useState(defaultTotal);

	const { data, loading } = useQuery(GQL.GET_SUMMARY, { 
		variables: { 
			customer: '', start: values.start, end: values.end, limit: 1000, offset: 0,
			status: [5, 6, 7], userId: null, subStatus: null, orderBy: 1, showBySurgeryDate: true
		} 
	});

	useEffect(() => {
		if(data){
			const tmpTotal = data.crms.rows.reduce((total, el) => {
				const sale = total.sale + parseFloat(el.total);
				const tmpCost = el.Costs ? el.Costs.reduce((totalCh, elCh) => {
					return totalCh + parseFloat(elCh.cost);
				}, 0) : 0;
				const cost = total.cost + parseFloat(tmpCost);
				return { sale, cost };
			}, defaultTotal);
			const costPercent = (tmpTotal.cost / tmpTotal.sale) * 100;
			const utility1 = tmpTotal.sale - tmpTotal.cost;
			const utility1Percent = (utility1 / tmpTotal.sale) * 100;
			const totalExpense = data.crms.expense ? Object.keys(data.crms.expense).reduce((total, key) => {
				const value = isNaN(parseFloat(data.crms.expense[key])) ? 0 : parseFloat(data.crms.expense[key]);
				return total + value;
			}, 0) : 0;
			const expense = (parseFloat(totalExpense) / 30.5) * parseFloat(values.days);
			const expensePercent = tmpTotal.sale > 0 ? (expense / tmpTotal.sale) * 100 : 0;
			const utility2 = utility1 - expense;
			const utility2Percent = tmpTotal.sale > 0 ? (utility2 / tmpTotal.sale) * 100 : 0;
			const taxes = 7000;
			const taxesPercent = tmpTotal.sale > 0 ? (taxes / tmpTotal.sale) * 100 : 0;
			const utility3 = utility2 - taxes;
			const utility3Percent = tmpTotal.sale > 0 ? (utility3 / tmpTotal.sale) * 100 : 0;
			setTotal({ ...tmpTotal, costPercent, utility1, utility1Percent, expense, expensePercent, utility2, utility2Percent, taxes, taxesPercent, utility3, utility3Percent });
		}
	}, [data, values.days]);

	const handleClose = () => {
		props.handleClose();
	}

	const handleDateChange = (name, value) => {
		const tmpValue = format(value, 'yyyy/MM/dd')
		const start = name === 'start' ? new Date(tmpValue) : new Date(values.start);
		const end = name === 'end' ? new Date(tmpValue) : new Date(values.end);
    const days = differenceInDays(end, start);
    setValues(values => ({ ...values, [name]: tmpValue, days: days + 1 }));
	}

	const handleOpenExpenses = () => {
		setIsOpen(isOpen => !isOpen);
	}

	return (
		<Fragment>
			<FormDialog
				isOpen={props.isOpen}
				isLoading={loading}
				title='Resumen'
	      handleClose={handleClose}
	      hideAction
	      noPadding
	      rightContent={
	      	<Grid container spacing={1}>
	      		<Grid item xs={6}>
	      			<DateField
	      				noFormat
	      				name='start'
	      				label='Desde'
	      				value={values.start}
	      				onChange={handleDateChange}
	      				variant='outlined'
	      				size='small'
	      			/>
	      		</Grid>
	      		<Grid item xs={6}>
	      			<DateField
	      				noFormat
	      				name='end'
	      				label='Hasta'
	      				value={values.end}
	      				onChange={handleDateChange}
	      				variant='outlined'
	      				size='small'
	      			/>
	      		</Grid>
	      	</Grid>
	      }
			>
				<Table size="small">
					<TableBody>
						<BodyRow>
							<BodyCell>Venta Total</BodyCell>
							<BodyCell align='right'>{currencyFormat(total.sale || 0)}</BodyCell>
							<BodyCell align='right'>{currencyFormat(100, false)}%</BodyCell>
						</BodyRow>
						<BodyRow>
							<BodyCell>Costo de Venta Total</BodyCell>
							<BodyCell align='right'>{currencyFormat(total.cost || 0)}</BodyCell>
							<BodyCell align='right'>{currencyFormat(total.costPercent || 0, false)}%</BodyCell>
						</BodyRow>
						<BodyRow>
							<BodyCell><strong>Utilidad Bruta</strong></BodyCell>
							<BodyCell align='right'><strong>{currencyFormat(total.utility1)}</strong></BodyCell>
							<BodyCell align='right'>{currencyFormat(total.utility1Percent || 0, false)}%</BodyCell>
						</BodyRow>
						<BodyRow>
							<BodyCell>Gastos Fijos</BodyCell>
							<BodyCell align='right'>
								<Link onClick={handleOpenExpenses}>{currencyFormat(total.expense)}</Link>
							</BodyCell>
							<BodyCell align='right'>
								<Link onClick={handleOpenExpenses}>{currencyFormat(total.expensePercent, false)}%</Link>
							</BodyCell>
						</BodyRow>
						<BodyRow>
							<BodyCell><strong>Utilidad antes de Imptos</strong></BodyCell>
							<BodyCell align='right'><strong>{currencyFormat(total.utility2)}</strong></BodyCell>
							<BodyCell align='right'>{currencyFormat(total.utility2Percent, false)}%</BodyCell>
						</BodyRow>
						<BodyRow>
							<BodyCell>Impuestos</BodyCell>
							<BodyCell align='right'>{currencyFormat(total.taxes)}</BodyCell>
							<BodyCell align='right'>{currencyFormat(total.taxesPercent, false)}%</BodyCell>
						</BodyRow>
						<BodyRow>
							<BodyCell><strong>Utilidad Neta</strong></BodyCell>
							<BodyCell align='right'><strong>{currencyFormat(total.utility3)}</strong></BodyCell>
							<BodyCell align='right'>{currencyFormat(total.utility3Percent, false)}%</BodyCell>
						</BodyRow>
					</TableBody>
				</Table>
			</FormDialog>
			<ExpenseDialog isOpen={isOpen} handleClose={handleOpenExpenses} expense={data && data.crms.expense} />
		</Fragment>
	);
}

export default SummaryDialog;