import React, { useState, Fragment, useEffect } from 'react';

import CloseIcon from '@material-ui/icons/Close';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PaperMui from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import UploadDialog from './update.dialog';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		...theme.mixins.toolbar,
		padding: theme.spacing(0.5, 1.5, 0.5, 1.5),
	  borderBottom: '1px solid #e0e0e0', 
	  position: 'abosulute',
	  zIndex: 1000,
	  backgroundColor: "#fff",
	  [theme.breakpoints.down('xs')]: {
	  	padding: theme.spacing(1, 1.5, 1, 1.5)
    },
	},
	infoContainer: {
		display: 'flex',
		overflowX: 'auto'
	},
	paper: {
		margin: theme.spacing(0.5),
		padding: theme.spacing(0.5),
		cursor: 'cursor'
	},
	pointer: {
		margin: theme.spacing(0.5),
		padding: theme.spacing(0.5),
		cursor: 'pointer'
	}
}));

const RowOptions = props => {
	
	const classes = useStyles();

	const [ isOpen, setIsOpen ] = useState([false, null]);

	useEffect(() => {
		if(props.openFromExternal.open) {
			const { row, value, id, files, doctorCommentary } = props.openFromExternal;
			setIsOpen([ true, { row, value, id, files, doctorCommentary } ]);
			props.closeOpenFromExternal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.openFromExternal]); 

	const handleClick = (e, row, value) => {
		setIsOpen([ true, { row, value, id: props._id } ]);
	}

	const handleClose = () => {
		setIsOpen([ false, null ]);
		props.handleClose();
	}

	return (
		<Fragment>
		<Grid container alignItems="center" spacing={0} className={ classes.header }>
			<Grid item md={11} className={classes.infoContainer}>
				{
					props.values && props.values.map((el, i) => 
						<Paper 
							key={i}
							tag={el.tag}
							label={el.label}
							options={el.options}
							value={el.value}
							row={el.row}
							handleClick={!el.noEdit && handleClick}
						/>
					)
				}
			</Grid>
			<Grid item md={1} align='right'>
				<IconButton aria-label="close" color="secondary" onClick={props.handleClose}>
	        <CloseIcon />
	      </IconButton>
			</Grid>
		</Grid>
		<UploadDialog
			isOpen={isOpen[0]}
			params={isOpen[1]}
			handleClose={handleClose}
			procedures={props.procedures}
		/>
		</Fragment>
	);
}

const Paper = props => {

	const classes = useStyles();

	const handleClick = e => {
		props.handleClick && props.handleClick(e, props.row, props.value);
	}

	return (
		<PaperMui 
			elevation={1} 
			onClick={handleClick} 
			className={props.handleClick 
				? classes.pointer
				: classes.paper
			}
		>
			{props.tag}<br/>
			<Typography variant="body2" noWrap>
				<strong>{props.label}</strong>
			</Typography>
		</PaperMui>
	)
}

export default RowOptions;