import { gql } from '@apollo/client';

const GET = gql`
  query Users{
    users {
      count
      rows {
        id
        name
        username
        privileges  
      }
    }
  }
`;

const GET_NAME = gql`
  query Users{
    users {
      count
      rows {
        id
        name
        username
        privileges
      }
    }
  }
`;

const GET_BY_ID = gql`
  query User($id: ID!){
    user(id: $id) {
      id
      name
      username
      privileges
    }
  }
`;

const DELETE = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  mutation CreateUser(
    $name: String!
    $username: String!
    $password: String!
    $privileges: Int!
  ) {
    createUser(input: {
      name: $name
      username: $username
      password: $password
      privileges: $privileges
    }) {
      id
      name
      username
      privileges
    }
  }
`
const UPDATE = gql`
  mutation UpdateUser(
    $id: ID!
    $name: String!
    $username: String!
    $privileges: Int!
  ) {
    updateUser(
      id: $id, 
      input: {
        name: $name
        username: $username
        privileges: $privileges
      }
    ) {
      id
      name
      username
      privileges
    }
  }
`
const object = { GET, GET_BY_ID, DELETE, CREATE, UPDATE, GET_NAME };
export default object;