import React, { useState, Fragment } from 'react';

import { useQuery } from '@apollo/client';

import EditIcon from '@material-ui/icons/EditOutlined';

import Module from './../../presentationals/Module';

import GQL from './_gql';
import UpdateDialog from './update.dialog';

import useStyles from './style';

const _privileges = parseInt(localStorage.getItem('privileges'));

const Rows = [
	{ label: 'Estatus', key: 'status', sort: 'text', format: 'status', filter: true },
	{ label: 'Nombre', key: 'Customer name', sort: 'text', filter: true },
	{ label: 'Teléfono', key: 'Customer phone', sort: 'text' },
	{ label: 'Ubicación', key: 'Customer place', sort: 'text' },
	{ label: 'HablaEspañol', key: 'Customer spanish', sort: 'text', format: 'yesNoType' },
	{ label: 'Cirugía', key: 'surgeryDate', sort: 'date', format: 'dateTime' },
	{ label: 'Llegada', key: 'RecoveryHouse arriveDate', sort: 'date', format: 'dateTime' },
	{ label: 'Salida', key: 'RecoveryHouse departureDate', sort: 'date', format: 'dateTime' },
	{ label: 'EntradaRecoveryHouse', key: 'RecoveryHouse entryDate', sort: 'date', format: 'dateTime' },
	{ label: 'SalidaRecoveryHouse', key: 'RecoveryHouse outputDate', sort: 'date', format: 'dateTime' },
	{ label: 'Comentario', key: 'RecoveryHouse commentary', sort: 'text' }
];

const defaultValues = {
	arriveDate: '',
	departureDate: '',
	commentary: '',
	entryDate: '',
	outputDate: ''
}

const Component = props => {

	const classes = useStyles();

	const [ isOpenUpdateDialog, setIsOpenUpdateDialog ] = useState(false);
	const [ datas, setDatas ] = useState(defaultValues);

	const { data, loading } = useQuery(GQL.GET);

	const handleEdit = (e, params) => {
		setDatas({ RecoveryHouse: params.RecoveryHouse });
		// console.log(params.RecoveryHouse.arriveDate);
		setIsOpenUpdateDialog(true);
	}

	const handleCloseUpdateDialog = () => {
		setIsOpenUpdateDialog(false);
	}
	
	return (
		[1,3].includes(_privileges) ?
			<Fragment>
				<Module
					isLoading={loading}
					name='Recovery House'
					singular='Recovery Houses'
					operation={['recoveryHouses']}
					Rows={Rows}
					get={GQL.GET}
					getById={GQL.GET}
					create={GQL.UPDATE}
					noCreate
					noDelete
					noEdit
					records={data && data.recoveryHouses}
					actions={[
						{ 
							icon: <EditIcon/>, 
							label: 'Actualizar', 
							onClick: handleEdit
						}
					]}
					fields={[
					]}
				/>
				{<UpdateDialog isOpen={isOpenUpdateDialog} handleClose={handleCloseUpdateDialog} params={datas} />}
			</Fragment>
		: <div className={classes.noPrivileges}>No tienes privilegios para consultar este módulo.</div>
	);
}

export default Component;