import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: { padding: theme.spacing(0.4) },
  danger: { backgroundColor: '#ffebeb' },
  warning: { backgroundColor: '#ffffd6' },
  info: { backgroundColor: '#b8dcff' },
  regular: { backgroundColor: '#fff' }
}));

export default useStyles;