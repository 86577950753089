import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import FormDialog from './../../presentationals/FormDialog';
import DateField from './../../presentationals/DateTimeField';

import { format } from 'date-fns';

import GQL from './_gql';

const defaultValues = {
	date: null
};

const CreateDialog = props => {
	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setErrors ] = useState({});

	const [ createPostSurgery, { loading } ] = useMutation(GQL.CREATE, {
		update(cache, { data: response }) {
			try {
				const dataResponse = response.createPostSurgery;
				const crmId = props.params.id;
	      const oldQuery = cache.readQuery({ query: GQL.GET, variables: { expired: props.expired } });
	      const newQuery = oldQuery.postSurgeries.map(el => {
	      	if(parseInt(el.id) === parseInt(crmId)){
	      		return {
	      			...el,
	      			PostSurgeryCount: parseInt(el.PostSurgeryCount) + 1,
	      			PostSurgery: {
	      				...el.PostSurgery,
	      				status: dataResponse.status,
	      				date: dataResponse.date
	      			}
	      		}
	      	}
	      	return el;
	      })
	      cache.writeQuery({
	        query: GQL.GET,
	        variables: { expired: props.expired },
	        data: { postSurgeries: newQuery }
	      });
			}catch(e){
				console.log(e.message)
			}
			
		}
	});

	const handleCloseForm = e => {
		props.handleClose();
	}

	const handleAction = async (e) => {
		try{
			const { id } = props.params;
			
			setErrors({});

			if(!values.date){
				const e = new Error("Selecciona una fecha para la cita.");
				e.name = 'date';
				throw e;
			}
			// console.log(props.params);
			await createPostSurgery({ variables: { crmId: id, date: values.date } });
			handleCloseForm();
		}catch(e){
			if(e.name === 'date'){
				setErrors({ [e.name]: e.message });
				return;
			}
			console.log(e.message)
			setErrors({ date: 'Este paciente ya cuenta con una cita programada.' });
		}
		
	}

	const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd HH:mm')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

	return (
		<FormDialog
			isOpen={props.isOpen}
			isLoading={loading}
			title='Crear cita'
      handleClose={handleCloseForm}
      handleAction={handleAction}
		>
			<DateField
				name='date'
				label='Fecha y Hora'
				onChange={handleDateChange}
				value={values.date}
				error={errors.date}
			/>
		</FormDialog>
	);
};

export default CreateDialog;