import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import FormDialog from './../../presentationals/FormDialog';
import TextField from './../../presentationals/TextField';

import GQL from './_gql';

const CreateDialog = props => {
	const [ values, setValues ] = useState('');

	const [ updatePostSurgery, { loading } ] = useMutation(GQL.UPDATE_COMMENTARY);

	const handleCloseForm = e => {
		props.handleClose();
	}
	const handleAction = async e => {
		try{
			const { id } = props;
			await updatePostSurgery({ variables: { id, commentary: values } });
			setValues('');
			handleCloseForm();
		}catch(e){
			console.log(e.message)
		}
	}

	const handleChange = e => {
    setValues(e.target.value);
  }

	return (
		<FormDialog
			isOpen={props.isOpen}
			isLoading={loading}
			title='Crear comentario'
      handleClose={handleCloseForm}
      handleAction={handleAction}
      actionLabel='Crear'
		>
			<TextField
				name='commentary'
				label='Comentario'
				onChange={handleChange}
				value={values}
			/>
		</FormDialog>
	);
};

export default CreateDialog;