import { gql } from '@apollo/client';

const GET = gql`
  query Indicators($offset: Int $limit: Int $start: Date $end: Date){
    indicatorsv2(offset: $offset limit: $limit start: $start end: $end) {
      count
      rows {
        id
        date
        saleEmployee1
        saleEmployee2
        saleEmployee3
        adminEmployee
        postEmployee
      }
    }
  }
`;

const object = { 
  GET
};
export default object;