import React from 'react';

import { useQuery } from '@apollo/client';

import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';

import Module from './../../presentationals/Module';

import GQL from './_gql';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text', filter: true },
	{ label: 'Usuario', key: 'username', sort: 'text', filter: true },
	{ label: 'Permisos', key: 'privileges', sort: 'text', format: 'privileges' }
];

const privilegesOptions = [
	{ value: 1, label: 'Administrador' },
	{ value: 2, label: 'Ventas' },
	{ value: 3, label: 'Masajes' },
	{ value: 4, label: 'Observador' },
	{ value: 5, label: 'Indicador' },
];

const Component = props => {

	const { data, loading } = useQuery(GQL.GET);
	
	return (
		<Module
			isLoading={loading}
			name='Empleados'
			singular='Empleado'
			operation={['user', 'users']}
			Rows={Rows}
			get={GQL.GET}
			getById={GQL.GET_BY_ID}
			create={GQL.CREATE}
			update={GQL.UPDATE}
			delete={GQL.DELETE}
			records={data && data.users}
			fields={[
				{ 
					icon: <AccountCircleIcon />,
					name: 'name', 
					label: 'Nombre',
					autoFocus: true
				},
				{ 
					icon: <SecurityIcon />,
					name: 'username', 
					label: 'Usuario'
				},
				{ 
					noShowOnUpdate: true,
					name: 'password', 
					label: 'Contraseña',
					type: 'password'
				},
				{ 
					field: 'select',
					name: 'privileges', 
					label: 'Permisos',
					options: privilegesOptions
				},
			]}
		/>
	);
}

export default Component;