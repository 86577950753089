import { gql } from '@apollo/client';

const GET = gql`
  query Procedures{
    procedures {
      count
      rows{
        id
        name
        prefix
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Procedure($id: ID!){
    procedure(id: $id) {
      id
      name
      prefix
    }
  }
`;

const GET_COST_BY_ID = gql`
  query ProcedureCost($id: ID!){
    procedure(id: $id) {
      id
      name
      ProcedureCost {
        c1 c2 c3 c4 c5 c6 c7 c8 c9 c10
      }
    }
  }
`;

const CREATE = gql`
  mutation CreateProcedure(
    $name: String!
    $prefix: String!
  ) {
    createProcedure(input: {
      name: $name
      prefix: $prefix
    }) {
      id
      name
      prefix
    }
  }
`

const DELETE = gql`
  mutation DeleteProcedure($id: ID!) {
    deleteProcedure(id: $id) {
      id
    }
  }
`;

const UPDATE = gql`
  mutation UpdateProcedure(
    $id: ID!, 
    $name: String!
    $prefix: String!
  ) {
    updateProcedure(
      id: $id, 
      input: {
        name: $name
        prefix: $prefix
      }
    ) {
      id
      name
      prefix
    }
  }
`

const UPDATE_COST = gql`
  mutation UpdateProcedureCost(
    $procedureId: ID!, $c1: Float! $c2: Float! $c3: Float! $c4: Float! $c5: Float! $c6: Float! $c7: Float! $c8: Float! $c9: Float! $c10: Float!
  ) {
    updateCost(
      procedureId: $procedureId, 
      input: { c1: $c1 c2: $c2 c3: $c3 c4: $c4 c5: $c5 c6: $c6 c7: $c7 c8: $c8 c9: $c9 c10: $c10 }
    ) {
      procedureId c1 c2 c3 c4 c5 c6 c7 c8 c9 c10
    }
  }
`
const object = { 
  GET, 
  GET_BY_ID, 
  DELETE, 
  CREATE, 
  UPDATE,
  GET_COST_BY_ID,
  UPDATE_COST
};
export default object