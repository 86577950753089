import React from 'react';

import Header from './../Table/header';
import Table from './../Table';
import ActionForm from './../ActionForm';
import DeleteDialog from './../DeleteDialog';
import Snackbar from './../Snackbar';

import useStyles from './style';

const Component = props => {
	
	const classes = useStyles();

	if(props.isLoading) { return (<div>Espere un momento...</div>)}
		
	return (
		<div className={classes.root}>
			
			<Header 
				title={props.name} 
				subTitle={props.subTitle}
				create={props.create}
				qty={props.records ? props.records.count : 0}
				Rows={props.Rows} 
				records={props.records ? props.records.rows : []}
				handleCustomCreate={props.handleCustomCreate || null}
				filter={props.filter}
				return={props.return}
				noCreate={props.noCreate}
			/>
			
			<Table 
				qty={props.records ? props.records.count : 0}
				operation={props.operation[0]}
				Rows={props.Rows} 
				records={props.records ? props.records.rows : []}
				delete={props.delete}
				getById={props.getById}
				actions={props.actions || []}
				noDelete={props.noDelete}
				noEdit={props.noEdit}
				handleRows={props.handleRows}
			/>
			{!props.noDelete &&
				<DeleteDialog 
		    	params={props.extraParams} 
		    	operation={props.operation[0]} 
		    	delete={props.delete}
		    	get={props.get}
		    />
			}
	   	{ props.fields && 
	      <ActionForm 
	      	operation={props.operation[1]}
	      	singular={props.singular} 
	      	fields={props.fields}
	      	create={props.create}
	      	update={props.update}
	      	get={props.get}
	      	cacheVariables={props.cacheVariables}
	      	params={props.extraParams}
	      /> 
	    }

	    <Snackbar horizontal='center' />

		</div>
	);
}

export default Component;