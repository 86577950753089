import React, { useState, useEffect } from 'react';

import { startOfDay, endOfDay, subHours } from 'date-fns';

import CloseIcon from '@material-ui/icons/Close';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import Button from './../Button';
import DateField from './../DateField';
import Checkbox from './../Checkbox';

import useStyles from './style';

const defaultValues = {
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
  branches: []
}

const branches = localStorage.getItem('companies') ? JSON.parse(localStorage.getItem('companies')) : [];

const FilterDrawer = props => {
  
  const classes = useStyles();

  const [ values, setValues ] = useState(defaultValues);
  
  useEffect(() => {
    if(!!branches) {
      const tmp  = branches.map(el => ({
        label: el.name,
        name: 'branches',
        params: el.branchId,
        checked: true
      }));
      setValues(values => ({ ...values, branches: tmp }));
    }
  }, []);

  const handleClick = e => {
    props.handleAction && props.handleAction(e, {...values, startDate: subHours(values.startDate, 0), endDate: subHours(values.endDate, 7) }, props.params);
    props.handleClose();
  }

  const handleDateChange = (name, value) => {
    console.log(name, value);
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleCheckBoxChange = (name, checked, id) => {
    const tmp = values[name].map(el => el.params === id ? ({ ...el, checked }) : el);
    setValues(values => ({ ...values, [name]: tmp }));
  }

  return (
    <Drawer anchor='right' open={props.isOpen} onClose={props.handleClose} classes={{ paper: classes.container }}>
      <div className={classes.title}>
        <Typography variant="h6">
          Filtrar Información
        </Typography>
        <IconButton 
          aria-label="close" 
          onClick={props.handleClose} 
          className={classes.closeButton} 
          size="medium"
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      <Divider/>
      {
        props.showDates &&
          <div>
            <div className={classes.dateContent}>
              <Typography variant="subtitle1">
                Rango de fechas:
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <DateField 
                    variant='outlined' 
                    name="startDate" 
                    value={values.startDate} 
                    onChange={handleDateChange} 
                    noFormat 
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateField 
                    variant='outlined'
                    name='endDate'
                    value={values.endDate} 
                    onChange={handleDateChange} 
                    noFormat 
                  />
                </Grid>
              </Grid>
            </div>
            <Divider/>
          </div>
      }
      {
        props.showBranches && values.branches.length > 1 &&
          <div>
            <div className={classes.dateContent}>
              <Typography variant="subtitle1">
                Sucursales:
              </Typography>
              <Checkbox 
                noFormat
                handleChange={handleCheckBoxChange}
                values={values.branches}
              />
            </div>
            <Divider/>
          </div>
      }
      <div className={classes.content}>
        {props.children}
      </div>
      <Divider/>
      <div className={classes.footer}>
        <Button 
          label='Aplicar Filtro' 
          size='large' 
          color='secondary'
          variant='outlined' 
          onClick={handleClick} 
          className={classes.actionButton} 
        />
      </div>
    </Drawer>
  )
}

export default FilterDrawer;