import React, { useState, useEffect } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TextField from './../../../presentationals/TextField';
import FormDialog from './../../../presentationals/FormDialog';
import LinearForm from './../../../presentationals/LinearForm';
import IconButton from './../../../presentationals/IconButton';
import format from './../../../presentationals/Table/helpers/Format';

import GQL from './../_gql';

//CREATE ($crmId: ID! $description: String! $cost: Float!)
//DELETE (id)

const defaultValues = {
	description: '',
	cost: ''
}

const CostDialog = props => {

	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setError ] = useState({});
	const [ total, setTotal ] = useState(0);

	const [ getCosts, { data, loading } ] = useLazyQuery(GQL.GET_BY_ID);
	const [ deleteCost ] = useMutation(GQL.DELETE, {
		update(cache, { data: response }) {
			cache.evict({ id: `Cost:${response.deleteCost.id}` });
			cache.gc();
		}
	});
	const [ createCost ] = useMutation(GQL.CREATE, {
		update(cache, { data: response }) {
			try {
				const dataResponse = response.createCost;
	      const oldQuery = cache.readQuery({ query: GQL.GET_BY_ID, variables: { crmId: props.crmId } });
	      cache.writeQuery({
	        query: GQL.GET_BY_ID,
	        variables: { crmId: props.crmId },
	        data: { cost: [
	        	...oldQuery.cost,
	        	{ ...dataResponse }
	        ] }
	      });
	      const oldQuery1 = cache.readQuery({ 
	      	query: GQL.GET_CRM, 
	      	variables: { 
	      		customer: props.search, status: 5, limit: 25, offset: 0, userId: null, subStatus: null, orderBy: 1, showByStatusDate: true, statusDate: true 
	      	} 
	      });
	      const newQuery = oldQuery1.crms.rows.map(el => {
	      	if(parseInt(el.id) === parseInt(props.crmId)){
	      		return { ...el, Costs: [ ...el.Costs, { cost: dataResponse.cost } ] }
	      	}
	      	return el;
	      });
	      cache.writeQuery({
	        query: GQL.GET_CRM,
	        variables: { status: 5, limit: 25, offset: 0, customer: '', userId: null, subStatus: null, orderBy: 1, showByStatusDate: true, statusDate: true },
	        data: { crms: {
	        	...oldQuery1.crms,
	        	rows: newQuery
	        } }
	      });
			}catch(e){
				console.log(e.message)
			}
			
		}
	});

	useEffect(() => {
		if(data && data.cost){
			const tmpCost = data.cost.reduce((total, el) => {
				return total + parseFloat(el.cost)
			}, 0);
			setTotal(tmpCost);
		}
	}, [data]);

	useEffect(() => {
		if(props.crmId){
			getCosts({ variables: { crmId: props.crmId } })
		}
	}, [props.crmId, getCosts]);

	const handleAction = async () => {
		try{
			if(values.description === ''){
				const descriptionError = new Error('Proporciona una descripción.');
				descriptionError.name = 'description';
				throw descriptionError;
			}
			if(values.cost === ''){
				const costError = new Error('Proporciona un costo.');
				costError.name = 'cost';
				throw costError;
			}

			await createCost({ variables: { crmId: props.crmId, description: values.description, cost: parseFloat(values.cost) } });

			setError({});
		}catch(e){
			if(['description','cost'].includes(e.name)){
				setError({ [e.name]: e.message });
				return;
			}
			console.log(e.message)
		}
	}

	const handleClose = () => {
		props.handleClose(null,null,'close');
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleDelete = (e, id) => {
		deleteCost({ variables: { id } });
	}

	return (
		<FormDialog
			isOpen={props.isOpen}
			isLoading={loading}
			title='Costo de Venta'
      handleClose={handleClose}
      hideAction
		>
			<LinearForm handleAdd={handleAction}>
				<Grid container spacing={1}>
					<Grid item xs={8}>
						<TextField 
							label='Descripción'
							name='description'
							value={values.description}
							error={errors.description}
							noFormat
							variant='outlined'
							onChange={handleChange}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField 
							label='Costo'
							name='cost'
							value={values.cost}
							error={errors.cost}
							noFormat
							type='number'
							variant='outlined'
							onChange={handleChange}
						/>
					</Grid>
				</Grid>
			</LinearForm>

			<Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Descripción</TableCell>
            <TableCell align='right'>CostoVenta</TableCell>
            <TableCell align='right'>%CV</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        	{
        		data && data.cost.map(el => {
        			return (
        				<TableRow key={el.id}>
        					<TableCell>
        						<IconButton size='small' label='Eliminar' icon={<DeleteOutlineIcon />} params={el.id} action={handleDelete}/>
        					</TableCell>
        					<TableCell>{el.description}</TableCell>
        					<TableCell align='right'>{format(el.cost, 'currency')}</TableCell>
        					<TableCell align='right'>{format((el.cost / total) * 100, 'percent')}</TableCell>
        				</TableRow>
        			)
        		})
        	}
        </TableBody>
	    </Table>

		</FormDialog>
	)
}

export default CostDialog;