import React, { useState, useEffect, Fragment } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';

import Module from './../../presentationals/Module';
import { getModes } from './../../helpers';

import { cacheQty, cacheRecords } from './../../apollo/cache';

import GQL from './_gql';
import FilterDialog from './filter.dialog.js';

const Rows = [
	{ key: 'date', label: 'Registro', format: 'date' },
	{ key: 'surgeryDoctor', label: 'Doctor', format: 'surgeryDoctor' },
	{ key: 'status', label: 'Estatus', align: 'center', format: 'status' },
	{ key: 'subStatus', label: 'SubEstatus', align: 'center', format: 'subStatus' },
	{ key: 'Customer name', label: 'Paciente' },
	{ key: 'Customer phone', label: 'Teléfono' },
	{ key: 'Customer age', label: 'Edad' },
	{ key: 'Customer place', label: 'Ubicación' },
	{ key: 'catchment', label: 'Captación', format: 'catchment' },
	{ key: 'createdAt', label: 'FechaPros', format: 'date' },
	{ key: 'xopDate', label: 'FechaXop', format: 'date' },
	{ key: 'diffDays', label: 'Diff', align: 'center', format: 'int' },
	{ key: 'surgeryDate', label: 'FechaCirugía', format: 'date' },
	{ key: 'OneProcedure prefix', label: 'Proc1', search: true },
];

const Component = props => {

	const cacheQtyVar = useReactiveVar(cacheQty);
	const cacheRecordsVar = useReactiveVar(cacheRecords);

	const [ status, setStatus ] = useState([0,0,0,0,0,0,0,0,0]);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});
	const { data, loading, refetch } = useQuery(GQL.GET);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	useEffect(() => {
		const totals = [0,0,0,0,0,0,0,0,0];
		let ages = [];
		let tmpAge = 0;
		let tmpDay = 0;
		cacheRecordsVar.forEach(el => {
			ages = [...ages, parseInt(el.Customer.age)];
			tmpAge = el.Customer.age ? (tmpAge + parseInt(el.Customer.age)) : tmpAge;
			tmpDay = el.diffDays ? (parseInt(el.diffDays) + tmpDay) : tmpDay;
			switch(el.status){
				case 1:
					totals[0]++;
					break;
				case 2:
					totals[1]++;
					break;
				case 3: 
					totals[2]++;
					break;
				case 4:
					totals[3]++;
					break;
				case 5:
					totals[4]++;
					break;
				case 7:
					totals[5]++;
					break;
				case 15:
					totals[6]++;
					break;
				default:
					totals[7]++;
					break;
			}
		});
		totals[8] = (cacheQtyVar > 0) ? parseInt(tmpAge / parseInt(cacheQtyVar)) : 0;
		totals[9] = getModes(ages);
		totals[10] = (cacheQtyVar > 0) ? parseInt(tmpDay / parseInt(cacheQtyVar)) : 0;
		setStatus(totals);
	},[cacheRecordsVar, cacheQtyVar])

	const handleFilter = () => setIsOpen(true);

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}
	
	return (
		<Fragment>
			<Module
				isLoading={loading}
				subTitle={`PROS: ${status[0]} | 1RA: ${status[1]} | COT: ${status[2]} | XOP: ${status[3]} | OP: ${status[4]} | OPSF: ${status[5]} | CAN: ${status[6]} | OTR: ${status[7]} | %COT: ${status[0] > 0 ? (status[2] / status[0] * 100).toFixed(2) : 0} | %XOP: ${status[2] > 0 ? (status[3] / status[2] * 100).toFixed(2) : 0} | %OP: ${status[3] > 0 ? (status[4] / status[3] * 100).toFixed(2) : 0} | PromEdad: ${status[8]} | ModEdad: ${status[9]} | PromDia: ${status[10]}`}
				name='Movimientos por estatus'
				singular='Movimientos por estatus'
				operation={['crmLog', 'crmLogs']}
				Rows={Rows}
				get={GQL.GET}
				filter={handleFilter}
				noCreate
				noEdit
				noDelete
				records={data && data.crmLogs}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
		</Fragment>
	);
}

export default Component;