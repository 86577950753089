import { gql } from '@apollo/client';


const GET = gql`
	query RecoveryHouses{
		recoveryHouses{
			count
			rows{
				id
				status
				subStatus
				statusCommentary
				statusDate
				firstDate
				preSurgeryDate
				surgeryDate
				postSurgeryDate
				isFirstDateOnline
				OneProcedure {
					id
					prefix
				}
				TwoProcedure {
					id
					prefix
				}
				ThreeProcedure {
					id
					prefix
				}
				Customer {
					id
					name
					age
					phone
					place
				}
				PostSurgery {
					id
					date
					status
					commentary
				}
				createdAt
				chemicals
				anesthesia
				intensive
				doctor
				feedback
				feedbackCommentary
				total
				payment
				balance
				paid
				commision
				PostSurgeryCount
				RecoveryHouse {
					id
					arriveDate
					departureDate
					commentary
					entryDate
					outputDate
				}
			}
			
    }
  }
`;

const UPDATE = gql`
	mutation UpdateRecoveryHouse(
		$id: ID!
		$arriveDate: Date
		$departureDate: Date
		$commentary: String
		$entryDate: Date
		$outputDate: Date
	) {
		updateRecoveryHouse(
			id: $id
			input: {
			arriveDate: $arriveDate
			departureDate: $departureDate
			commentary: $commentary
			entryDate: $entryDate
			outputDate: $outputDate
			}
		) {
			arriveDate
			departureDate
			commentary
			entryDate
			outputDate
		}
	}
`;
const object = { GET, UPDATE };
export default object;