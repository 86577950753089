import React, { useState, useEffect } from 'react';

import TablePaginationMui from '@material-ui/core/TablePagination';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		background: '#fff',
		borderTop: '1px solid #e0e0e0', 
	}
}));

const TablePagination = props => {
	
	const classes = useStyles();

	const [ page, setPage ] = useState(0);
	const [ rowsPerPage, setRowsPerPage ] = useState(25);

	useEffect(() => {
		if(props.rowsPerPage){
			setRowsPerPage(props.rowsPerPage);
		}
	}, [props.rowsPerPage]);

	const labelDisplayedRows = ({ from, to, count }) => `${from}-${to} de ${count}`;
	const handleChangePage = (e, newPage) => {
    setPage(newPage);
    props.handleRows(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = e => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
    props.handleRows(0, parseInt(e.target.value, 10));
  };

	return (
		<div className={classes.container}>
			<TablePaginationMui
	      rowsPerPageOptions={[25, 50, 100]}
	      component="div"
	      labelRowsPerPage='Filas por página:'
	      backIconButtonText='Página anterior'
	      nextIconButtonText='Página siguiente'
	      labelDisplayedRows={labelDisplayedRows}
	      count={props.count || 0}
	      rowsPerPage={rowsPerPage}
	      page={page}
	      onChangePage={handleChangePage}
	      onChangeRowsPerPage={handleChangeRowsPerPage}
	    />
    </div>
	)
}

export default TablePagination;