import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import FormDialog from './../../presentationals/FormDialog';
import SelectField from './../../presentationals/SelectField';

import GQL from './_gql';

const statusOptions = [
	{ value: 1, label: 'Pendiente' },
	{ value: 2, label: 'Asisitió' },
	{ value: 3, label: 'No asistió' },
];

const defaultValues = {
	status: 1
}

const CreateDialog = props => {
	const [ values, setValues ] = useState(defaultValues);

	const [ updatePostSurgery, { loading } ] = useMutation(GQL.UPDATE, {
		update(cache, { data: response }) {
			
			const dataResponse = response.updatePostSurgery;
			// console.log(dataResponse.status);

			const crmId = props.params.id;
      const oldQuery = cache.readQuery({ query: GQL.GET, variables: { expired: props.expired } });

      const newQuery = oldQuery.postSurgeries.map(el => {
      	if(parseInt(el.id) === parseInt(crmId)){
      		let PostSurgeryCount;
      		let PostSurgery = null;

      		if(parseInt(dataResponse.status) === 2 || parseInt(dataResponse.status) === 1){
      			PostSurgeryCount = parseInt(el.PostSurgeryCount);
      		} else {
      			PostSurgeryCount = parseInt(el.PostSurgeryCount) - 1;
      		};

      		if(parseInt(dataResponse.status) === 1){
      			PostSurgery = el.PostSurgery;
      		}
      		return {
      			...el,
      			PostSurgeryCount,
      			PostSurgery
      		}
      	}
      	return el;
      })
      cache.writeQuery({
        query: GQL.GET,
        variables: { expired: props.expired },
        data: { postSurgeries: newQuery }
      });
		}
	});

	const handleCloseForm = e => {
		props.handleClose();
	}

	const handleAction = async (e) => {
		try{
			const { id } = props.params.PostSurgery;
			
			await updatePostSurgery({ variables: { id, status: values.status } });
			handleCloseForm();
		}catch(e){
			console.log(e.message)
		}
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FormDialog
			isOpen={props.isOpen}
			isLoading={loading}
			title='Actualizar Estatus'
      handleClose={handleCloseForm}
      handleAction={handleAction}
      actionLabel='Actualizar'
		>
			<SelectField
				name='status'
				label='Estatus'
				value={values.status}
				onChange={handleChange}
				options={statusOptions}
			/>
		</FormDialog>
	);
};

export default CreateDialog;