import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  noPrivileges: {
    paddingTop: theme.spacing(20),
    textAlign: 'center',
    fontSize: 30
  }
}));

export default useStyles;