import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
  	flex: 1,
  	display: 'flex',
    flexDirection: 'column',
  },
  regular: {
  	background: '#fff'
  },
  danger: {
    background: '#ffd8d8',
    '&:hover': {
      background: '#ffecec !important'
    }
  },
  filterContent: {
		padding: theme.spacing(2, 2, 3, 2),
	},
  noPrivileges: {
    paddingTop: theme.spacing(20),
    textAlign: 'center',
    fontSize: 30
  }
}));

export default useStyles;