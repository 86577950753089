import { gql } from '@apollo/client';

const GET = gql`
  query Products{
    products {
      count
      rows{
        id
        name
        sku
        cost
        price
        stock
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Product($id: ID!){
    product(id: $id) {
      id
      name
      sku
      cost
      price
      stock
      description
    }
  }
`;

const CREATE = gql`
  mutation CreateProduct(
    $name: String!
    $sku: String
    $description: String
    $cost: Float
    $price: Float!
    $stock: Float
  ) {
    createProduct(input: {
      name: $name
      sku: $sku
      description: $description
      cost: $cost
      price: $price
      stock: $stock
    }) {
      id
      name
      sku
      cost
      price
      stock
    }
  }
`

const DELETE = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      id
    }
  }
`;

const UPDATE = gql`
  mutation UpdateProduct(
    $id: ID!, 
    $name: String!
    $sku: String
    $description: String
    $cost: Float
    $price: Float!
    $stock: Float
  ) {
    updateProduct(id: $id, input: {
      name: $name
      sku: $sku
      description: $description
      cost: $cost
      price: $price
      stock: $stock
    }) {
      id
      name
      sku
      cost
      price
      stock
    }
  }
`
const object = { 
  GET, 
  GET_BY_ID, 
  DELETE, 
  CREATE, 
  UPDATE,
};
export default object