import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from './../../presentationals/FormDialog';
import { currencyFormat } from './../../helpers/general';

import GQL from './_gql';

const Component = props => {
	const [ productSales, setProductSales ] = useState([]);

	const [ getSale, { data } ] = useLazyQuery(GQL.GET_BY_ID);

	useEffect(() => {
		props.id && getSale({ variables: { id: props.id } });
		// eslint-disable-next-line
	},[props.id]); 

	useEffect(() => {
		if(data) {
			setProductSales(data.sale.ProductSales);
		}
	},[data]);

	const handleClose = () => props.handleClose();

	return (
		<FormDialog 
			isOpen={props.isOpen}
      title="Conceptos"
      name="concepts"
      singular="Concept"
      disableEscape
      handleClose={handleClose}
      hideAction
      width="sm"
		>	
			<TableContainer>
				<Table size="small">
					<TableHead>
	          <TableRow>
	            <TableCell>Producto</TableCell>
	            <TableCell align="center">Entrada</TableCell>
	            <TableCell align="right">Importe</TableCell>
	          </TableRow>
	        </TableHead>
	        <TableBody>
	        	{
	        		productSales.map((el, i) => 
	      				<TableRow key={i}>
			            <TableCell>{el.Product.name}</TableCell>
			            <TableCell align="center">{el.quantity}</TableCell>
			            <TableCell align="right">{currencyFormat(el.total)}</TableCell>
	      				</TableRow>
	      			)
	        	}
	        </TableBody>
				</Table>
			</TableContainer>
		</FormDialog>
	);
}

export default Component;