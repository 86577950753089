import React, { useState, Fragment, useEffect } from 'react';

import { useQuery } from '@apollo/client';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import FilterDrawer from './../../presentationals/FilterDrawer';
import SelectField from './../../presentationals/SelectField';
import Checkbox from './../../presentationals/Checkbox';

import GQL_EMPLOYEE from './../employee/_gql';
import { catchmentOptions, surgeryDoctorOptions } from '../crm';

const statusOptions = [
	{ value: -1, label: 'TODOS' },
	{ value: 1, label: 'PROS' },
	{ value: 2, label: '1RA' },
	{ value: 3, label: 'COT' },
	{ value: 4, label: 'XOP' },
	{ value: 5, label: 'OP' },
	{ value: 7, label: 'OPSF' },
	{ value: 15, label: 'CANCEL' },
];

const subStatusOptions = [
	{ value: -1, label: 'TODOS' },
	{ value: 1, label: 'EN PROC' },
	{ value: 2, label: 'NO CON' },
	{ value: 3, label: 'PRECIO' },
	{ value: 4, label: 'UBICAC' },
	{ value: 5, label: 'SIN FE' },
	{ value: 6, label: 'NO APL' },
	{ value: 7, label: 'DESPUES' },
	{ value: 8, label: 'NO INTE' },
	{ value: 9, label: 'OTRA FE' },
	{ value: 10, label: 'NO SATI' },
	{ value: 11, label: 'NO QUIE' },
	{ value: 12, label: 'CALCULAN' },
	{ value: 13, label: 'FOTOS' },
];

const defaultValues = {
	status: -1,
	subStatus: -1,
	userId: -1,
	surgeryDoctor: -1,
	catchment: catchmentOptions.map(el => ({ 
		name: 'catchment',
		params: el.value, 
		label: el.label,
		checked: true}))
}

const FilterDialog = props => {
	
	const [ values, setValues ] = useState(defaultValues);
	const [ userOptions, setUserOptions ] = useState([]);

	const { data: dataEmployees } = useQuery(GQL_EMPLOYEE.GET);

	useEffect(() => {
		if(dataEmployees){
			const tmp = dataEmployees && dataEmployees.users.rows.map(el => ({
				value: el.id, label: el.name
			}));
			setUserOptions([ { value: -1, label: 'TODOS' }, ...tmp ]);
		}
	}, [dataEmployees]);
	
	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		const { startDate: start, endDate: end } = data;
		const status = values.status === -1 ? null : values.status;
		const subStatus = values.subStatus === -1 ? null : values.subStatus;
		const userId = values.userId === -1 ? null : values.userId;
		const surgeryDoctor = values.surgeryDoctor === -1 ? null : values.surgeryDoctor;
		const catchment = values.catchment.map(el => el.checked && el.params).filter(el => el);
		props.handleAction(null, { start, end, status, subStatus, userId, catchment, surgeryDoctor });
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleCheckboxChange = (name, checked, id) => {
  	const tmp = values[name].map(el => parseInt(el.params) === parseInt(id) ? ({ ...el, checked }) : el);
    setValues(values => ({ ...values, [name]: tmp }));
  }

	return (
		<FilterDrawer 
			isOpen={props.isOpen} 
			handleClose={handleClose} 
			handleAction={handleAction}
			showDates
		>	
			{
				!props.noStatus &&
					<Fragment>
						<Typography variant="subtitle1">
			        Estatus:
			      </Typography>
			      <SelectField
			        name='status'
			        variant='outlined'
			        value={values.status}
			        onChange={handleChange}
			        options={statusOptions}
			        noFormat
			      />
			      <br/>
			      
		      </Fragment>
			}

			{
				!props.noStatus &&
					<Fragment>
						<Typography variant="subtitle1">
			        SubEstatus:
			      </Typography>
			      <SelectField
			        name='subStatus'
			        variant='outlined'
			        value={values.subStatus}
			        onChange={handleChange}
			        options={subStatusOptions}
			        noFormat
			      />
			      <br/>
			      
		      </Fragment>
			}


			<Typography variant="subtitle1">
        Empleados:
      </Typography>
      <SelectField
      	name='userId'
      	value={values.userId || -1}
      	onChange={handleChange}
      	options={userOptions}
      	variant='outlined'
      	noFormat
      />

			<br/>

			<Typography variant="subtitle1">
				Doctor Asignado:
			</Typography>
			<SelectField
				name='surgeryDoctor'
				value={values.surgeryDoctor}
				onChange={handleChange}
				options={surgeryDoctorOptions}
				variant='outlined'
				noFormat
			/>

    	<br/>

    	{ !props.noCatchment && 
    		<Fragment>
		    	<Typography variant="subtitle1">
		        Método de Captación:
		      </Typography>
		      <Checkbox 
		      	noFormat
		      	handleChange={handleCheckboxChange}
		      	values={values.catchment}
		      />
		      {/*<SelectField
		      	name='catchment'
		      	value={values.catchment || -1}
		      	onChange={handleChange}
		      	options={catchmentOptions}
		      	variant='outlined'
		      	noFormat
		      /> */}
		     </Fragment>
	    }
    	<Divider/>

      
		</FilterDrawer>
	);
}

export default FilterDialog;