import { InMemoryCache, makeVar } from '@apollo/client';

export const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				snackbar: {
					read() {
						return snackbar();
					}
				},
				actionForm: {
					read() {
						return actionForm();
					}
				},
				deleteForm: {
					read() {
						return deleteForm();
					}
				},
				form: {
					read() {
						return form();
					}
				},
				cost: {
					merge: false
				}
			},
		}
	}
});

export const snackbar = makeVar({
  isOpen: false,
  time: null,
  label: '',
  severity: 'success'
});

export const actionForm = makeVar({
  isOpen: false,
  action: '',
  defaultData: {}
});

export const deleteForm = makeVar({
	isOpen: false,
  params: {}
});

export const form = makeVar({});
export const searchField = makeVar('');
export const cacheQty = makeVar(null);
export const cacheRecords = makeVar([]);