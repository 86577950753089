import React, { useState, useEffect, Fragment } from 'react';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { currencyFormat } from './../../../../helpers/general';

import IconButton from './helpers/iconButton';

const defaultValues = {
	discount: 0,
	subtotal: 0,
	total: 0
}

const TableComponent = props => {

	const [ values, setValues ] = useState(defaultValues);

	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

	useEffect(() => {
		const tmp = props.cartItems.reduce((object, el) => {
			return {
				discount: parseFloat(object.discount) + parseFloat(el.discount),
				subtotal: parseFloat(object.subtotal) + parseFloat(el.subtotal),
				total: parseFloat(object.total) + parseFloat(el.total),
			};
		}, defaultValues);
		setValues(tmp);
	}, [props.cartItems, props.iva]);

	const handleDeleteItem = (e, position) => {
		props.removeItem(position);
	}
	
	return (
		<Fragment>
			<TableContainer>
				<Table size="small">
					<TableHead>
	          <TableRow>
	            <TableCell>Acciones</TableCell>
	            <TableCell>Producto</TableCell>
	            <TableCell align="center">Cant.</TableCell>
	            <TableCell align="right">{'P. Unitario'}</TableCell>
	            <TableCell align="right">{'Desc.'}</TableCell>
	            <TableCell align="right">{'Importe'}</TableCell>
	          </TableRow>
	        </TableHead>
	        <TableBody>
	        	{
	        		props.cartItems.map((el, i) => 
	      				<TableRow key={i}>
	      					<TableCell>
	      						<IconButton 
	      							label='Eliminar'
	      							icon={<DeleteOutlineIcon />}
	      							params={i}
	      							action={handleDeleteItem}
	      							size='small'
	      						/>
	      					</TableCell>
			            <TableCell>{el.product.label}</TableCell>
			            <TableCell align="center">{el.quantity}</TableCell>
			            <TableCell align="right">
			            {
			            	parseInt(props.type) === 1
			            		? currencyFormat(parseFloat(el.product.price))
			            		: currencyFormat(parseFloat(el.cost))
			            }
			            </TableCell>
			            <TableCell align="right">{currencyFormat(el.discount)}</TableCell>
			            <TableCell align="right">{currencyFormat(el.total)}</TableCell>
	      				</TableRow>
	      			)
	        	}
	        	<TableRow>
	        		<TableCell colSpan={5} align="right"><strong>Subtotal:</strong></TableCell>
	            <TableCell align="right"><strong>{props.currency} {currencyFormat(values.subtotal)}</strong></TableCell>
	        	</TableRow>
	        	<TableRow>
	        		<TableCell colSpan={5} align="right"><strong>Desc.:</strong></TableCell>
	            <TableCell align="right"><strong>{props.currency} {currencyFormat(values.discount)}</strong></TableCell>
	        	</TableRow>
	        	<TableRow>
	        		<TableCell colSpan={5} align="right"><strong>Total:</strong></TableCell>
	            <TableCell align="right"><strong>{props.currency} {currencyFormat(values.total)}</strong></TableCell>
	        	</TableRow>
	        </TableBody>
				</Table>
			</TableContainer>
		</Fragment>
	)
}

export default TableComponent;