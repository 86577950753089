import React, { useEffect, useState, Fragment } from 'react';

import { useLazyQuery } from '@apollo/client';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/EditOutlined';

import FormDialog from './../../presentationals/FormDialog';
import IconButton from './../../presentationals/IconButton';

import { format } from 'date-fns';

import GQL from './_gql';
import UpdateCommentaryDialog from './updateCommentary.dialog';

const HistoryDialog = props => {

	const [ updateCommentaryId, setUpdateCommentaryId ] = useState(null);
	const [ isOpenUpdateCommentaryDialog, setIsOpenUpdateCommentaryDialog ] = useState(false);

	const [ getHistory, { data, loading } ] = useLazyQuery(GQL.GET_HISTORY);

	useEffect(() => {
		if(props.id){ 
			getHistory({ variables: { crmId: props.id } });
		}
	}, [props.id, getHistory]);

	const handleAction = async e => {
		try{
			await getHistory({ variables: { crmId: props.id } });
		}catch(e){
			console.log(e.message)
		}
		
	}

	const handleCloseForm = e => {
		props.handleClose();
	}

	const handleClick = (e, id) => {
		setUpdateCommentaryId(id);
		setIsOpenUpdateCommentaryDialog(true);
	}

	const handleClose = () => {
		setIsOpenUpdateCommentaryDialog(false);
	}

	return (
		<Fragment>
			<FormDialog
				isOpen={props.isOpen}
				isLoading={loading}
				title='Historial de citas'
	      handleClose={handleCloseForm}
	      hideAction
	      noPadding
			>
				<Table size="small">
	        <TableHead>
	          <TableRow>
	            <TableCell>#</TableCell>
	            <TableCell>Fecha de Cita</TableCell>
	            <TableCell>Estatus</TableCell>
	            <TableCell>Comentario</TableCell>
	            <TableCell></TableCell>
	          </TableRow>
	        </TableHead>
	        <TableBody>
	        	{
							data && data.postSurgeriesHistory.map((el, i) =>
								<TableRow key={el.id}>
									<TableCell>{i + 1}</TableCell>
			            <TableCell>{format(new Date(el.date), 'dd/MM/yyyy HH:mm')}</TableCell>
			            <TableCell>{statusToLabel(el.status)}</TableCell>
			            <TableCell>{el.commentary}</TableCell>
			            <TableCell><IconButton label={'Editar'} icon={<EditIcon/>} params={el.id} action={handleClick}/></TableCell>
								</TableRow>
							)
						}
	        </TableBody>
	      </Table>
			</FormDialog>
			<UpdateCommentaryDialog crmId={props.id} handleAction={handleAction} id={updateCommentaryId} isOpen={isOpenUpdateCommentaryDialog} handleClose={handleClose}/>
		</Fragment>
	);
};

function statusToLabel(value){
	switch(parseInt(value)){
		case 1: return 'Pendiente';
		case 2: return 'Asisitió';
		case 3: return 'No asistió';
		default: return '-';
	}
};

export default HistoryDialog;