import { gql } from '@apollo/client';

const GET = gql`
  query Sales($start: Date $end: Date $type: [Int]){
    sales(start: $start end: $end type: $type) {
      count
      rows{
        id
        type
        folio
        serial
        total
        createdAt
        Customer {
          name
        }
        User {
          name
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Sale($id: ID!){
    sale(id: $id) {
      id
      status
      type
      folio
      serial
      description
      total
      createdAt
      customerId
      userId
      Customer {
        id
        name
        age
        phone
        place
        nationality
        spanish
      }
      User {
        id
        name
        username
        privileges
      }
      ProductSales {
        id
        quantity
        concept
        unitPrice
        total
        createdAt
        saleId
        productId
        Product {
          id
          name
          sku
          description
          cost
          price
          stock
        }
      }
    }
  }
`;

const GET_BY_ID_PDF = gql`
  query salePdf($id: ID!){
    sale(id: $id) {
      id
      status
      type
      folio
      serial
      description
      total
      createdAt
      customerId
      userId
      Customer {
        id
        name
        age
        phone
        place
        nationality
        spanish
      }
      User {
        id
        name
        username
        privileges
      }
      ProductSales {
        id
        quantity
        concept
        unitPrice
        total
        createdAt
        saleId
        productId
        Product {
          id
          name
          sku
          description
          cost
          price
          stock
        }
      }
    }
  }
`;


const CREATE = gql`
  mutation CreateSale(
    $type: Int!
    $description: String 
    $customerId: ID
    $Products: [ProductSaleData]
  ) {
    createSale(input: {
      type: $type
      description: $description
      customerId: $customerId
      Products: $Products
    }) {
      id
      type
      folio
      serial
      total
      createdAt
      Customer {
        name
      }
      User {
        name
      }
    }
  }
`;

const object = { GET, GET_BY_ID, CREATE, GET_BY_ID_PDF }

export default object;
