import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme: Theme) => ({
	danger: { 
		backgroundColor: '#ffebeb',
		'&:hover': {
			backgroundColor: '#ffc4c4',
		}
	},
	regular: { 
		backgroundColor: '#transparent',
	}
}));

const Component = props => {
	
	const classes = useStyles();

	return (
		<TableCell align={props.align || 'left'} className={classes[props.background]} variant="body" >
			{
				props.format === 'avatar' ?
					<Avatar alt={props.children} src={props.url} />
				:
					<Typography variant="body2" noWrap={props.noWrap}>
						{props.children}
					</Typography>	
			}
		</TableCell>
	)
}

export default Component;