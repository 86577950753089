import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { format } from 'date-fns';


import TrashIcon from '@material-ui/icons/DeleteOutline';
import SearchIcon from '@material-ui/icons/SearchOutlined';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Badge from '@material-ui/core/Badge';
import Link from '@material-ui/core/Link';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';

import FormDialog from './../../presentationals/FormDialog';
import SelectField from './../../presentationals/SelectField';
import TextField from './../../presentationals/TextField';
import DateField from './../../presentationals/DateField';
import DateTimeField from './../../presentationals/DateTimeField';
import Autocomplete from './../../presentationals/Autocomplete';
import ImageField from './../../presentationals/ImageField';
import IconButton from './../../presentationals/IconButton';

import { snackbar } from './../../apollo/cache';
import { parseError } from './../../helpers';
import config from './../../config/config.json';

import { statusOptions, subStatusOptions } from './helpers';

import GQL from './_gql';
import { surgeryDoctorOptionsSelect } from '.';

const _privileges = parseInt(localStorage.getItem('privileges'));

const defaultSnackbar = { isOpen: true, time: 3000 };

const useStyles = makeStyles((theme) => ({
  image: {
    height: 200,
    borderRadius: 5,
    margin: theme.spacing(1)
  },
  badgeButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 1, 0, 0), 
    color: '#5c5c5c',
    backgroundColor: '#fff',
    top: 0, 
    '&:hover': {
      color: '#000',
      backgroundColor: '#fff',
    }
  },
  lastBadgeButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 8, 0, 0), 
    color: '#5c5c5c',
    backgroundColor: '#fff',
    top: 0, 
    '&:hover': {
      color: '#000',
      backgroundColor: '#fff',
    }
  }
}));

const defaultValues = {
	status: '',
  subStatus: '',
  statusCommentary: '', 
  statusDate: null,
  firstDate: null,
  preSurgeryDate: null,
  surgeryDate: null,
  postSurgeryDate: null,
  oneProcedureId: null,
  twoProcedureId: null,
  threeProcedureId: null,
  name: '',
  age: '',
  phone: '',
  place: '',
  clinicHistory: false,
  chemicals: false,
  anesthesia: false,
  intensive: false,
  doctor: false,
  doctorCommentary: "",
  feedback: false,
  feedbackDate: null,
  xopDate: null,
  cancelDate: null,
	feedbackCommentary: '',
	total: 0,
	payment: 0,
	paid: false,
	commision: 0,
	surgeryDoctor: 0,
};

const yesNoOptions = [
	{ value: true, label: 'SI' },
	{ value: false, label: 'NO' },
]



const UpdateDialog = props => {

	const surgeryDoctorOptions = [
		{ value: 0, label: 'No asignado' },
		...surgeryDoctorOptionsSelect
	];

	const classes = useStyles();

	const [ errors, setErrors ] = useState({});
	const [ values, setValues ] = useState(defaultValues);
	const [ clinicHistoryImage, setClinicHistoryImage ] = useState(false);
	const [ paymentImage, setPaymentImage ] = useState(false);
	const [ doctorImage, setDoctorImage ] = useState(false);
	const [ beforeImage, setBeforeImage ] = useState(false);
	const [ hideAction, setHideAction ] = useState(false);
	const [ previewImage, setPreviewImage ] = useState([null,null]);
	
	const [ updateCrm, { loading } ] = useMutation(
		clinicHistoryImage || paymentImage || beforeImage || doctorImage
			? GQL.UPLOAD_FILE 
			: GQL.UPDATE
	);

	useEffect(() => {
		if(props.params){
			if(props.params.row === 'before'){
				setBeforeImage(true);
			}
			if(props.params.row === "doctor" && props.params.doctorCommentary){
				setValues({...values, doctor: props.params.value, doctorCommentary: props.params.doctorCommentary});
				return;
			}
			if(['oneProcedureId','twoProcedureId','threeProcedureId'].includes(props.params.row)) {
				const data = props.procedures.find(tmp => parseInt(tmp.value) === parseInt(props.params.value));
				setValues({ ...values, [props.params.row]: data || null });
				return;
			}
			if(['clinicHistory','chemicals','anesthesia','intensive','feedback','feedbackCommentary','total','payment','paid','commision'].includes(props.params.row)) {
      	setValues({ ...values, [props.params.row]: props.params.value || '' });
				return;
     	}
			setValues({ ...values, [props.params.row]: props.params.value || null });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.params]);

	const handleAction = async e => {
		try {
			const { id, row } = props.params;
			if(clinicHistoryImage || paymentImage || beforeImage || doctorImage){
				await updateCrm({ 
					variables: { 
						id, 
						type: clinicHistoryImage 
							? 'HistoryClinic'
							: paymentImage 
								? 'Payment'
								: beforeImage 
									? 'Before'
									: 'Doctor', 
						file: previewImage[1] 
					} 
				});
				setDoctorImage(false);
				setBeforeImage(false);
				setPaymentImage(false);
				setClinicHistoryImage(false);
				props.handleClose();
				return;
			}
			const value = ['oneProcedureId','twoProcedureId','threeProcedureId'].includes(row)
				? values[row].value
				:	['total','payment','commision'].includes(row)
					? parseFloat(values[row])
					: values[row];

			if(props.params.row === "doctor" && values.doctorCommentary){
				await updateCrm({ variables: { id, [row]: value, doctorCommentary: values.doctorCommentary } });
				props.handleClose();
				return;
			}
			await updateCrm({ variables: { id, [row]: value } });
			props.handleClose();
		} catch(e) {
			const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if(el.name === 'BAD_USER_INPUT') {
          setErrors(el.message);
        } else {
          snackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
        }
      });
		};
	}

	const handleCloseForm = () => {
		setPaymentImage(false);
		setClinicHistoryImage(false);
		setDoctorImage(false);
		setHideAction(false);
		setPreviewImage([null,null]);
		props.handleClose();
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleDeleteStatusDate = () => {
  	setValues(values => ({ ...values, statusDate: '2050/01/01' }));
  }

  const handleDeleteSurgeryDate = () => {
  	setValues(values => ({ ...values, surgeryDate: '2050/01/01' }));
  }

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  const handleDateTimeChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd HH:mm')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  const handleAutocompleteChange = (e, name, value) => {
  	console.log(name,value);
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleImage = (uri,file) => {
  	setHideAction(false);
		window.URL.revokeObjectURL(previewImage[0]);
		setPreviewImage([uri,file]);
	}
 
  const handleClinicHistoryImage = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setClinicHistoryImage(!clinicHistoryImage);
  }

  const handlePaymentImage = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setPaymentImage(!paymentImage);
  }

  const handleDoctorImage = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setDoctorImage(!doctorImage);
  }

  const handleDeleteFile = (e, params) => {
  	console.log(params)
  }

  const handleShowFile = (e, params) => {
  	window.open(`${config["api-file-service"].url}/files/${params}`, '_href');
  }

	return (
		<FormDialog
			isOpen={props.isOpen}
			isLoading={loading}
			title='Actualización del Seguimiento'
      handleClose={handleCloseForm}
      handleAction={handleAction}
      actionLabel={
      	(clinicHistoryImage || paymentImage || beforeImage || doctorImage)
	      	? 'Guardar' 
	      	: 'Actualizar'
      }
      hideAction={_privileges === 4 ? true : hideAction}
		>
			{
				(props.params && props.params.row === 'status') &&
					<SelectField
						name="status"
						label="Estatus"
						value={values.status}
						error={errors.status}
						onChange={handleChange}
						options={statusOptions}
					/>
			}
			{
				(props.params && props.params.row === 'subStatus') &&
					<SelectField
						name="subStatus"
						label="Subtipo"
						value={values.subStatus}
						onChange={handleChange}
						options={subStatusOptions}
					/>
			}
			{
				(props.params && props.params.row === 'statusCommentary') &&
					<TextField
						name="statusCommentary"
						label="Comentario"
						value={values.statusCommentary}
						onChange={handleChange}
					/>
			}
			{
				(props.params && props.params.row === 'statusDate') &&
					<Grid container alignItems='flex-end'>
				    <Grid item xs={11}>
							<DateField
								name="statusDate"
								label="Seguimiento"
								value={
									new Date(values.statusDate) <= new Date('2040/01/01') ? values.statusDate : null
								}
								noFormat
								onChange={handleDateChange}
							/>
						</Grid>
				    <Grid item xs={1} align='right'>
							<Link href='#' onClick={handleDeleteStatusDate}><TrashIcon /></Link>
						</Grid>
					</Grid>
			}
			{
				(props.params && props.params.row === 'name') &&
					<TextField
						name="name"
						label="Nombre"
						value={values.name}
						onChange={handleChange}
					/>
			}
			{
				(props.params && props.params.row === 'age') &&
					<TextField
						name="age"
						label="Edad"
						value={values.age}
						onChange={handleChange}
					/>
			}
			{
				(props.params && props.params.row === 'place') &&
					<TextField
						name="place"
						label="Ubicación"
						value={values.place}
						onChange={handleChange}
					/>
			}
			{
				(props.params && props.params.row === 'phone') &&
					<TextField
						name="phone"
						label="Télefono"
						value={values.phone}
						onChange={handleChange}
					/>
			}
			{
				(props.params && props.params.row === 'oneProcedureId') &&
					<Autocomplete
						name="oneProcedureId"
						label="Proc1"
						value={values.oneProcedureId}
						onChange={handleAutocompleteChange}
						options={props.procedures}
					/>
			}
			{
				(props.params && props.params.row === 'twoProcedureId') &&
					<Autocomplete
						name="twoProcedureId"
						label="Proc2"
						value={values.twoProcedureId}
						onChange={handleAutocompleteChange}
						options={props.procedures}
					/>
			}
			{
				(props.params && props.params.row === 'threeProcedureId') &&
					<Autocomplete
						name="threeProcedureId"
						label="Proc3"
						value={values.threeProcedureId}
						onChange={handleAutocompleteChange}
						options={props.procedures}
					/>
			}
			{
				(props.params && props.params.row === 'firstDate') &&
					<DateTimeField
						name="firstDate"
						label="Primera Cita"
						value={values.firstDate}
						onChange={handleDateTimeChange}
					/>
			}
			{
				(props.params && props.params.row === 'preSurgeryDate') &&
					<DateTimeField
						name="preSurgeryDate"
						label="Cita de Pre Cirugía"
						value={values.preSurgeryDate}
						onChange={handleDateTimeChange}
					/>
			}
			{
				(props.params && props.params.row === 'surgeryDate') &&
					<Grid container alignItems='flex-end'>
				    <Grid item xs={11}>
							<DateTimeField
								name="surgeryDate"
								label="Cita de Cirugía"
								value={
									new Date(values.surgeryDate) <= new Date('2040/01/01') ? values.surgeryDate : null
								}
								onChange={handleDateTimeChange}
								noFormat
							/>
						</Grid>
				    <Grid item xs={1} align='right'>
							<Link href='#' onClick={handleDeleteSurgeryDate}><TrashIcon /></Link>
						</Grid>
					</Grid>
			}
			{
				(props.params && props.params.row === 'postSurgeryDate') &&
					<DateTimeField
						name="postSurgeryDate"
						label="Cita de Post Cirugía"
						value={values.postSurgeryDate}
						onChange={handleDateTimeChange}
					/>
			}
			{
				(props.params && props.params.row === 'clinicHistory') &&
					<div>
						<Collapse in={!clinicHistoryImage}>
							<SelectField
								name="clinicHistory"
								label="Historia Clínica"
								value={values.clinicHistory}
								onChange={handleChange}
								options={yesNoOptions}
							/>
							<Grid container>
								<Grid item xs={1} />
								<Grid item xs={9}>
									<br />
									<Link href="#" onClick={handleClinicHistoryImage}>
								    Adjuntar imagen de historia clínica
								  </Link>
								</Grid>
								<Grid item xs={1} />
								
								<Grid item xs={12}>
									<Grid container align='center'>
									{ props.params.files.map(el => 
											<Grid key={el.id} item md={6} xs={12}>
												<Badge
						              overlap="circle"
						              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						              badgeContent={
						              	<div>
							                <IconButton  
							                  params={el.id} 
							                  action={handleShowFile} 
							                  className={classes.badgeButton}
							                  icon={<SearchIcon />}
							                /> 
							                <IconButton  
							                  params={el.id} 
							                  action={handleDeleteFile} 
							                  className={classes.lastBadgeButton}
							                  icon={<TrashIcon />}
							                />   
						                </div>             
							            }
							          >
						              <img
						                src={`${config["api-file-service"].url}/files/${el.id}`} alt={`IMG-${el.id}`}
						                className={classes.image}
						              />
							          </Badge>
						          </Grid>
										) 
									}
									</Grid>
								</Grid>
								
						  </Grid>
					  </Collapse>
						<Collapse in={clinicHistoryImage}>
							<ImageField croppedImage={handleImage}>
								<Link href="#" onClick={handleClinicHistoryImage}>
							    Volver a la ventana anterior
							  </Link>
							</ImageField>
						</Collapse>
					</div>
			}
			{
				(props.params && props.params.row === 'before') &&
					<ImageField croppedImage={handleImage}>
						{ props.params.files.map(el => 
							<Grid key={el.id} item md={6} xs={12}>
								<Badge
		              overlap="circle"
		              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
		              badgeContent={
		                <div>
			                <IconButton  
			                  params={el.id} 
			                  action={handleShowFile} 
			                  className={classes.badgeButton}
			                  icon={<SearchIcon />}
			                /> 
			                <IconButton  
			                  params={el.id} 
			                  action={handleDeleteFile} 
			                  className={classes.lastBadgeButton}
			                  icon={<TrashIcon />}
			                />   
		                </div>                      
			            }
			          >
		              <img
		                src={`${config["api-file-service"].url}/files/${el.id}`} alt={`IMG-${el.id}`}
		                className={classes.image}
		              />
			          </Badge>
		          </Grid>
						) 
					}
				</ImageField>
			}
			{
				(props.params && props.params.row === 'chemicals') &&
					<SelectField
						name="chemicals"
						label="Químicos"
						value={values.chemicals}
						onChange={handleChange}
						options={yesNoOptions}
					/>
			}
			{
				(props.params && props.params.row === 'anesthesia') &&
					<SelectField
						name="anesthesia"
						label="Anestesiólogo"
						value={values.anesthesia}
						onChange={handleChange}
						options={yesNoOptions}
					/>
			}
			{
				(props.params && props.params.row === 'intensive') &&
					<SelectField
						name="intensive"
						label="Intensivista"
						value={values.intensive}
						onChange={handleChange}
						options={yesNoOptions}
					/>
			}
			{
				(props.params && props.params.row === 'surgeryDoctor') &&
					<SelectField
						name="surgeryDoctor"
						label="Doctor"
						value={values.surgeryDoctor}
						onChange={handleChange}
						options={surgeryDoctorOptions}
					/>
			}
			{
				(props.params && props.params.row === 'doctor') &&
					<div>
						<Collapse in={!doctorImage}>
							<SelectField
								name="doctor"
								label="Valoración"
								value={values.doctor}
								onChange={handleChange}
								options={yesNoOptions}
							/>
							<TextField
								name='doctorCommentary'
								label='Comentario'
								onChange={handleChange}
								value={values.doctorCommentary}
							/>
							<Grid container>
								<Grid item xs={1} />
								<Grid item xs={9}>
									<br />
									<Link href="#" onClick={handleDoctorImage}>
								    Adjuntar imagen de paciente
								  </Link>
								</Grid>
								<Grid item xs={1} />
								
								<Grid item xs={12}>
									<Grid container align='center'>
									{ props.params.files.map(el => 
											<Grid key={el.id} item md={6} xs={12}>
												<Badge
						              overlap="circle"
						              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						              badgeContent={
						                <div>
							                <IconButton  
							                  params={el.id} 
							                  action={handleShowFile} 
							                  className={classes.badgeButton}
							                  icon={<SearchIcon />}
							                /> 
							                <IconButton  
							                  params={el.id} 
							                  action={handleDeleteFile} 
							                  className={classes.lastBadgeButton}
							                  icon={<TrashIcon />}
							                />   
						                </div>                      
							            }
							          >
						              <img
						                src={`${config["api-file-service"].url}/files/${el.id}`} alt={`IMG-${el.id}`}
						                className={classes.image}
						              />
							          </Badge>
						          </Grid>
										) 
									}
									</Grid>
								</Grid>
								
						  </Grid>
					  </Collapse>
						<Collapse in={doctorImage}>
							<ImageField croppedImage={handleImage}>
								<Link href="#" onClick={handleDoctorImage}>
							    Volver a la ventana anterior
							  </Link>
							</ImageField>
						</Collapse>
					</div>
			}
			{
				(props.params && props.params.row === 'feedback') &&
					<SelectField
						name="feedback"
						label="Confirmación de Cirugía"
						value={values.feedback}
						onChange={handleChange}
						options={yesNoOptions}
					/>
			}
			{
				(props.params && props.params.row === 'feedbackDate') &&
					<DateField
						name="feedbackDate"
						label="Fecha de Confirmación"
						value={values.feedbackDate}
						onChange={handleDateChange}
					/>
			}
			{
				(props.params && props.params.row === 'cancelDate') &&
					<DateField
						name="cancelDate"
						label="Fecha de Cancelación"
						value={values.cancelDate}
						onChange={handleDateChange}
					/>
			}
			{
				(props.params && props.params.row === 'xopDate') &&
					<DateField
						name="xopDate"
						label="Fecha XOP"
						value={values.xopDate}
						onChange={handleDateChange}
					/>
			}
			{
				(props.params && props.params.row === 'feedbackCommentary') &&
					<TextField
						name="feedbackCommentary"
						label="Comentario"
						value={values.feedbackCommentary}
						onChange={handleChange}
					/>
			}
			{
				(props.params && props.params.row === 'total') &&
					<TextField
						name="total"
						label="Presupuesto"
						value={values.total}
						onChange={handleChange}
						type='number'
					/>
			}
			{
				(props.params && props.params.row === 'payment') &&
					<div>
						<Collapse in={!paymentImage}>
							<TextField
								name="payment"
								label="Abono"
								value={values.payment}
								onChange={handleChange}
								type='number'
							/>
							<Grid container>
								<Grid item xs={1} />
								<Grid item xs={9}>
									<br />
									<Link href="#" onClick={handlePaymentImage}>
								    Adjuntar imagen de pago
								  </Link>
								</Grid>
								<Grid item xs={1} />
								
								<Grid item xs={12}>
									<Grid container align='center'>
									{ props.params.files.map(el => 
											<Grid key={el.id} item md={6} xs={12}>
												<Badge
						              overlap="circle"
						              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						              badgeContent={
						                <div>
							                <IconButton  
							                  params={el.id} 
							                  action={handleShowFile} 
							                  className={classes.badgeButton}
							                  icon={<SearchIcon />}
							                /> 
							                <IconButton  
							                  params={el.id} 
							                  action={handleDeleteFile} 
							                  className={classes.lastBadgeButton}
							                  icon={<TrashIcon />}
							                />   
						                </div>                        
							            }
							          >
						              <img
						                src={`${config["api-file-service"].url}/files/${el.id}`} alt={`IMG-${el.id}`}
						                className={classes.image}
						              />
							          </Badge>
						          </Grid>
										) 
									}
									</Grid>
								</Grid>
								
						  </Grid>
					  </Collapse>
						<Collapse in={paymentImage}>
							<ImageField croppedImage={handleImage}>
								<Link href="#" onClick={handlePaymentImage}>
							    Volver a la ventana anterior
							  </Link>
							</ImageField>
						</Collapse>
					</div>
			}
			{
				(props.params && props.params.row === 'paid') &&
					<SelectField
						name="paid"
						label="Liquidación"
						value={values.paid}
						onChange={handleChange}
						options={yesNoOptions}
					/>
			}
			{
				(props.params && props.params.row === 'commision') &&
					<TextField
						name="commision"
						label="Comisión"
						value={values.commision}
						onChange={handleChange}
						type='number'
					/>
			}
		</FormDialog>
	);
};

export default UpdateDialog;