import { Observable } from '@apollo/client';
import { onError } from "@apollo/client/link/error";

import getNewToken from './helpers/getNewToken';

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    const error = graphQLErrors[0];
    if(error.extensions.code === 'UNAUTHENTICATED') {
      return new Observable(async observer => {
        try {
          const oldHeaders = operation.getContext().headers;
          const newToken = await getNewToken();
          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...oldHeaders,
              authorization: newToken
            }
          }));
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          };
          forward(operation).subscribe(subscriber);
        } catch(e) {
          observer.error(e);
        }
      });
    }
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export default errorLink;