import React from 'react';

import LinkMui from '@material-ui/core/Link';

function statusToLabel(value) {
	switch(value) {
		case 1: 
			return 'PROS';
		case 2: 
			return '1RA';
		case 3: 
			return 'COT';
		case 4: 
			return 'XOP';
		case 5: 
			return 'OP';
		// case 6: 
		// 	return 'OPS';
		case 7: 
			return 'OPSF'; //Con foto
		case 15:
			return 'CANCEL';
		default:
			return '-'
	}
}

const statusOptions = [
	{ value: 1, label: 'PROS' },
	{ value: 2, label: '1RA' },
	{ value: 3, label: 'COT' },
	{ value: 4, label: 'XOP' },
	{ value: 5, label: 'OP' },
	{ value: 7, label: 'OPSF' },
	{ value: 15, label: 'CANCEL' },
];

function subStatusToLabel(value) {
	switch(value) {
		case 1: 
			return 'EN PROC';
		case 2: 
			return 'NO CON';
		case 3: 
			return 'PRECIO';
		case 4: 
			return 'UBICAC';
		case 5: 
			return 'SIN FE';
		case 6: 
			return 'NO APL';
		case 7: 
			return 'DESPUES';
		case 8: 
			return 'NO INTE';
		case 9: 
			return 'OTRA FE';
		case 10: 
			return 'NO SATI';
		case 11: 
			return 'NO QUIE';
		case 12: 
			return 'CALCULAN';
		case 13: 
			return 'FOTOS';
		case 14: 
			return 'NO REA PROC';
		default:
			return '-'
	}
}

const subStatusOptions = [
	{ value: 1, label: 'EN PROC' },
	{ value: 2, label: 'NO CON' },
	{ value: 3, label: 'PRECIO' },
	{ value: 4, label: 'UBICAC' },
	{ value: 5, label: 'SIN FE' },
	{ value: 6, label: 'NO APL' },
	{ value: 7, label: 'DESPUES' },
	{ value: 8, label: 'NO INTE' },
	{ value: 9, label: 'OTRA FE' },
	{ value: 10, label: 'NO SATI' },
	{ value: 11, label: 'NO QUIE' },
	{ value: 12, label: 'CALCULAN' },
	{ value: 13, label: 'FOTOS' },
	{ value: 14, label: 'NO REA PROC' },
];

const Link = props => {
	const handleClick = e => {
		e.preventDefault();
		props.handleClick(e, props.params);
	}
	return(
		<LinkMui href="#" onClick={handleClick} color="inherit">
			{props.children}
		</LinkMui>
	);
}

export { statusToLabel, subStatusToLabel, statusOptions, subStatusOptions, Link };