import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabelMui from '@material-ui/core/FormControlLabel';
import CheckboxMui from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

const Checkbox = ({ noFormat, icon, error, ...props }) => {
  const handleChange = (name, checked, params) => {
    props.handleChange(name, checked, params);
  }
  return (
    <Grid container spacing={0} alignItems="flex-end">
      <Grid item xs={ noFormat ? false : 1 }>
        { icon || "" }
      </Grid>
      <Grid item xs={noFormat ? 12 : 10}>
        <FormControl required component="fieldset">
          <FormGroup>
            {
              props.values.map(el => 
                <FormControlLabel 
                  key={`${el.name}-${el.label}`}
                  checked={el.checked} 
                  name={el.name} 
                  label={el.label} 
                  params={el.params} 
                  handleChange={handleChange} 
                />
              )
            }
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}

const FormControlLabel = props => {

  const handleChange = (e) => {
    props.handleChange(e.target.name, e.target.checked, props.params);
  };

  return (
    <FormControlLabelMui
      control={<CheckboxMui checked={props.checked} onChange={handleChange} name={props.name} />}
      label={props.label}
    />
  )
}   



export default Checkbox;