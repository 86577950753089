import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    height: 45,
    borderRadius: 40,
    backgroundColor: '#2ab859',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#26a851',
      boxShadow: 'none',
    }
  },
  box: { 
    boxShadow: 'none',
    backgroundColor: '#fafafa'
  },
  logo: {
    width: '100%',
    marginBottom: theme.spacing(6)
  },
  createAccount: {
    marginTop: theme.spacing(0.5)
  },
  title: {
    fontWeigth: 'bold',
    fontSize: 20,
    letterSpacing: -1,
    lineHeight: 1,
    marginBottom: theme.spacing(1),
    color: '#666'
  }
}));

export default useStyles;