import { gql } from '@apollo/client';

const CREATE = gql`
	mutation CreatePostSurgery(
		$crmId: ID!
		$date: Date!
	) {
		createPostSurgery(
			id: $crmId
			date: $date
		) {
			date
			status
		}
	}
`;

const CREATE_CRM = gql`
  mutation CreateCrm(
    $name: String!
    $age: String
    $nationality: Int
    $phone: String
		$catchment: Int!
		$status: Int
  ) {
    createCrm(input: {
      name: $name
      age: $age
      phone: $phone
      catchment: $catchment
      status: $status
      nationality: $nationality
    }) {
      id
			Customer {
				name
				age
				phone
			}
			createdAt
    }
  }
`;

const GET_BY_ID = gql`
  query PostSurgery($id: ID!){
    postSurgery(id: $id) {
      id
      date
      status
    }
  }
`;

const GET = gql`
	query PostSurgeries($expired: Boolean){
		postSurgeries(expired: $expired){
			count 
			rows{
				id
				surgeryDate
				postSurgeryCommentary
				postSurgeryCommission
				OneProcedure { 
					prefix
				}
				TwoProcedure {
					prefix
				}
				ThreeProcedure {
					prefix
				}
				Customer {
					name
					age
					phone
				}
				PostSurgery {
					id
					date
					status
					commentary
				}
				createdAt
				doctor
				PostSurgeryCount
				After { id }
				applyPostSurgeries
			}
    }
  }
`;

const UPDATE = gql`
	mutation UpdatePostSurgery(
		$id: ID!
		$status: Int
		$commentary: String
	) {
		updatePostSurgery(
			id: $id
			status: $status
			commentary: $commentary
		) {
			date
			status
			commentary
		}
	}
`;

const UPDATE_COMMENTARY = gql`
	mutation UpdatePostSurgeryCommentary(
		$id: ID!
		$commentary: String
	) {
		updateCrm(
			id: $id
			input: {
				postSurgeryCommentary: $commentary
			}
		) {
			id
			postSurgeryCommentary
		}
	}
`;

const UPDATE_COMMISSION = gql`
	mutation UpdatePostSurgeryCommission(
		$id: ID!
		$commission: Int
	) {
		updateCrm(
			id: $id
			input: {
				postSurgeryCommission: $commission
			}
		) {
			id
			postSurgeryCommission
		}
	}
`;

const GET_HISTORY = gql`
	query PostSurgeriesHistory($crmId: ID!){
		postSurgeriesHistory(crmId: $crmId){
			id
			date
			status
			commentary
		}
	}
`;

const GET_AFTER = gql`
	query Crm($id: ID!){
    crm(id: $id) {
      After {
      	id
      }
    }
  }
`;


const object = { CREATE, GET, UPDATE, GET_BY_ID, GET_HISTORY, UPDATE_COMMENTARY, UPDATE_COMMISSION, GET_AFTER, CREATE_CRM };
export default object;