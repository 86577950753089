import React from 'react';

import { useQuery } from '@apollo/client';

import Module from './../../presentationals/Module';
import GQL from './_gql';

const Rows = [
	{ label: 'Producto', key: 'name', sort: 'text', filter: true },
	{ label: 'SKU', key: 'sku', sort: 'text', filter: true },
	{ label: 'Costo', key: 'cost', format: 'currency' },
	{ label: 'Precio', key: 'price', format: 'currency' },
	{ label: 'Stock', key: 'stock', align: 'center' },
];

const Component = props => {

	const { data, loading } = useQuery(GQL.GET);
	
	return (
		<Module
			isLoading={loading}
			name='Productos'
			singular='Producto'
			operation={['product', 'products']}
			Rows={Rows}
			get={GQL.GET}
			getById={GQL.GET_BY_ID}
			create={GQL.CREATE}
			update={GQL.UPDATE}
			delete={GQL.DELETE}
			records={data && data.products}
			fields={[
				{ 
					name: 'name', 
					label: 'Producto',
					autoFocus: true
				},
				{ 
					name: 'sku', 
					label: 'SKU'
				},
				{ 
					name: 'cost', 
					label: 'Costo',
					defaultValue: 0,
					type: 'number',
				},
				{ 
					name: 'price', 
					label: 'Precio',
					defaultValue: 0,
					type: 'number',
				},
				{ 
					name: 'stock', 
					label: 'Stock',
					defaultValue: 0,
					type: 'number',
				},
				{ 
					name: 'description', 
					label: 'Descripción',
				}
			]}
		/>
	);
}

export default Component;