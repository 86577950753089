import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const token = localStorage.getItem('token');

const Authenticated = ({ component: C, ...rest }) => token 
	? <Route {...rest} render={props => <C {...props} />} /> 
	: <Redirect to="/public/login" />

const Unauthenticated = ({ component: C, ...rest }) => 
  <Route {...rest} render={ props => !token ? <C {...props} /> : <Redirect to="/" /> } />

export { Authenticated, Unauthenticated };