import React, { useState, useEffect } from 'react';

import { useMutation, useQuery, useLazyQuery } from '@apollo/client';

import { format } from 'date-fns';

import TrashIcon from '@material-ui/icons/DeleteOutline';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import CameraAltIcon from '@material-ui/icons/CameraAltOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Badge from '@material-ui/core/Badge';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid'; 
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import ButtonMUI from '@material-ui/core/Button';

import FormDialog from './../../presentationals/FormDialog';
import SelectField from './../../presentationals/SelectField';
import TextField from './../../presentationals/TextField';
import DateField from './../../presentationals/DateField';
import DateTimeField from './../../presentationals/DateTimeField';
import Autocomplete from './../../presentationals/Autocomplete';
import Button from './../../presentationals/Button';
import ImageField from './../../presentationals/ImageField';
import IconButton from './../../presentationals/IconButton';

import config from './../../config/config.json';

import GQL_PROCEDURE from './../procedure/_gql';
import GQL from './_gql';

import ReviewPdf from './review.pdf';
import { catchmentOptions } from '.';

const _privileges = parseInt(localStorage.getItem('privileges'));

const useStyles = makeStyles((theme) => ({
  image: {
    height: 200,
    borderRadius: 5,
    margin: theme.spacing(1)
  },
  badgeButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0, 0, 5), 
    color: '#5c5c5c',
    backgroundColor: 'rgba(255,255,255,0.7)',
    top: 0, 
    '&:hover': {
      color: '#000',
      backgroundColor: '#fff',
    }
  },
  lastBadgeButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 5, 0, 0), 
    color: '#5c5c5c',
    backgroundColor: 'rgba(255,255,255,0.7)',
    top: 0, 
    '&:hover': {
      color: '#000',
      backgroundColor: '#fff',
    }
  },
  formContainer: {
  	padding: theme.spacing(3, 5, 5, 5),
  	height: 435,
  	overflow: 'auto'
  },
  input: {
    display: 'none'
  },
  uploadButton: {
    border: '1px solid #808080',
    textTransform: 'none',
  },
}));

const defaultValues = {
	statusDate: null,
	firstDate: null,
  preSurgeryDate: null,
  surgeryDate: null,
  postSurgeryDate: null,
  oneProcedureId: null,
  twoProcedureId: null,
  threeProcedureId: null,
  fourProcedureId: null,
  fiveProcedureId: null,
  statusCommentary: '',
  name: '',
  age: '',
  phone: '',
  place: '',
  chemicals: false,
  anesthesia: false,
  intensive: false,
  doctor: false,
  doctorCommentary: '',
  historyClinic: '',
  historyClinicCommentary: '',
  feedback: false,
  feedbackDate: null,
  cancelDate: null,
  xopDate: null,
	feedbackCommentary: '',
	total: 0,
	payment: 0,
	totalDll: 0,
	paymentDll: 0,
	paid: false,
	commision: 0,
  passwordCommision: '',
	balance: 0,
	catchment: ''
};

const yesNoOptions = [
	{ value: true, label: 'SI' },
	{ value: false, label: 'NO' },
];

const PersonalDataDialog = ({ isAdmin, ...props }) => {

	const classes = useStyles();

	const [ procedures, setProcedures ] = useState([]);
	const [ files, setFiles ] = useState([]);
	const [ values, setValues ] = useState(defaultValues);
	const [ hideAction, setHideAction ] = useState(false);
	const [ imageDialog, setImageDialog ] = useState([false, null]);
	const [ previewImage, setPreviewImage ] = useState([null,null]);
	const [ isUpload, setIsUpload ] = useState(false);
	
	const { data: dataProcedures } = useQuery(GQL_PROCEDURE.GET);
	const [ getCRM, { data, loading: loadingData }] = useLazyQuery(GQL.GET_BY_ID);

	const [ updateCrm, { loading } ] = useMutation(GQL.UPDATE);
	const [ updateImg, { loading: loadingImage } ] = useMutation(GQL.UPLOAD_FILE, {
		update(cache, { data: response }) {
      try{
        const dataResponse = parseInt(response.updateFiles.id);
        const oldQuery = cache.readQuery({ query: GQL.GET_BY_ID, variables: { id: props.crmId } });
        const newQuery = [ ...oldQuery.crm[imageDialog[1]], { id: dataResponse, __typename: 'File' } ];
        cache.writeQuery({
          query: GQL.GET_BY_ID,
          variables: { id: props.crmId },
          data: { crm: { ...oldQuery.crm, [imageDialog[1]]: newQuery } }
        });
      }catch(e){
        console.log(e.message);
      }
    },
	});
	const [ deleteImg ] = useMutation(GQL.DELETE_FILE, {
		update(cache, { data: response }) {
      try{
      	cache.evict({ 
      		id: cache.identify({ __typename: 'File', id: response.deleteFiles.id}),
   			});
      }catch(e){
        console.log(e.message);
      }
    },
    onCompleted(data) {
      const newQuery = files.filter(el => parseInt(el.id) !== parseInt(data.deleteFiles.id));
      setFiles(newQuery);
    }
	});

	useEffect(() => {
		if(props.crmId){
			getCRM({ variables: { id: props.crmId } });
		}
	}, [props.crmId, getCRM]);

	useEffect(() => {
		if(data && data.crm && procedures.length > 0){
			const currentData = data.crm;
			const oneProcedureId = currentData.OneProcedure ? procedures.find(el => parseInt(el.value) === parseInt(currentData.OneProcedure.id)) : null;
			const twoProcedureId = currentData.TwoProcedure ? procedures.find(el => parseInt(el.value) === parseInt(currentData.TwoProcedure.id)) : null;
			const threeProcedureId = currentData.ThreeProcedure ? procedures.find(el => parseInt(el.value) === parseInt(currentData.ThreeProcedure.id)) : null;
			const fourProcedureId = currentData.FourProcedure ? procedures.find(el => parseInt(el.value) === parseInt(currentData.FourProcedure.id)) : null;
			const fiveProcedureId = currentData.FiveProcedure ? procedures.find(el => parseInt(el.value) === parseInt(currentData.FiveProcedure.id)) : null;
			const totalDll = currentData.totalDll ? parseFloat(currentData.totalDll) : 0;
			const total = currentData.total ? parseFloat(currentData.total) : 0;
			const payment = currentData.payment ? parseFloat(currentData.payment) : 0;
			const paymentDll = currentData.paymentDll ? parseFloat(currentData.paymentDll) : 0;
			const balance = parseFloat(total) - parseFloat(payment);
			const tmp = {
			  oneProcedureId, twoProcedureId, threeProcedureId, fourProcedureId, fiveProcedureId, total, payment, balance, totalDll, paymentDll,
				statusDate: currentData.statusDate,
				firstDate: currentData.firstDate,
			  preSurgeryDate: currentData.preSurgeryDate,
			  surgeryDate: currentData.surgeryDate,
			  postSurgeryDate: currentData.postSurgeryDate,
			  statusCommentary: currentData.statusCommentary || '',
			  name: currentData.Customer.name,
			  age: currentData.Customer.age,
			  phone: currentData.Customer.phone,
			  place: currentData.Customer.place,
			  chemicals: currentData.chemicals || false,
			  anesthesia: currentData.anesthesia || false,
			  intensive: currentData.intensive || false,
			  historyClinic: currentData.historyClinic || '',
  			historyClinicCommentary: currentData.historyClinicCommentary || '',
			  doctor: currentData.doctor || false,
			  doctorCommentary: currentData.doctorCommentary || '',
			  feedback: currentData.feedback || false,
			  feedbackDate: currentData.feedbackDate || null,
        cancelDate: currentData.cancelDate || null,
        xopDate: currentData.xopDate || null,
				feedbackCommentary: currentData.feedbackCommentary || '',
				paid: currentData.paid || false,
				commision: currentData.commision || 0,
				catchment: currentData.catchment || '',
        passwordCommision: ''
			};
			setValues(tmp);
		}
	}, [data, procedures]);

	useEffect(() => {
		if(dataProcedures){
			const tmp = dataProcedures && dataProcedures.procedures.rows.map(el => ({ value: el.id, label: el.prefix }));
			setProcedures(tmp);
		}
	}, [dataProcedures]);

	const handleAction = async e => {
		try {
			if(imageDialog[0]){
				await updateImg({ 
					variables: { id: props.crmId, type: imageDialog[1], file: previewImage[1] }
				});
				setImageDialog([false, null]);
				setPreviewImage([null,null]);
				setIsUpload(true);
				setFiles([]);
				return;
			}
			await updateCrm({ 
				variables: { 
					id: props.crmId, 
					...values,
					oneProcedureId: values.oneProcedureId ? values.oneProcedureId.value : null,
					twoProcedureId: values.twoProcedureId ? values.twoProcedureId.value : null,
					threeProcedureId: values.threeProcedureId ? values.threeProcedureId.value : null,
					fourProcedureId: values.fourProcedureId ? values.fourProcedureId.value : null,
					fiveProcedureId: values.fiveProcedureId ? values.fiveProcedureId.value : null,
					total: parseFloat(values.total),
					totalDll: parseFloat(values.totalDll),
					payment: parseFloat(values.payment),
					paymentDll: parseFloat(values.paymentDll),
					commision: parseFloat(values.commision)
				} 
			});
      setValues(values => ({ ...values, passwordCommision: '' }));
			props.handleClose();
		} catch(e) {
			console.log(e.message);
			setImageDialog([false, null]);
			setFiles([]);
		};
	}

	const handleCloseForm = () => {
		setImageDialog([false, null]);
		setHideAction(false);
		setPreviewImage([null,null]);
		setIsUpload(false);
		props.handleClose();
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));

    if(['total', 'payment'].includes(name)){
    	const total = name === 'total' && !isNaN(parseFloat(value)) ? parseFloat(value) : name === 'total' && isNaN(parseFloat(value)) ? 0 : name !== 'total' ? parseFloat(values.total) : 0;
    	const payment = name === 'payment' && !isNaN(parseFloat(value)) ? parseFloat(value) : name === 'payment' && isNaN(parseFloat(value)) ? 0 : name !== 'payment' ? parseFloat(values.payment) : 0;
    	const balance = total - payment;
    	setValues(values => ({ ...values, balance }));
    }
  }

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  const handleDateTimeChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd HH:mm')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleImage = (uri,file) => {
  	setHideAction(false);
  	console.log(uri, file);
		window.URL.revokeObjectURL(previewImage[0]);
		setPreviewImage([uri,file]);
	}
 
  const handleClinicHistoryImage = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setImageDialog([ true, 'HistoryClinic' ]);
  	setFiles(data.crm.HistoryClinic);
  }
 
  const handleClinicHistoryPdf = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setImageDialog([ true, 'HistoryClinic', true ]);
  	setFiles(data.crm.HistoryClinic);
  }

  const handlePaymentImage = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setImageDialog([ true, 'Payment' ]);
  	setFiles(data.crm.Payment);
  }

  const handleDoctorImage = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setImageDialog([ true, 'Doctor' ]);
  	setFiles(data.crm.Doctor);
  }

  const handleDoctorPdf = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setImageDialog([ true, 'Doctor', true ]);
  	setFiles(data.crm.Doctor);
  }

  const handleBeforeImage = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setImageDialog([ true, 'Before' ]);
  	setFiles(data.crm.Before);
  }

  const handleChemicalImage = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setImageDialog([ true, 'Chemicals' ]);
  	setFiles(data.crm.Chemicals);
  }

  const handleChemicalPdf = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setImageDialog([ true, 'Chemicals', true ]);
  	setFiles(data.crm.Chemicals);
  }

  const handleIntensiveImage = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setImageDialog([ true, 'Intensive' ]);
  	setFiles(data.crm.Intensive);
  }

  const handleIntensivePdf = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setImageDialog([ true, 'Intensive', true ]);
  	setFiles(data.crm.Intensive);
  }

  const handleAnesthesiaImage = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setImageDialog([ true, 'Anesthesia' ]);
  	setFiles(data.crm.Anesthesia);
  }

  const handleAnesthesiaPdf = e => {
  	e.preventDefault();
  	setHideAction(!hideAction);
  	setImageDialog([ true, 'Anesthesia', true ]);
  	setFiles(data.crm.Anesthesia);
  }

  const handleBack = () => {
  	setHideAction(!hideAction);
  	setImageDialog([false, null]);
  }

  const handleDeleteFile = async (e, params) => {
  	try{
  		await deleteImg({ variables: { id: props.crmId, fileId: params, type: imageDialog[1] } });
  	}catch(e) {
  		console.log(e.message);
  	}
  }

  const handleShowFile = (e, params) => {
  	window.open(`${config["api-file-service"].url}/files/${params}`, '_href');
  }

  const handlePrint = () => {
  	ReviewPdf(props.crmId);
  }

  const handleResetImage = () => {
  	setIsUpload(false);
  }

  const handleDeleteStatusDate = () => {
  	setValues(values => ({ ...values, statusDate: '2050/01/01' }));
  }

  const handleDeleteSurgeryDate = () => {
    setValues(values => ({ ...values, surgeryDate: '2050/01/01' }));
  }

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
    	setPreviewImage(['', e.target.files[0]]);
      setHideAction(!hideAction);
    }
  };

	return (
		<FormDialog
      fullScreen
			isOpen={props.isOpen}
			isLoading={loadingData || loading || loadingImage}
			title='Actualización del Seguimiento'
      handleClose={handleCloseForm}
      handleAction={handleAction}
      hideAction={hideAction || (_privileges === 4 && true)}
      rightContent={
      	<Button 
      		label='Imprimir PDF'
      		variant='outlined'
      		onClick={handlePrint}
      		color='secondary'
      	/>
     	}
      actionLabel={imageDialog[0] ? 'Guardar' : 'Actualizar'}
      contentBackground={imageDialog[0] ? '#fff' : '#f6f6f6'}
		>	
			<div>
			<Collapse in={!imageDialog[0]}>
				<Grid container spacing={2}>
					<Grid item md={6} xs={12}>
						<Paper className={classes.formContainer}>
							<Typography variant="h5" gutterBottom>
				        Datos Personales
				      </Typography>
							<TextField
								name="name"
								label="Nombre"
								value={values.name}
								onChange={handleChange}
								noFormat
							/>
							<TextField
								name="age"
								label="Edad"
								value={values.age}
								onChange={handleChange}
								noFormat
							/>
							<TextField
								name="place"
								label="Ubicación"
								value={values.place}
								onChange={handleChange}
								noFormat
							/>
							<TextField
								name="phone"
								label="Télefono"
								value={values.phone}
								onChange={handleChange}
								noFormat
							/>
							<Grid container alignItems='flex-end'>
				      	<Grid item xs={10}>
				      		<TextField
										name="historyClinic"
										label="Historia Clínica"
										value={values.historyClinic}
										onChange={handleChange}
										noFormat
									/>
				      	</Grid>
				      	<Grid item xs={1} align='right'>
				      		<Link href='#' onClick={handleClinicHistoryPdf}><PictureAsPdfIcon /></Link>
				      	</Grid>
				      	<Grid item xs={1} align='right'>
				      		<Link href='#' onClick={handleClinicHistoryImage}><CameraAltIcon /></Link>
				      	</Grid>
				      </Grid>
							
							<TextField
								name="historyClinicCommentary"
								label="Comentario de Historia Clínica"
								value={values.historyClinicCommentary}
								onChange={handleChange}
								noFormat
							/>
						</Paper>
					</Grid>
					<Grid item md={6} xs={12}>
						<Paper className={classes.formContainer}>
							<Typography variant="h5" gutterBottom>
				        Procedimientos
				      </Typography>
							<Autocomplete
								name="oneProcedureId"
								label="Proc1"
								value={values.oneProcedureId}
								onChange={handleAutocompleteChange}
								options={procedures}
								noFormat
							/>
							<Autocomplete
								name="twoProcedureId"
								label="Proc2"
								value={values.twoProcedureId}
								onChange={handleAutocompleteChange}
								options={procedures}
								noFormat
							/>
							<Autocomplete
								name="threeProcedureId"
								label="Proc3"
								value={values.threeProcedureId}
								onChange={handleAutocompleteChange}
								options={procedures}
								noFormat
							/>
							<Autocomplete
								name="fourProcedureId"
								label="Proc4"
								value={values.fourProcedureId}
								onChange={handleAutocompleteChange}
								options={procedures}
								noFormat
							/>
							<Autocomplete
								name="fiveProcedureId"
								label="Proc5"
								value={values.fiveProcedureId}
								onChange={handleAutocompleteChange}
								options={procedures}
								noFormat
							/>
							<br />
							<Link href="#" onClick={handleBeforeImage}>
						    Adjuntar o ver imágenes antes de cirugía.
						  </Link>
						</Paper>
					</Grid>
					<Grid item md={6} xs={12}>
						<Paper className={classes.formContainer}>
							<Typography variant="h5">
				        Seguimiento
				      </Typography>
				      <Grid container alignItems='flex-end'>
				      	<Grid item xs={11}>
						      <DateField
										name="statusDate"
										label="Fecha de Seguimiento"
										value={
											new Date(values.statusDate) <= new Date('2040/01/01') ? values.statusDate : null
										}
										onChange={handleDateChange}
										noFormat
									/>
								</Grid>
				      	<Grid item xs={1} align='right'>
				      		<Link href='#' onClick={handleDeleteStatusDate}><DeleteOutlineIcon /></Link>
				      	</Grid>
				      </Grid>
							<TextField
								name="statusCommentary"
								label="Comentarios del Seguimiento"
								value={values.statusCommentary}
								onChange={handleChange}
								noFormat
							/>
				      <br/>
				      <Typography variant="h5">
				        Citas
				      </Typography>
				      <DateTimeField
								name="firstDate"
								label="Primera Cita"
								value={values.firstDate}
								onChange={handleDateTimeChange}
								noFormat
							/>
							<DateTimeField
								name="preSurgeryDate"
								label="Pre Cirugía"
								value={values.preSurgeryDate}
								onChange={handleDateTimeChange}
								noFormat
							/>
              <Grid container alignItems='flex-end'>
                <Grid item xs={11}>
    							<DateTimeField
    								name="surgeryDate"
    								label="Cirugía"
    								value={
                      new Date(values.surgeryDate) <= new Date('2040/01/01') ? values.surgeryDate : null
                    }
    								onChange={handleDateTimeChange}
    								noFormat
    							/>
                </Grid>
                <Grid item xs={1} align='right'>
                  <Link href='#' onClick={handleDeleteSurgeryDate}><DeleteOutlineIcon /></Link>
                </Grid>
              </Grid>
							<DateTimeField
								name="postSurgeryDate"
								label="Post Cirugía"
								value={values.postSurgeryDate}
								onChange={handleDateTimeChange}
								noFormat
							/>
				    </Paper>
				  </Grid>
				  <Grid item md={6} xs={12}>
						<Paper className={classes.formContainer}>
							<Typography variant="h5" gutterBottom>
				        Resultados
				      </Typography>
				      <Grid container alignItems='flex-end'>
				      	<Grid item xs={10}>
				      		<SelectField
										name="chemicals"
										label="Químicos"
										value={values.chemicals}
										onChange={handleChange}
										options={yesNoOptions}
										disabled={isAdmin ? false : true}
										noFormat
									/>
				      	</Grid>
				      	<Grid item xs={1} align='right'>
				      		<Link href='#' onClick={handleChemicalPdf}><PictureAsPdfIcon /></Link>
				      	</Grid>
				      	<Grid item xs={1} align='right'>
				      		<Link href='#' onClick={handleChemicalImage}><CameraAltIcon /></Link>
				      	</Grid>
				      </Grid>
				      <Grid container alignItems='flex-end'>
				      	<Grid item xs={10}>
				      		<SelectField
										name="anesthesia"
										label="Anestesiólogo"
										value={values.anesthesia}
										onChange={handleChange}
										options={yesNoOptions}
										disabled={isAdmin ? false : true}
										noFormat
									/>
				      	</Grid>
				      	<Grid item xs={1} align='right'>
				      		<Link href='#' onClick={handleAnesthesiaPdf}><PictureAsPdfIcon /></Link>
				      	</Grid>
				      	<Grid item xs={1} align='right'>
				      		<Link href='#' onClick={handleAnesthesiaImage}><CameraAltIcon /></Link>
				      	</Grid>
				      </Grid>
				      <Grid container alignItems='flex-end'>
				      	<Grid item xs={10}>
				      		<SelectField
										name="intensive"
										label="Intensivista"
										value={values.intensive}
										onChange={handleChange}
										options={yesNoOptions}
										disabled={isAdmin ? false : true}
										noFormat
									/>
				      	</Grid>
				      	<Grid item xs={1} align='right'>
				      		<Link href='#' onClick={handleIntensivePdf}><PictureAsPdfIcon /></Link>
				      	</Grid>
				      	<Grid item xs={1} align='right'>
				      		<Link href='#' onClick={handleIntensiveImage}><CameraAltIcon /></Link>
				      	</Grid>
				      </Grid>
							<Grid container alignItems='flex-end'>
				      	<Grid item xs={10}>
									<SelectField
										name="doctor"
										label="Valoración de Doctor"
										value={values.doctor}
										onChange={handleChange}
										options={yesNoOptions}
										disabled={isAdmin ? false : true}
										noFormat
									/>
								</Grid>
				      	<Grid item xs={1} align='right'>
				      		<Link href='#' onClick={handleDoctorPdf}><PictureAsPdfIcon /></Link>
				      	</Grid>
				      	<Grid item xs={1} align='right'>
				      		<Link href='#' onClick={handleDoctorImage}><CameraAltIcon /></Link>
				      	</Grid>
				      </Grid>
							<TextField
								name='doctorCommentary'
								label='Comentarios del Doctor'
								onChange={handleChange}
								value={values.doctorCommentary}
								disabled={isAdmin ? false : true}
								noFormat
							/>
						</Paper>
				  </Grid>
				  <Grid item md={6} xs={12}>
						<Paper className={classes.formContainer}>
							<Typography variant="h5" gutterBottom>
				        Confirmación de la Cirugía
				      </Typography>
				      <SelectField
								name="feedback"
								label="¿La cirugía fue confirmada?"
								value={values.feedback}
								onChange={handleChange}
								options={yesNoOptions}
								disabled={isAdmin ? false : true}
								noFormat
							/>
							<DateField
								name="feedbackDate"
								label="Fecha de Confirmación"
								value={values.feedbackDate}
								onChange={handleDateChange}
								disabled={isAdmin ? false : true}
								noFormat
							/>
							<TextField
								name="feedbackCommentary"
								label="Comentarios de la Confirmación"
								value={values.feedbackCommentary}
								disabled={isAdmin ? false : true}
								onChange={handleChange}
								noFormat
							/>
							<br/>
							<Typography variant="h5" gutterBottom>
				        Marketing
				      </Typography>
							<SelectField
								name="catchment"
								label="Método de Captación"
								value={values.catchment}
								onChange={handleChange}
								options={catchmentOptions}
								noFormat
							/>
				    </Paper>
				  </Grid>
				  <Grid item md={6} xs={12}>
						<Paper className={classes.formContainer}>
							<Typography variant="h5" gutterBottom>
				        Estado de Cuenta
				      </Typography>
				      <TextField
								name="total"
								label="Presupuesto MXN"
								value={values.total}
								onChange={handleChange}
								type='number'
								noFormat
							/>
							<Grid container alignItems='flex-end'>
				      	<Grid item xs={11}>
									<TextField
										name="payment"
										label="Abono MXN"
										value={values.payment}
										onChange={handleChange}
										type='number'
										noFormat
									/>
								</Grid>
				      	<Grid item xs={1} align='right'>
				      		<Link href='#' onClick={handlePaymentImage}><CameraAltIcon /></Link>
				      	</Grid>
				      </Grid>
				      <TextField
				      	name='balance'
								label="Saldo MXN"
								value={values.balance}
								type='number'
								onChange={handleChange}
								noFormat
								disabled
							/>
							<TextField
								name="totalDll"
								label="Presupuesto DLL"
								value={values.totalDll}
								onChange={handleChange}
								type='number'
								noFormat
							/>
							<Grid container alignItems='flex-end'>
				      	<Grid item xs={11}>
									<TextField
										name="paymentDll"
										label="Abono DLL"
										value={values.paymentDll}
										onChange={handleChange}
										type='number'
										noFormat
									/>
								</Grid>
				      	<Grid item xs={1} align='right'>
				      		<Link href='#' onClick={handlePaymentImage}><CameraAltIcon /></Link>
				      	</Grid>
				      </Grid>
							<SelectField
								name="paid"
								label="Liquidación"
								value={values.paid}
								onChange={handleChange}
								options={yesNoOptions}
								disabled={isAdmin ? false : true}
								noFormat
							/>
              <Grid container spacing={1} alignItems='flex-end'>
                <Grid item xs={6}>
    							<TextField
    								name="commision"
    								label="Comisión"
    								value={values.commision}
    								onChange={handleChange}
    								disabled={isAdmin ? false : true}
    								type='number'
    								noFormat
    							/>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="passwordCommision"
                    label="Contraseña de Comisión"
                    value={values.passwordCommision}
                    onChange={handleChange}
                    disabled={isAdmin ? false : true}
                    type='password'
                    noFormat
                  />
                </Grid>
              </Grid>
				    </Paper>
				  </Grid>
				</Grid>
			</Collapse>
			</div>
			<div>
				<Collapse in={imageDialog[0]}>
					{
						imageDialog[2] 
							?	<div>
									<Grid container align='center' alignItems='center' justify='center' alignContent='center'>
										<Grid item xs={12}>
											<input
			  				        accept='application/pdf'
			  				        className={classes.input}
			  				        id='contained-button-file'
			  				        onChange={onSelectFile}
			  				        type='file'
			  				      />
			  				      <label htmlFor='contained-button-file'>
			  				        <ButtonMUI 
			  				        	variant='outlined'
			  				        	color='default' 
			  				        	component='span'
			  				        	disableElevation
			  				        	className={classes.uploadButton}
			  				        >
			  				          Selecciona un documento de tu ordenador
			  				        </ButtonMUI>
			  				      </label>
										</Grid>
										{ files.map(el => 
												<Grid key={el.id} item md={3} xs={12}>
													<Badge
							              overlap="circle"
							              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
							              badgeContent={
							              	<IconButton  
							                  params={el.id} 
							                  action={handleShowFile} 
							                  className={classes.badgeButton}
							                  icon={<SearchIcon />}
							                /> 
								            }
								          >
														<Badge
								              overlap="circle"
								              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
								              badgeContent={
							                  <IconButton  
								                  params={el.id} 
								                  action={handleDeleteFile} 
								                  className={classes.lastBadgeButton}
								                  icon={<TrashIcon />}
								                />      
									            }
									          >
								              <img
								                src={`${config["api-file-service"].url}/files/${el.id}`} alt={`IMG-${el.id}`}
								                className={classes.image}
								              />
									          </Badge>
								          </Badge>
							          </Grid>
											) 
										}
										<Grid item xs={12}>
											<Link href="#" onClick={handleBack}>
										    Volver a la ventana anterior
										  </Link>
										</Grid>
									</Grid>
								</div>
							: <ImageField 
									croppedImage={handleImage} 
									isUpload={isUpload} 
									resetImage={handleResetImage}
									aspect={9/14}
									width={100}
									noHeight
								>
									<Grid container align='center' alignItems='center' justify='center' alignContent='center'>
										{ files.map(el => 
												<Grid key={el.id} item md={3} xs={12}>
													<Badge
							              overlap="circle"
							              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
							              badgeContent={
							              	<IconButton  
							                  params={el.id} 
							                  action={handleShowFile} 
							                  className={classes.badgeButton}
							                  icon={<SearchIcon />}
							                /> 
								            }
								          >
														<Badge
								              overlap="circle"
								              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
								              badgeContent={
							                  <IconButton  
								                  params={el.id} 
								                  action={handleDeleteFile} 
								                  className={classes.lastBadgeButton}
								                  icon={<TrashIcon />}
								                />      
									            }
									          >
								              <img
								                src={`${config["api-file-service"].url}/files/${el.id}`} alt={`IMG-${el.id}`}
								                className={classes.image}
								              />
									          </Badge>
								          </Badge>
							          </Grid>
											) 
										}
									</Grid>
									<Link href="#" onClick={handleBack}>
								    Volver a la ventana anterior
								  </Link>
								</ImageField>
					}
					
				</Collapse>
			</div>
		</FormDialog>
	);
};

// function getFileNameWithExt(event) {
//   if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
//     return;
//   }
//   const name = event.target.files[0].name;
//   const lastDot = name.lastIndexOf('.');
//   const fileName = name.substring(0, lastDot);
//   const ext = name.substring(lastDot + 1);
//   return [fileName, ext];
// }

export default PersonalDataDialog;