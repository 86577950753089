const companies = localStorage.getItem('companies') 
  ? JSON.parse(localStorage.getItem('companies')) 
  : [];

export const ivaTax = companies.length > 0 
  ? parseFloat(companies[0].iva) / 100
  : 0.16;

export const addressFormat = address => {
  
  const addressLine1 = address.addressLine1 ? `${address.addressLine1}, ` : "";
  const addressLine2 = address.addressLine2 ? `${address.addressLine2}, ` : "";
  const city = address.city ? `${address.city}, ` : "";
  const State = address.State ? `${address.State.name}, ` : "";
  const zipCode = address.zipCode ? `CP ${address.zipCode}` : "";

  return `${addressLine1}${addressLine2}${city}${State}${zipCode}`.trim();

};

export const currencyFormat = (num, simbol = true) => (simbol ? '$' : '') + (Number(num).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));

export const phoneFormat = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
  return phoneNumberString
}

export const capitalize = string => {
  if (typeof string !== 'string') return ''
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}


export const queryFormat = values => {
  let query = "";
  Object.keys(values).forEach(key => {
    query += `${key}=${values[key]}&`;
  });
  return query.slice(0, -1);;
}

export const parseError = e => {
  if(e.message.includes('Network error')){ 
    return [{ name: 'NETWORK_ERROR', message: 'El servidor no responde, intente más tarde.' }];
  }
  const parseErrors = e.graphQLErrors.map(({ extensions }) => {
    if(extensions.code === 'BAD_USER_INPUT'){
      const errors = extensions.fields
        .reduce((object, el) => (
          { ...object, [el.name]: el.message }
        ), {});
      return { name: extensions.code, message: errors };
    }
    return null;
  }).filter(el => el);
  return parseErrors;
}

export function getModes(arr) {
    var numMapping = {};
    var greatestFreq = 0;
    var mode;
    arr.forEach(function findMode(number) {
        numMapping[number] = (numMapping[number] || 0) + 1;

        if (greatestFreq < numMapping[number]) {
            greatestFreq = numMapping[number];
            mode = number;
        }
    });
    return !isNaN(+mode) ? +mode : 0;
}

export const newDate = (date) => {
  const dt = new Date(date);
  return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
}