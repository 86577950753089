import { gql } from '@apollo/client';

/*
* START OF CART CODE
*/

export const GET_CART = gql`
  query GetCart {
    cart @client {
      customer
      newCustomer
      commentary
      SaleProductData
    }
  }
`;

export const GET_CUSTOMER_CART = gql`
  query GetCustomerCart {
    cart @client {
      customer
      newCustomer
    }
  }
`;

export const GET_COMMENTARY_CART = gql`
  query GetCommentaryCart {
    cart @client {
      commentary
    }
  }
`;

export const GET_ITEMS_CART = gql`
  query GetItemsCart {
    cart @client {
      priceName
      SaleProductData
    }
  }
`;

export const CLEAR_CART = gql`
  mutation ClearCart {
    clearCart @client
  }
`;

export const HANDLE_CUSTOMER_CART = gql`
  mutation HandleCustomerCart(
    $customer: String
    $priceName: String
    $newCustomer: String
  ) {
    handleCustomerCart(
      customer: $customer
      priceName: $priceName
      newCustomer: $newCustomer
    ) @client
  }
`;

export const HANDLE_COMMENTARY_CART = gql`
  mutation HandleCommentaryCart(
    $commentary: String!
  ) {
    handleCommentaryCart(
      commentary: $commentary
    ) @client
  }
`;

export const HANDLE_ITEMS_CART = gql`
  mutation HandleItemsCart(
    $item: String!
    $action: Boolean
  ) {
    handleItemsCart(
      item: $item
      action: $action
    ) @client
  }
`;

/*
* END OF CART CODE
*/

export const GET_FORM = gql`
  query GetForm {
    form @client
  }
`;

export const GET_DELETE_FORM = gql`
  query GetDeleteForm {
    deleteForm @client {
      isOpen,
      params
    }
  }
`;

export const GET_SNACKBAR = gql`
  query GetSnackbar {
    snackbar @client {
      isOpen,
      label,
      severity,
      time
    }
  }
`;

export const GET_ACTION_FORM = gql`
  query GetActionForm {
    actionForm @client {
    	isOpen,
    	action,
    	defaultData
    }
  }
`;



export const HANDLE_FORM = gql`
  mutation HandleForm(
    $form: String!
  ) {
    handleForm(
      form: $form
    ) @client
  }
`;

export const HANDLE_DELETE_FORM = gql`
  mutation HandleDeleteForm(
    $isOpen: Boolean!, 
    $params: String!
  ) {
    handleDeleteForm(
      isOpen: $isOpen, 
      params: $params,
    ) @client
  }
`;

export const HANDLE_SNACKBAR = gql`
  mutation HandleSnackbar(
    $isOpen: Boolean!, 
    $label: String!,
    $severity: String,
    $time: Int
  ) {
    handleSnackbar(
      isOpen: $isOpen, 
      label: $label,
      severity: $severity,
      time: $time
    ) @client
  }
`;

export const HANDLE_ACTION_FORM = gql`
  mutation HandleActionForm(
  	$isOpen: Boolean!, 
  	$action: String,
  	$defaultData: String
  ) {
    handleActionForm(
    	isOpen: $isOpen, 
    	action: $action,
    	defaultData: $defaultData
    ) @client
  }
`;

const typeDefs = gql`
  
  extend type Query {
    form: String!
  }

  extend type Mutation {
    
    clearCart: Boolean

    handleItemsCart(
      item: String!
      action: Boolean
    ): Boolean

    handleCustomerCart(
      customer: String!
      priceName: String
      newCustomer: String
    ): Boolean

    handleCommentaryCart(
      commentary: String!
    ): Boolean

    handleForm(
      form: String!
    ): Boolean

    handleDeleteForm(
      isOpen: Boolean!, 
      params: String!
    ): Boolean

    handleSnackbar(
      isOpen: Boolean!, 
      label: String,
      severity: String,
      time: Int
    ): Boolean
  
    handleActionForm(
    	isOpen: Boolean!, 
    	action: String,
    	defaultData: String
    ): Boolean

  }
  
`;

export default typeDefs;