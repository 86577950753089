import { gql } from '@apollo/client';

const CREATE = gql`
  mutation CreateCrm(
    $name: String!
    $age: String
    $phone: String
    $place: String
    $nationality: Int
		$oneProcedureId: ID
		$twoProcedureId: ID
		$threeProcedureId: ID
		$fourProcedureId: ID
		$fiveProcedureId: ID
		$catchment: Int!
		$surgeryDoctor: Int
  ) {
    createCrm(input: {
      name: $name
      age: $age
      phone: $phone
      place: $place
      nationality: $nationality
			oneProcedureId: $oneProcedureId
			twoProcedureId: $twoProcedureId
			threeProcedureId: $threeProcedureId
			fourProcedureId: $fourProcedureId
			fiveProcedureId: $fiveProcedureId
			catchment: $catchment
			surgeryDoctor: $surgeryDoctor
    }) {
      id
			status
			subStatus
			statusCommentary
			statusDate
			firstDate
			preSurgeryDate
			surgeryDate
			surgeryDoctor
			postSurgeryDate
			isFirstDateOnline
			warningMessage
			effectivity
			OneProcedure {
				id
				prefix
			}
			TwoProcedure {
				id
				prefix
			}
			FourProcedure {
				id
				prefix
			}
			FiveProcedure {
				id
				prefix
			}
			ThreeProcedure {
				id
				prefix
			}
			Customer {
				id
				name
				age
				phone
				place
			}
			User {
				name
			}
			createdAt
			chemicals
			anesthesia
			intensive
			doctor
			feedback
			feedbackDate
			cancelDate
			xopDate
			feedbackCommentary
			historyClinic
			historyClinicCommentary
			total
			payment
			balance
			paid
			commision
			background
			pictures
			picturesBackground
			statusDateBackground
			totalBackground
			feedbackDateBackground
			surgeryDateBackground
			catchment
    }
  }
`;

const GET = gql`
  query Crms($userId: ID $status: [Int] $subStatus: Int $start: Date $end: Date $showByStatusDate: Boolean $generalStatus: Int $limit: Int $offset: Int $customer: String $orderBy: Int $surgeryDoctor: Int){
    crms(userId: $userId status: $status subStatus: $subStatus start: $start end: $end showByStatusDate: $showByStatusDate generalStatus: $generalStatus limit: $limit offset: $offset customer: $customer orderBy: $orderBy surgeryDoctor: $surgeryDoctor) {
    	count
    	rows {
    		id
				status
				subStatus
				statusCommentary
				statusDate
				firstDate
				preSurgeryDate
				surgeryDoctor
				surgeryDate
				postSurgeryDate
				isFirstDateOnline
				warningMessage
				effectivity
				OneProcedure {
					id
					prefix
				}
				TwoProcedure {
					id
					prefix
				}
				ThreeProcedure {
					id
					prefix
				}
				FourProcedure {
					id
					prefix
				}
				FiveProcedure {
					id
					prefix
				}
				Customer {
					id
					name
					age
					phone
					place
				}
				User {
					name
				}
				createdAt
				chemicals
				anesthesia
				intensive
				historyClinic
				historyClinicCommentary
				doctor
				doctorCommentary
				feedback
				cancelDate
				xopDate
				feedbackDate
				feedbackCommentary
				total
				payment
				balance
				paid
				commision
				background
				pictures
				picturesBackground
				statusDateBackground
				totalBackground
				feedbackDateBackground
				surgeryDateBackground
				HistoryClinic { id }
				Payment { id }
				Before { id }
				Doctor { id }
				Chemicals { id }
				Anesthesia { id }
				Intensive { id }
				catchment
				tpc
				tcx
				ttx
    	}
    }
  }
`;

const GET_BY_ID = gql`
  query Crm($id: ID!){
    crm(id: $id) {
      id
			status
			subStatus
			statusCommentary
			statusDate
			firstDate
			preSurgeryDate
			surgeryDate
			postSurgeryDate
			isFirstDateOnline
			OneProcedure {
				id
				prefix
			}
			TwoProcedure {
				id
				prefix
			}
			ThreeProcedure {
				id
				prefix
			}
			FourProcedure {
				id
				prefix
			}
			FiveProcedure {
				id
				prefix
			}
			Customer {
				id
				name
				age
				phone
				place
			}
			User {
				name
			}
			statusCommentary
			createdAt
			chemicals
			anesthesia
			intensive
			doctor
			surgeryDoctor
			historyClinic
			historyClinicCommentary
			doctorCommentary
			feedback
			feedbackDate
			cancelDate
			xopDate
			feedbackCommentary
			total
			payment
			totalDll
			paymentDll
			balance
			paid
			commision
			catchment
			HistoryClinic { id }
			Payment { id }
			Before { id }
			Doctor { id }
			Chemicals { id }
			Anesthesia { id }
			Intensive { id }
    }
  }
`;

const DELETE = gql`
  mutation DeleteCrm($id: ID!) {
    deleteCrm(id: $id) {
      id
    }
  }
`;

const UPDATE = gql`
  mutation UpdateCrm(
    $id: ID!
    $status: Int
		$subStatus: Int
		$statusCommentary: String
		$statusDate: Date
		$firstDate: Date
		$preSurgeryDate: Date
		$surgeryDate: Date
		$postSurgeryDate: Date
		$isFirstDateOnline: Boolean
		$oneProcedureId: ID
		$twoProcedureId: ID
		$threeProcedureId: ID
		$fourProcedureId: ID
		$fiveProcedureId: ID
		$name: String
		$age: String
		$phone: String
		$place: String
		$nationality: Int
		$catchment: Int
		$chemicals: Boolean
		$anesthesia: Boolean
		$intensive: Boolean
		$doctor: Boolean
		$applyPostSurgeries: Boolean
		$doctorCommentary: String
		$feedback: Boolean
		$feedbackDate: Date
		$cancelDate: Date
		$xopDate: Date
		$feedbackCommentary: String
		$total: Float
		$payment: Float
		$totalDll: Float
		$paymentDll: Float
		$paid: Boolean
		$commision: Float
		$passwordCommision: String
		$historyClinic: String
		$historyClinicCommentary: String
		$surgeryDoctor: Int
  ) {
    updateCrm(
      id: $id, 
      input: {
	    	status: $status
				subStatus: $subStatus
				statusCommentary: $statusCommentary
				statusDate: $statusDate
				firstDate: $firstDate
				preSurgeryDate: $preSurgeryDate
				surgeryDate: $surgeryDate
				postSurgeryDate: $postSurgeryDate
				isFirstDateOnline: $isFirstDateOnline
				oneProcedureId: $oneProcedureId
				twoProcedureId: $twoProcedureId
				threeProcedureId: $threeProcedureId
				fourProcedureId: $fourProcedureId
				fiveProcedureId: $fiveProcedureId
				name: $name
				age: $age
				phone: $phone
				place: $place
				nationality: $nationality
				catchment: $catchment
				chemicals: $chemicals
				anesthesia: $anesthesia
				surgeryDoctor: $surgeryDoctor
				intensive: $intensive
				doctor: $doctor
				doctorCommentary: $doctorCommentary
				applyPostSurgeries: $applyPostSurgeries
				feedback: $feedback
				feedbackDate: $feedbackDate
				cancelDate: $cancelDate
				xopDate: $xopDate
				feedbackCommentary: $feedbackCommentary
				total: $total
				payment: $payment
				totalDll: $totalDll
				paymentDll: $paymentDll
				paid: $paid
				commision: $commision
				passwordCommision: $passwordCommision
				historyClinic: $historyClinic
				historyClinicCommentary: $historyClinicCommentary
      }
    ) {
      id
			status
			subStatus
			statusCommentary
			statusDate
			firstDate
			preSurgeryDate
			surgeryDate
			postSurgeryDate
			surgeryDoctor
			isFirstDateOnline
			warningMessage
			effectivity
			OneProcedure {
				id
				prefix
			}
			TwoProcedure {
				id
				prefix
			}
			ThreeProcedure {
				id
				prefix
			}
			FourProcedure {
				id
				prefix
			}
			FiveProcedure {
				id
				prefix
			}
			Customer {
				id
				name
				age
				phone
				place
			}
			User {
				name
			}
			createdAt
			chemicals
			anesthesia
			intensive
			historyClinic
			historyClinicCommentary
			doctor
			doctorCommentary
			applyPostSurgeries
			feedback
			feedbackDate
			cancelDate
			xopDate
			feedbackCommentary
			total
			payment
			totalDll
			paymentDll
			balance
			paid
			commision
			background
			pictures
			picturesBackground
			statusDateBackground
			totalBackground
			feedbackDateBackground
			surgeryDateBackground
			catchment
    }
  }
`

const UPLOAD_FILE = gql`
	mutation UploadFiles($id: ID!, $type: String!, $file: Upload!) {
		updateFiles(id: $id type: $type file: $file){
			id
		}
	}
`;

const DELETE_FILE = gql`
  mutation DeleteFiles($id: ID!, $type: String!, $fileId: ID!) {
    deleteFiles(id: $id, type: $type, fileId: $fileId){
      id
    }
  }
`;

const object = { CREATE, GET, GET_BY_ID, DELETE, UPDATE, UPLOAD_FILE, DELETE_FILE };
export default object;