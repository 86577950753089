import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';

import FormDialog from './../../../presentationals/FormDialog';
import TextField from './../../../presentationals/TextField';

import GQL from './../_gql';

const defaultValues = {
	cost1: 0,
	cost2: 0,
	cost3: 0,
	cost4: 0,
	cost5: 0,
	cost6: 0,
	cost7: 0,
	cost8: 0,
	cost9: 0,
	cost10: 0,
	cost11: 0,
	cost12: 0,
	cost13: 0,
	cost14: 0,
	cost15: 0
}

const ExpenseDialog = props => {

	const [ values, setValues ] = useState(defaultValues);

	const [ updateExpense ] = useMutation(GQL.UPDATE_EXPENSE);

	useEffect(() => {
		if(props.expense){
			const tmpExpense = Object.keys(props.expense).reduce((array, key) => {
				const value = isNaN(parseFloat(props.expense[key])) ? 0 : parseFloat(props.expense[key]);
				return { ...array, [key]: value };
			}, defaultValues);
			// console.log(tmpExpense)
			setValues(tmpExpense)
		}
	}, [props.expense])

	const handleClose = () => {
		props.handleClose();
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = async () => {
  	try{
  		const variables = Object.keys(values).reduce((array, key) => {
  			return { ...array, [key]: parseFloat(values[key]) }
  		}, {});
  		await updateExpense({ variables });
  		handleClose();
  	}catch(e){
  		console.log(e);
  	}
  }

	return (
		<FormDialog
			isOpen={props.isOpen}
			isLoading={false}
			title='Gastos Fijos'
			width='xs'
      handleClose={handleClose}
      handleAction={handleAction}
		>
			<TextField
				label='RENTA'
				name='cost1'
				value={values.cost1}
				onChange={handleChange}
			/>
			<TextField
				label='CONTADOR'
				name='cost2'
				type='number'
				value={values.cost2}
				onChange={handleChange}
			/>
			<TextField
				label='DR. OMAR'
				name='cost3'
				type='number'
				value={values.cost3}
				onChange={handleChange}
			/>
			<TextField
				label='MERCADOTECNIA'
				name='cost4'
				type='number'
				value={values.cost4}
				onChange={handleChange}
			/>
			<TextField
				label='JESUS'
				name='cost5'
				type='number'
				value={values.cost5}
				onChange={handleChange}
			/>
			<TextField
				label='CLARISSA'
				name='cost6'
				type='number'
				value={values.cost6}
				onChange={handleChange}
			/>
			<TextField
				label='COORDINADORA HOSPITAL'
				name='cost7'
				type='number'
				value={values.cost7}
				onChange={handleChange}
			/>
			<TextField
				label='LILIANA'
				name='cost8'
				type='number'
				value={values.cost8}
				onChange={handleChange}
			/>
			<TextField
				label='LEDEZMA'
				name='cost9'
				type='number'
				value={values.cost9}
				onChange={handleChange}
			/>
			<TextField
				label='ENFERMERA NOCTURNA'
				name='cost10'
				type='number'
				value={values.cost10}
				onChange={handleChange}
			/>
			<TextField
				label='PRESTACIONES DE PERSONAL'
				name='cost11'
				type='number'
				value={values.cost11}
				onChange={handleChange}
			/>
			<TextField
				label='VARIOS OFICINA LIMPIEZA'
				name='cost12'
				type='number'
				value={values.cost12}
				onChange={handleChange}
			/>
			<TextField
				label='VIATICOS PERSONAL DE APOYO'
				name='cost13'
				type='number'
				value={values.cost13}
				onChange={handleChange}
			/>
			<TextField
				label='ENFERMERA CIRCULANTE'
				name='cost14'
				type='number'
				value={values.cost14}
				onChange={handleChange}
			/>
			<TextField
				label='OTROS'
				name='cost15'
				type='number'
				value={values.cost15}
				onChange={handleChange}
			/>
		</FormDialog>
	);
}

export default ExpenseDialog;