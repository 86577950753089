import React, { useState, Fragment } from 'react';

import { useQuery, useMutation } from '@apollo/client';

import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/AddOutlined';
import HistoryIcon from '@material-ui/icons/HistoryOutlined';
import CameraAltIcon from '@material-ui/icons/CameraAltOutlined';
import CommentIcon from '@material-ui/icons/CommentOutlined';
import LocalAtmIcon from '@material-ui/icons/LocalAtmOutlined';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroidOutlined';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Module from './../../presentationals/Module';
import FilterDrawer from './../../presentationals/FilterDrawer';
import SelectField from './../../presentationals/SelectField';

import GQL_CRM from './../crm/_gql';
import GQL from './_gql';
import CreateDialog from './create.dialog';
import UpdateDialog from './update.dialog';
import HistoryDialog from './history.dialog';
import CreateCommentaryDialog from './createCommentary.dialog';
import PictureDialog from './picture.dialog';
import CommissionDialog from './commision.dialog';

import useStyles from './style';
import { catchmentOptions } from '../crm';

const _privileges = parseInt(localStorage.getItem('privileges'));

const expiredOptions = [
	{ value: false, label: 'TODOS' },
	{ value: true, label: 'PENDIENTES' }
];

const nationalityOptions = [
	{ value: 1, label: 'Mexicana' },
	{ value: 2, label: 'Extranjera' }
];

const Rows = [
	{ label: 'Nombre', key: 'Customer name', sort: 'text', filter: true },
	{ label: 'Edad', key: 'Customer age', sort: 'number' },
	{ label: 'Teléfono', key: 'Customer phone', sort: 'text' },
	{ label: 'Cirugía', key: 'surgeryDate', sort: 'date', format: 'date' },
	{ label: 'Procedimiento', key: 'OneProcedure prefix', sort: 'text' },
	{ label: 'Cita', key: 'PostSurgery date', sort: 'date', format: 'dateTime' },
	{ label: 'Estatus', key: 'PostSurgery status', sort: 'text', format: 'postSurgeryStatus' },
	{ label: '#Cita', key: 'PostSurgeryCount', sort: 'number', align: 'center' },
	{ label: 'Comentario', key: 'postSurgeryCommentary', sort: 'text' },
	{ label: 'Comisión', key: 'postSurgeryCommission', format: 'postSurgeryCommission' },
];

const Component = props => {

	const classes = useStyles();

	const [ isOpen, setIsOpen ] = useState(false);
	const [ isOpenImageDialog, setIsOpenImageDialog ] = useState(false);
	const [ isOpenCreateDialog, setIsOpenCreateDialog ] = useState(false);
	const [ isOpenUpdateDialog, setIsOpenUpdateDialog ] = useState(false);
	const [ isOpenHistoryDialog, setIsOpenHistoryDialog ] = useState(false);
	const [ isOpenCommissionDialog, setIsOpenCommissionDialog ] = useState(false);
	const [ isOpenCreateCommentaryDialog, setIsOpenCreateCommentaryDialog ] = useState(false);
	const [ datas, setDatas ] = useState({});
	const [ images, setImages ] = useState([null, []]);
	const [ createCommentaryId, setCreateCommentaryId ] = useState(null);
	const [ expired, setExpired ] = useState(false);

	const { data, loading } = useQuery(GQL.GET, { variables: { expired } });

	const [ updateCrm ] = useMutation(GQL_CRM.UPDATE);

	const handleApply = async (e, params, apply) => {
		try{
			// const { id, applyPostSurgeries } = params;
			// await updateCrm({ variables: { id, applyPostSurgeries: !applyPostSurgeries } });
			const { id } = params;
			await updateCrm({ variables: { id, applyPostSurgeries: apply } });
		}catch(e){
			console.log(e.message)
		}
	}

	const handleEdit = (e, params) => {
		setDatas({ id: params.id, PostSurgery: params.PostSurgery });
		setIsOpenUpdateDialog(true);
	}
	
	const handleCreate = (e, params) => {
		setDatas({ id: params.id });
		setIsOpenCreateDialog(true);
	}

	const handleHistory = (e, params) => {
		setDatas({ crmId: params.id });
		setIsOpenHistoryDialog(true);
	}

	const handleCommission = (e, params) => {
		setDatas({ crmId: params.id, commission: params.postSurgeryCommission });
		setIsOpenCommissionDialog(true);
	}

	const handleOpenCreateCommentaryDialog = (e, params) => {
		setCreateCommentaryId(params.id);
		setIsOpenCreateCommentaryDialog(true);
	}

	const handleCloseCreateDialog = () => {
		setIsOpenCreateDialog(false);
	}

	const handleCloseUpdateDialog = () => {
		setIsOpenUpdateDialog(false);
	}

	const handleCloseHistoryDialog = () => {
		setIsOpenHistoryDialog(false);
	}

	const handleCloseCommissionDialog = () => {
		setIsOpenCommissionDialog(false);
	}

	const handleCloseCreateCommentaryDialog = () => {
		setIsOpenCreateCommentaryDialog(false);
	}

	const handleImage = (e, params) => {
		setImages([params.id, params.After]);
		setIsOpenImageDialog(true);
	}

	const handleCloseImageDialog = () => {
		setIsOpenImageDialog(false);
	}

	const handleChange = e => {
    const value = e.target.value;
    setExpired(value);
  }

	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);
	
	return (
		[1,3].includes(_privileges) ?
			<Fragment>
				<Module
					isLoading={loading}
					name='Citas post cirugía'
					singular='Paciente'
					operation={['postSurgery', 'postSurgeries']}
					filter={handleOpen}
					Rows={Rows}
					get={GQL.GET}
					getById={GQL.GET_BY_ID}
					create={GQL.CREATE_CRM}
					noDelete
					noEdit
					cacheVariables={{ expired }}
					records={data && data.postSurgeries}
					actions={[
						{ 
							icon: <LockIcon/>, 
							label: 'No Aplica', 
							onClick: (e,params) => handleApply(e,params,true),
							hide: { key: 'applyPostSurgeries', value: ['true', 'null'] }
						},
						{ 
							icon: <LockOpenIcon/>, 
							label: 'Si Aplica', 
							onClick: (e,params) => handleApply(e,params,false),
							hide: { key: 'applyPostSurgeries', value: ['false'] }
						},
						{ 
							icon: <EditIcon/>, 
							label: 'Estatus de Cita', 
							onClick: handleEdit,
							disable: { key: 'PostSurgery', value: 'null' },
							hide: { key: 'applyPostSurgeries', value: ['true', 'null'] }
						},
						{ 
							icon: <AddIcon/>, 
							label: 'Crear Cita', 
							onClick: handleCreate,
							disable: { key: 'PostSurgery', valueIsNot: 'null' },
							hide: { key: 'applyPostSurgeries', value: ['true', 'null'] }
						},
						{ 
							icon: <CameraAltIcon/>, 
							label: 'Fotografía', 
							onClick: handleImage,
							hide: { key: 'applyPostSurgeries', value: ['true', 'null'] }
						},
						{ 
							icon: <HistoryIcon/>, 
							label: 'Historial de Citas', 
							onClick: handleHistory,
							hide: { key: 'applyPostSurgeries', value: ['true', 'null'] }
						},{ 
							icon: <CommentIcon />, 
							label: 'Agregar Comentario', 
							onClick: handleOpenCreateCommentaryDialog,
							hide: { key: 'applyPostSurgeries', value: ['true', 'null'] }
						},{ 
							icon: <LocalAtmIcon />, 
							label: 'Comisión', 
							onClick: handleCommission,
							hide: { key: 'applyPostSurgeries', value: ['true', 'null'] }
						}
					]}
					fields={[
						{ 
							icon: <AccountCircleIcon />,
							name: 'name', 
							label: 'Nombre',
							autoFocus: true
						},
						{ 
							name: 'age', 
							label: 'Edad'
						},
						{ 
							icon: <PhoneAndroidIcon />,
							name: 'phone', 
							label: 'Teléfono',
							type: 'phone'
						},
						{ 
							name: 'nationality', 
							label: 'Nacionalidad',
							field: 'select',
							defaultValue: 1,
							options: nationalityOptions
						},
						{
							field: 'select',
							name: 'catchment',
							label: 'Método de Captación',
							options: catchmentOptions
						},
						{
							name: 'status',
							label: 'Estatus',
							type: 'number',
							defaultValue: 8,
							show: true
						}
					]}
				/>
				<FilterDrawer 
		      isOpen={isOpen} 
		      handleClose={handleClose}
		    >
		    	<div className={classes.filterContent}>
	        	<Typography variant="subtitle1">
	            Fecha de Cita:
	          </Typography>
	          <SelectField
	          	name='expired'
	          	value={expired}
	          	onChange={handleChange}
	          	options={expiredOptions}
	          	variant='outlined'
	          	noFormat
	          />
	        </div>
					<Divider/>
		    </FilterDrawer>
				<PictureDialog expired={expired} isOpen={isOpenImageDialog} handleClose={handleCloseImageDialog} crmId={images[0]} images={images[1]} />
				<CreateDialog expired={expired} isOpen={isOpenCreateDialog} handleClose={handleCloseCreateDialog} params={datas} />
				<UpdateDialog expired={expired} isOpen={isOpenUpdateDialog} handleClose={handleCloseUpdateDialog} params={datas} />
				<CommissionDialog expired={expired} isOpen={isOpenCommissionDialog} handleClose={handleCloseCommissionDialog} params={datas} />
				<HistoryDialog 
					expired={expired}
					isOpen={isOpenHistoryDialog} 
					handleClose={handleCloseHistoryDialog} 
					id={datas.crmId} 
				/>
				<CreateCommentaryDialog expired={expired} id={createCommentaryId} isOpen={isOpenCreateCommentaryDialog} handleClose={handleCloseCreateCommentaryDialog}/>
			</Fragment>
		: <div className={classes.noPrivileges}>No tienes privilegios para consultar este módulo.</div>
	);
}

export default Component;