import React, { useEffect, Fragment } from 'react';

import Link from '@material-ui/core/Link';

import ReviewPdf from './../crm/review.pdf';

const Pdf = props => {

	const params = new URLSearchParams(props.location.search);
	const crmId = params.get('crmId');

	useEffect(() => {
		if(!isNaN(parseInt(crmId))){
			
			ReviewPdf(crmId, true);
		}
	}, [crmId]);

	const handleClick = e => {
		e.preventDefault();
		ReviewPdf(crmId, true);
	}

	return (
		crmId 
			? <Link href="#" onClick={handleClick}>Clic aquí para abrir el documento.</Link>
			: <Fragment>La URL proporcionada no es válida</Fragment>
	);
}

export default Pdf;