import React, { useEffect, useState, Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Autocomplete from './../../presentationals/Autocomplete';
import SelectField from './../../presentationals/SelectField';
import TextField from './../../presentationals/TextField';
import FormDialog from './../../presentationals/FormDialog';

import { parseError } from './../../helpers';
import { snackbar } from './../../apollo/cache';

import GQL from './_gql';
import GQL_CUSTOMER from './../customer/_gql';

import Cart from './cart';

const defaultValues = {
	type: 1,
	description: '',
	customerId: null,
	Products: []
}

const typeOptions = [
	{ value: 1, label: 'Venta' },
	{ value: 2, label: 'Abastecimiento' },
];

const Component = props => {

	const [ values, setValues ] = useState(defaultValues);
	const [ codeToClear, setCodeToClear ] = useState(null);
	const [ customers, setCustomers ] = useState([]);
	const [ errors, setErrors ] = useState({});

	const [ createSale, { loading }] = useMutation(GQL.CREATE, {
		onCompleted: data => {
			props.handleCreate && props.handleCreate(data);
		}
	});
	useQuery(GQL_CUSTOMER.GET,{
		onCompleted: data => {
			const tmp = data.customers.rows.map(el => ({ value: el.id, label: el.name }));
			setCustomers(tmp);
		}
	});

	useEffect(() => {
		setValues(values => ({ ...values, customerId: parseInt(values.type) === 1 ? values.customerId : null }));
	},[values.type]);

	const handleClose = () => {
		setValues(defaultValues);
		setCodeToClear(null);
		props.handleClose();
	}

	const handleAction = async () => {
		try {

			if(parseInt(values.type) === 1 && !values.customerId){ 
  			const e = new Error('Selecciona un cliente.')
  			e.name = 'customerId';
  			throw e; 
  		}

			const variables = {
				...values,
				customerId: values.customerId ? values.customerId.value : null,
				Products: values.Products.map(el => ({
					cost: el.cost,
					discount: el.discount,
					quantity: el.quantity,
					productId: el.product ? el.product.value : null,
				}))
			};
			
			await createSale({ variables });
			setValues(defaultValues);
			setCodeToClear(null);
			setErrors({});
			props.handleClose();
		}catch(e) {
			if(e.name !== 'Error') {
				setErrors({ [e.name]: e.message });
				return;
			}
			
			const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
	      return;
      }
		}
	}

	const getCart = data => {
    setValues(values => ({ ...values, Products: data }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FormDialog 
			isOpen={props.isOpen}
			isLoading={loading}
      title={values.type === 1 ? 'Nueva Venta' : 'Nuevo Abastecimiento' }
      width='md'
      name="cart"
      singular="Cart"
      disableEscape
      handleClose={handleClose}
      handleAction={handleAction}
      rightContent={
      	<Grid container alignItems='center' spacing={1}>
        	<Grid item xs={4} />
          <Grid item xs={8}>
            <SelectField 
              noFormat
              name='type'
              label='Tipo'
              value={values.type}
              onChange={handleChange}
              options={typeOptions}
              variant='outlined'
              size='small'
            />
          </Grid>
        </Grid>
      }
		>
		{
			parseInt(values.type) === 1 &&
				<Fragment>
					<Grid container spacing={1}>
						<Grid item xs={12} md={12}>
							<Autocomplete 
				        name='customerId'
				        label='Cliente' 
				        variant='outlined'
				        onChange={handleAutocompleteChange}
				        options={customers}
				        value={values.customerId}
				        error={errors.customerId}
				        noFormat
				      />
						</Grid>
					</Grid>
					<br/>
					<Divider/>
					<br/>
				</Fragment>
		}
			<Cart getCart={getCart} codeToClear={codeToClear} type={values.type} />
      <br/>
      <Grid container spacing={1}>
			  <Grid item xs={12} md={12}>
					<TextField 
			      name='description'
			      label='Descripción' 
			      variant='outlined'
			      onChange={handleChange}
			      value={values.description}
			      multiline
			      noFormat
			    />
				</Grid>
			</Grid>
		</FormDialog>
	);
}

export default Component;