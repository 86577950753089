import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  noPrivileges: {
    paddingTop: theme.spacing(20),
    textAlign: 'center',
    fontSize: 30
  },
  filterContent: {
		padding: theme.spacing(2, 2, 3, 2),
	},
}));

export default useStyles;