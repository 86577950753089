import React, { useState, useEffect } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';

import FormDialog from './../../presentationals/FormDialog';
import TextField from './../../presentationals/TextField';

import GQL from './_gql';

const defaultValues = { c1: 0, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0, c7: 0, c8: 0, c9: 0, c10: 0 };

//GET_COST_BY_ID

const Component = props => {

	const [ values, setValues ] = useState(defaultValues);

	const [ getCosts, { data, loading } ] = useLazyQuery(GQL.GET_COST_BY_ID);
	const [ updateCost ] = useMutation(GQL.UPDATE_COST, {
		update(cache, { data: response }) {
			try {
	      cache.writeQuery({
	        query: GQL.GET_COST_BY_ID,
	        variables: { id: props.procedureId },
	        data: {
	        	procedureId: props.procedureId,
	        	c1: response.updateCost.c1,
	        	c2: response.updateCost.c2,
	        	c3: response.updateCost.c3,
	        	c4: response.updateCost.c4,
	        	c5: response.updateCost.c5,
	        	c6: response.updateCost.c6,
	        	c7: response.updateCost.c7,
	        	c8: response.updateCost.c8,
	        	c9: response.updateCost.c9,
	        	c10: response.updateCost.c10,
	        	__typename: "ProcedureCost"
	        }
	      });
			}catch(e){
				console.log(e.message)
			}
		}
	});

	useEffect(() => {
		if(props.procedureId){
			getCosts({ variables: { id: props.procedureId } });
		}
	}, [props.procedureId, getCosts]);
	
	useEffect(() => {
		if(data && data.procedure.ProcedureCost){
			setValues({
				c1: data.procedure.ProcedureCost.c1,
				c2: data.procedure.ProcedureCost.c2,
				c3: data.procedure.ProcedureCost.c3,
				c4: data.procedure.ProcedureCost.c4,
				c5: data.procedure.ProcedureCost.c5,
				c6: data.procedure.ProcedureCost.c6,
				c7: data.procedure.ProcedureCost.c7,
				c8: data.procedure.ProcedureCost.c8,
				c9: data.procedure.ProcedureCost.c9,
				c10: data.procedure.ProcedureCost.c10,
			});
		}else{
			setValues(defaultValues);
		}
	}, [data])

	const handleCloseForm = () => {
		props.handleClose();
	}

	const handleAction = async () => {
		try{
			const variables = {
				procedureId: props.procedureId,
				c1: !isNaN(parseFloat(values.c1)) ? parseFloat(values.c1) : 0,
				c2: !isNaN(parseFloat(values.c2)) ? parseFloat(values.c2) : 0,
				c3: !isNaN(parseFloat(values.c3)) ? parseFloat(values.c3) : 0,
				c4: !isNaN(parseFloat(values.c4)) ? parseFloat(values.c4) : 0,
				c5: !isNaN(parseFloat(values.c5)) ? parseFloat(values.c5) : 0,
				c6: !isNaN(parseFloat(values.c6)) ? parseFloat(values.c6) : 0,
				c7: !isNaN(parseFloat(values.c7)) ? parseFloat(values.c7) : 0,
				c8: !isNaN(parseFloat(values.c8)) ? parseFloat(values.c8) : 0,
				c9: !isNaN(parseFloat(values.c9)) ? parseFloat(values.c9) : 0,
				c10: !isNaN(parseFloat(values.c10)) ? parseFloat(values.c10) : 0,
			};
			await updateCost({ variables });
			handleCloseForm();
		}catch(e){
			console.log(e);
		}
	}

	const handleChange = e => {
		const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
	}

	return (
		<FormDialog
			isOpen={props.isOpen}
			isLoading={loading}
			title='Costos del Procedimiento'
      handleClose={handleCloseForm}
      handleAction={handleAction}
		>
			<TextField 
				name='c1'
				label='HONORARIO MEDICO AYUDANTE'
				value={values.c1}
				type='number'
				onChange={handleChange}
			/>
			<TextField 
				name='c2'
				label='HONORARIO ANESTECIOLOGO'
				value={values.c2}
				type='number'
				onChange={handleChange}
			/>
			<TextField 
				name='c3'
				label='HONORARIO ENFERMERO'
				value={values.c3}
				type='number'
				onChange={handleChange}
			/>
			<TextField 
				name='c4'
				label='ADMON'
				value={values.c4}
				type='number'
				onChange={handleChange}
			/>
			<TextField 
				name='c5'
				label='VENDEDOR'
				value={values.c5}
				type='number'
				onChange={handleChange}
			/>
			<TextField 
				name='c6'
				label='IMPLANTES'
				value={values.c6}
				type='number'
				onChange={handleChange}
			/>
			<TextField 
				name='c7'
				label='RENTA EQUIPO'
				value={values.c7}
				type='number'
				onChange={handleChange}
			/>
			<TextField 
				name='c8'
				label='CONS. ANEST'
				value={values.c8}
				type='number'
				onChange={handleChange}
			/>
			<TextField 
				name='c9'
				label='ESTUDIOS'
				value={values.c9}
				type='number'
				onChange={handleChange}
			/>
			<TextField 
				name='c10'
				label='OTROS'
				value={values.c10}
				type='number'
				onChange={handleChange}
			/>
		</FormDialog>
	);

}

export default Component;