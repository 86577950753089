import React from 'react';

import TableRow from '@material-ui/core/TableRow';

const BodyRow = props => {
	
	const handleClick = (e) => {
		props.handleClick && props.handleClick(e, props.params);
	}

	return (
		<TableRow tabIndex={-1} hover className={props.className} onClick={handleClick}>
			{props.children}
		</TableRow>
	)
}

export default BodyRow;