import React from 'react';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import AirlineSeatFlatAngledIcon from '@material-ui/icons/AirlineSeatFlatAngledOutlined';
import AirlineSeatIndividualSuiteIcon from '@material-ui/icons/AirlineSeatIndividualSuiteOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAddOutlined';
import AirlineSeatFlatIcon from '@material-ui/icons/AirlineSeatFlatOutlined';
// import LocalHospitalIcon from '@material-ui/icons/LocalHospitalOutlined';
import BarChartIcon from '@material-ui/icons/BarChartOutlined';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import EventNoteIcon from '@material-ui/icons/EventNoteOutlined';

import Divider from '@material-ui/core/Divider';

import { LinkButton } from './helpers';

const _privileges = localStorage.getItem('privileges');

const MainListItems = props => { 
  return (
    <div>
      { parseInt(_privileges) !== 5 &&
        <LinkButton
          icon={<PersonAddIcon />} 
          url='/crm' 
          label='Nuevos Clientes' 
        />
      }
      { parseInt(_privileges) !== 5 &&
        <LinkButton
          icon={<EventNoteIcon />} 
          url='/crm?admin=true' 
          label='Administrador' 
        />
      }
      { parseInt(_privileges) !== 5 &&
        <LinkButton
          icon={<AirlineSeatFlatIcon />} 
          url='/postSurgery' 
          label='Post cirugía' 
        />
      }
      { parseInt(_privileges) !== 5 &&
        <LinkButton
          icon={<AirlineSeatIndividualSuiteIcon />} 
          url='/recoveryHouse' 
          label='Recovery House' 
        />
      }
      {/*<LinkButton
        icon={<LocalHospitalIcon />} 
        url='/crm?doctor=true' 
        label='Doctor' 
      />*/}
      { parseInt(_privileges) !== 5 && <Divider /> }
      { (parseInt(_privileges) === 1 || parseInt(_privileges) === 5) &&
          <LinkButton
            icon={<BarChartIcon />} 
            url='/indicator' 
            label='Indicadores' 
          />
      }
      { parseInt(_privileges) === 1 && <Divider /> } 
      {
        parseInt(_privileges) === 1 &&
          <LinkButton
            icon={<FormatListBulletedIcon />} 
            url='/crmLog' 
            label='Mov. por estatus' 
          />
      }
      {
        parseInt(_privileges) === 1 &&
          <LinkButton
            icon={<AirlineSeatFlatAngledIcon />} 
            url='/sale' 
            label='Mov. de producto' 
          />
      }
      {
        parseInt(_privileges) === 1 &&
          <LinkButton
            icon={<AccountBalanceIcon />} 
            url='/balance' 
            label='Edo. Resultados' 
          />
      }
      { parseInt(_privileges) === 1 && <Divider /> } 
      {
        parseInt(_privileges) === 1 &&
          <LinkButton
            icon={<AirlineSeatFlatAngledIcon />} 
            url='/procedure' 
            label='Procedimientos' 
          />
      } 
      {
        parseInt(_privileges) === 1 &&
          <LinkButton
            icon={<AirlineSeatFlatAngledIcon />} 
            url='/product' 
            label='Productos' 
          />
      }
      {
        parseInt(_privileges) === 1 &&
          <LinkButton
            icon={<PersonOutlineIcon />} 
            url='/customer' 
            label='Clientes' 
          />
      }
      {
        parseInt(_privileges) === 1 &&
          <LinkButton
            icon={<WorkOutlineIcon />} 
            url='/employee' 
            label='Empleados' 
          />
      }      
    </div>
  )
}

export default MainListItems;