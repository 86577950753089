import React, { useState, useEffect, Fragment } from 'react';

import { useQuery } from '@apollo/client';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import TableBody from '@material-ui/core/TableBody';

import Header from './../../presentationals/Table/header';
import Table from './../../presentationals/Table/helpers/Table';
import TableHead from './../../presentationals/Table/tableHead';
import BodyCell from './../../presentationals/Table/helpers/BodyCell';
import BodyRow from './../../presentationals/Table/helpers/BodyRow';
import format from './../../presentationals/Table/helpers/Format';

import { Link } from './../crm/helpers';
import FilterDialog from './../crmLog/filter.dialog';

import CostDialog from './CostDialog';
import SummaryDialog from './SummaryDialog';
import GQL from './_gql';

const Rows = [
	{ key: 'name', label: 'Paciente', background: '#00bfff', color: '#fff' },
	{ key: 'oneProcedureId', label: 'Proc1', background: '#00bfff', color: '#fff' },
	{ key: 'surgeryDate', label: 'Cirugía', background: '#00bfff', color: '#fff' },
	{ key: 'total', label: 'Presupuesto', background: '#00bfff', color: '#fff', align: 'right' },
	{ key: 'medicalConsultation', label: 'Consulta', background: '#00bfff', color: '#fff', align: 'right' },
	{ key: 'cost', label: 'CostoVenta', background: '#00bfff', color: '#fff', align: 'right' },
	{ key: 'costPercent', label: '%CV', background: '#00bfff', color: '#fff', align: 'right' },
	{ key: 'utility', label: 'UtilidadBruta', background: '#00bfff', color: '#fff', align: 'right' },
	{ key: 'utilityPercent', label: '%U', background: '#00bfff', color: '#fff', align: 'right' },
];

const defaultFilter = { status: [5, 6, 7], limit: 25, offset: 0, orderBy: 2, showBySurgeryDate: true };

const Balance = props => {

	const [ crms, setCrms ] = useState([]);
	const [ isOpenCost, setIsOpenCost ] = useState([false, null]);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isOpenSummary, setIsOpenSummary ] = useState(false);
	const [ search, setSearch ] = useState('');
	const [ filter, setFilter ] = useState({});

	const { data, loading, refetch } = useQuery(GQL.GET_CRM, { 
		variables: { customer: search, ...defaultFilter } 
	});

	useEffect(() => {
		if(data){
			const tmpData = data.crms.rows.map(el => {
				const cost = el.Costs ? el.Costs.reduce((obj, elCh) => { return parseFloat(obj) + parseFloat(elCh.cost) }, 0) : 0;
				const costPercent = cost / parseFloat(el.total) *  100;
				const utility = parseFloat(el.total || 0) + parseFloat(el.medicalConsultation) - cost;
				const utilityPercent = parseFloat(utility) / parseFloat(el.total) * 100;
				return { ...el, cost, costPercent, utility, utilityPercent };
			});
			setCrms(tmpData);
		}
	}, [data]);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch({ customer: search, ...defaultFilter, ...filter, status: [5, 6, 7] });
		}
	}, [search, filter, refetch]);

	const handleFilter = () => setIsOpen(true);

	const handleOpenCost = (e, params, action) => {
		if(action === 'close'){
			setIsOpenCost([false, null]);
			return;
		}
		setIsOpenCost([true, params])
	}

	const handleSearch = tmpSeach => {
  	setSearch(tmpSeach);
  }

  const handleOpenSummary = () => {
  	setIsOpenSummary(isOpenSummary => !isOpenSummary);
  }

  const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}
	
	if(loading) { return (<div>Espere un momento...</div>)}

	return (
		<Fragment>
			<Header
				title='Estado de Resultados' 
				qty={data ? data.crms.count : 0}
				handleSearch={handleSearch}
				customizedAction={{
					label: 'Resumen',
					action: handleOpenSummary,
					icon: <AccountBalanceIcon />
				}}
				filter={handleFilter}
				noCreate
				noMenu
			/>
			<Table>
				<TableHead Rows={Rows} direction='asc' noActionButtons />
				<TableBody>
					{
						crms.map(el =>
							<BodyRow key={el.id}>
								<BodyCell noWrap>{el.Customer.name}</BodyCell>
								<BodyCell noWrap>{el.OneProcedure ? el.OneProcedure.prefix : '-'}</BodyCell>
								<BodyCell noWrap>{format(el.surgeryDate, 'dateSurgery')}</BodyCell>
								<BodyCell noWrap align='right'>{format(el.total, 'currency')}</BodyCell>
								<BodyCell noWrap align='right'>{format(el.medicalConsultation, 'currency')}</BodyCell>
								<BodyCell noWrap align='right'>
									<Link handleClick={handleOpenCost} params={el.id}>{format(el.cost, 'currency')}</Link>
								</BodyCell>
								<BodyCell noWrap align='right'>{format(el.costPercent, 'percent')}</BodyCell>
								<BodyCell noWrap align='right'>{format(el.utility, 'currency')}</BodyCell>
								<BodyCell noWrap align='right'>{format(el.utilityPercent, 'percent')}</BodyCell>
							</BodyRow>
						)
					}
				</TableBody>
			</Table>
			<CostDialog isOpen={isOpenCost[0]} handleClose={handleOpenCost} crmId={isOpenCost[1]} search={search} />
			<SummaryDialog isOpen={isOpenSummary} handleClose={handleOpenSummary} />
			<FilterDialog isOpen={isOpen} handleAction={handleAction} noStatus noCatchment />
		</Fragment>

	)
}

export default Balance;