import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { format } from 'date-fns';

import FormDialog from './../../presentationals/FormDialog';
import TextField from './../../presentationals/TextField';
import DateTimeField from './../../presentationals/DateTimeField';

import GQL from './_gql';

const defaultValues = {
	arriveDate: '',
	departureDate: '',
	commentary: '',
	entryDate: '',
	outputDate: ''
}

const CreateDialog = props => {
	const [ values, setValues ] = useState(defaultValues);

	const [ updateRecoveryHouse, { loading } ] = useMutation(GQL.UPDATE)
	// {
	// 	update(cache, { data: response }) {
			
	// 		const dataResponse = response.updatePostSurgery;
	// 		// console.log(dataResponse.status);

	// 		const crmId = props.params.id;
 //      const oldQuery = cache.readQuery({ query: GQL.GET });

 //      const newQuery = oldQuery.postSurgeries.map(el => {
 //      	if(parseInt(el.id) === parseInt(crmId)){
 //      		let PostSurgeryCount;
 //      		let PostSurgery = null;

 //      		if(parseInt(dataResponse.status) === 2 || parseInt(dataResponse.status) === 1){
 //      			PostSurgeryCount = parseInt(el.PostSurgeryCount);
 //      		} else {
 //      			PostSurgeryCount = parseInt(el.PostSurgeryCount) - 1;
 //      		};

 //      		if(parseInt(dataResponse.status) === 1){
 //      			PostSurgery = el.PostSurgery;
 //      		}
 //      		return {
 //      			...el,
 //      			PostSurgeryCount,
 //      			PostSurgery
 //      		}
 //      	}
 //      	return el;
 //      })
 //      cache.writeQuery({
 //        query: GQL.GET,
 //        data: { postSurgeries: newQuery }
 //      });
	// 	}
	// });

	useEffect(() => {
		if(props.params){
			setValues({...values, ...props.params.RecoveryHouse});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.params]);

	const handleCloseForm = e => {
		props.handleClose();
	}

	const handleAction = async (e) => {
		try{
			// const { id } = props.params.RecoveryHouse;
			
			await updateRecoveryHouse({ variables: values });
			handleCloseForm();
		}catch(e){
			console.log(e.message)
		}
	}

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleDateTimeChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd HH:mm')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

	return (
		<FormDialog
			isOpen={props.isOpen}
			isLoading={loading}
			title='Actualizar'
      handleClose={handleCloseForm}
      handleAction={handleAction}
      actionLabel='Actualizar'
		>
			<DateTimeField
				name='arriveDate'
				label='Llegada'
				value={values.arriveDate}
				onChange={handleDateTimeChange}
			/>
			<DateTimeField
				name='departureDate'
				label='Salida'
				value={values.departureDate}
				onChange={handleDateTimeChange}
			/>
			<DateTimeField
				name='entryDate'
				label='Entrada a Recovery House'
				value={values.entryDate}
				onChange={handleDateTimeChange}
			/>
			<DateTimeField
				name='outputDate'
				label='Salida de Recovery House'
				value={values.outputDate}
				onChange={handleDateTimeChange}
			/>
			<TextField
				name='commentary'
				label='Comentario'
				value={values.commentary}
				onChange={handleChange}
			/>
		</FormDialog>
	);
};

export default CreateDialog;