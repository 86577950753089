import { gql } from '@apollo/client';

const GET = gql`
  query Customers{
    customers {
      count
      rows{
        id
        name
        age
        phone
        place
        nationality
        spanish
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Customer($id: ID!){
    customer(id: $id) {
      id
      name
      age
      phone
      place
      nationality
      spanish
    }
  }
`;

const DELETE = gql`
  mutation Delete($id: ID!) {
    deleteCustomer(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  mutation CreateCustomer(
    $name: String!
    $age: String!
    $phone: String!
    $place: String!
    $nationality: Int!
    $spanish: Boolean!
  ) {
    createCustomer(input: {
      name: $name
      age: $age
      phone: $phone
      place: $place
      nationality: $nationality
      spanish: $spanish
    }) {
      id
      name
      age
      phone
      place
      nationality
      spanish
    }
  }
`
const UPDATE = gql`
  mutation UpdateCustomer(
    $id: ID!, 
    $name: String!
    $age: String!
    $phone: String!
    $place: String!
    $nationality: Int!
    $spanish: Boolean!
  ) {
    updateCustomer(
      id: $id, 
      input: {
        name: $name
        age: $age
        phone: $phone
        place: $place
        nationality: $nationality
        spanish: $spanish
      }
    ) {
      id
      name
      age
      phone
      place
      nationality
      spanish
    }
  }
`

const GET_NAME_AND_PHONE = gql`
  query Customers{
    customers {
      id
      name
      age
      phone
      place
      nationality
      spanish
    }
  }
`;


const object = { 
  GET, 
  GET_BY_ID, 
  DELETE, 
  CREATE, 
  UPDATE, 
  GET_NAME_AND_PHONE,
};
export default object;