import React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitleMUI from '@material-ui/core/DialogTitle';

import LinearProgress from '@material-ui/core/LinearProgress';

import { DialogTitle, DialogContent, DialogActions } from './style';

const Component = props => 
  <Dialog
   open={ props.isOpen }
   onClose={ props.handleClose }
   fullWidth={ true }
   maxWidth = { props.width || 'sm' }
   fullScreen= { props.fullScreen }
   disableEscapeKeyDown={false}
   disableBackdropClick={false}  
  >
    { props.isLoading && <LinearProgress color="secondary" /> }
    { props.title && <DialogTitle children={props.title} rightContent={props.rightContent} /> }
    { props.altTitle && <DialogTitleMUI>{props.altTitle}</DialogTitleMUI> }
    <DialogContent 
      contentBackground={props.contentBackground} 
      noPadding={props.noPadding}
      >
        { props.children }
      </DialogContent>
    <DialogActions>
      { !props.hideClose && <Button disabled={props.isLoading ? true : false} onClick={ props.handleClose }>Cerrar</Button> }
      { !props.hideAction && (
        <Button onClick={ props.handleAction } disabled={props.isLoading ? true : false} color="secondary">
          { props.actionLabel || "Guardar" }
        </Button>
      )}
    </DialogActions>
  </Dialog>

export default Component;