import React, { Fragment, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import parseISO from 'date-fns/parseISO';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Module from './../../presentationals/Module';
import FilterDrawer from './../../presentationals/FilterDrawer';
import SelectField from './../../presentationals/SelectField';

import GQL from './_gql';

const useStyles = makeStyles((theme) => ({
	filterContent: {
		padding: theme.spacing(2, 2, 3, 2),
	},
}));

const monthOptions = [
	{ value: false, label: 'ACTUAL' },
	{ value: '2021-01-10', label: 'ENERO' },
	{ value: '2021-02-10', label: 'FEBRERO' },
	{ value: '2021-03-10', label: 'MARZO' },
	{ value: '2021-04-10', label: 'ABRIL' },
	{ value: '2021-05-10', label: 'MAYO' },
	{ value: '2021-06-10', label: 'JUNIO' },
	{ value: '2021-07-10', label: 'JULIO' },
	{ value: '2021-08-10', label: 'AGOSTO' },
	{ value: '2021-09-10', label: 'SEPTIEMBRE' },
	{ value: '2021-10-10', label: 'OCTUBRE' },
	{ value: '2021-11-10', label: 'NOVIEMBRE' },
	{ value: '2021-12-10', label: 'DICIEMBRE' },
];

const Rows = [
	{ label: 'Fecha', key: 'date', sort: 'text', format: 'date' },
	{ label: 'Vendedor1', key: 'saleEmployee1', sort: 'text' },
	{ label: 'Vendedor2', key: 'saleEmployee2', sort: 'text' },
	{ label: 'Vendedor3', key: 'saleEmployee3', sort: 'text' },
	{ label: 'Administrador', key: 'adminEmployee', sort: 'text' },
	// { label: 'PostCirugía', key: 'postEmployee', sort: 'text' }
];

const Component = props => {

	const classes = useStyles();

	const [ filter, setFilter ] = useState({});
	const [ isOpen, setIsOpen ] = useState(false);
	const [ month, setMonth ] = useState(false);

	const { data, loading, refetch } = useQuery(GQL.GET);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch({ ...filter, start: startOfMonth(parseISO(month)), end: endOfMonth(parseISO(month)) });
		}
		// eslint-disable-next-line
	}, [filter, refetch]);

	const handleOpenFilter = () => {
		setIsOpen(isOpen => !isOpen);
	}

	const handleFilter = (e, values) => {
		console.log(values)
	}

	const handleChange = e => {
    const value = e.target.value;
    setMonth(value);
    setFilter(filter => ({ ...filter, start: startOfMonth(parseISO(value)), end: endOfMonth(parseISO(value)) }));
  }

  const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}
	return (
		<Fragment>
			<Module
				isLoading={loading}
				name='Indicadores'
				singular='Indicador'
				operation={['indicatorv2', 'indicatorsv2']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				records={data && data.indicatorsv2}
				noDelete
				filter={handleOpenFilter}
				noEdit
			/>
			<FilterDrawer 
				isOpen={isOpen} 
				handleAction={handleFilter} 
				handleClose={handleOpenFilter} 
			>
				<div className={classes.filterContent}>
	      	<Typography variant="subtitle1">
	          Mes:
	        </Typography>
	        <SelectField
	        	name='month'
	        	value={month}
	        	onChange={handleChange}
	        	options={monthOptions}
	        	variant='outlined'
	        	noFormat
	        />
	      </div>
			</FilterDrawer>
		</Fragment>
	);
}

export default Component;