const sortByType = (rows, key, type, direction) => {
  let rows1 = []
  switch(type){
    case 'text':
      rows1 = rows.slice().sort(function (a, b) {
        if (a[key] > b[key]) { return 1; }
        if (a[key] < b[key]) { return -1; }
        return 0;
      });
      break;
    case 'date':
      rows1 = rows.slice().sort(function (a, b) {
        const tmpA = a[key] ? new Date(a[key]) : new Date('1995/01/01');
        const tmpB = b[key] ? new Date(b[key]) : new Date('1995/01/01');
        return tmpB - tmpA;
      });
      break;
    case 'number':
      rows1 = rows.slice().sort(function (a, b) {
        const tmpA = a[key] ? a[key] : 0;
        const tmpB = b[key] ? b[key] : 0;
        return tmpB - tmpA;
      });
      break;
    default:
      break;
  }
  if(direction === 'desc') rows1.reverse();
  return rows1;
}

const filterRows = (value, keys, rows) => {
  const tmp = rows.filter(data => {
    const isValid = keys.some(key => 
      data[key].toLowerCase().includes(value.toLowerCase())
    );
    return isValid && data;
  });
  return tmp;
}

export { sortByType, filterRows };